import axios from "axios";

export const AwsPresignedApi = {
  async putPresignedUrl(url: string, file: File) {
    const encodedFileName = encodeURIComponent(file.name);
    await axios.request({
      url,
      method: "PUT",
      data: file,
      withCredentials: false,
      headers: {
        "Content-Type": file.type,
        "Content-Disposition": `inline; filename="${encodedFileName}"; filename*=UTF-8''${encodedFileName}`,
      },
    });
  },
} as const;
