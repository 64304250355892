import React, { useCallback, useMemo, useState } from "react";
import { TestCasePolicies } from "../../../types/createProductStore";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  isRequirementPolicy,
  isTestCasePolicy,
  Policy,
} from "@design-controls/types";
import { useCurrentConfigs } from "../../../hooks/useCurrentConfigs";
import { TestCasePolicy } from "../../../components/TestCasePolicy/TestCasePolicy";
import { useUpdatePolicy } from "../../../hooks/policy/useUpdatePolicy";
import { useDisclosure } from "@chakra-ui/hooks";
import { DeletePolicy } from "./DeletePolicy";
import { useArchivePrefixes } from "../../../hooks/policy/useArchivePrefixes";

type Props = {
  nextRef: React.RefObject<any>;
};

export const TestCaseConfigure: React.FC<Props> = ({ nextRef }) => {
  const configs = useCurrentConfigs();
  const { mutate } = useUpdatePolicy();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [policyType, setPolicyType] = useState<Policy["type"] | null>(null);
  const { data: prefixes } = useArchivePrefixes();

  const requirements = useMemo(
    () => configs.filter(isRequirementPolicy).map((config) => config.type),
    configs,
  );

  const defaultValues = useMemo(() => {
    const requirements = configs.filter(isTestCasePolicy);
    return Object.fromEntries(
      requirements.map((policy) => [policy.type, policy]),
    );
  }, configs);

  const methods = useForm<TestCasePolicies>({
    defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(TestCasePolicies),
  });

  const { setError } = methods;

  const onSubmit = useCallback(
    (form: TestCasePolicies) => {
      const policies = Object.values(form);

      const toBeSaved = Object.fromEntries(
        policies.map((item) => [item.type, item.codePrefix]),
      );

      const usedPrefixes = [
        ...configs
          .filter((item) => toBeSaved[item.type] !== item.codePrefix)
          .map((item) => item.codePrefix),
        ...(prefixes ?? []),
      ];

      let hasErrors = false;
      policies.forEach((policy) => {
        if (usedPrefixes.includes(policy.codePrefix)) {
          methods.setError(`${policy.type}.codePrefix`, {
            message: "Prefix in use",
          });
          hasErrors = true;
        }
      });

      if (!hasErrors) {
        mutate(policies);
      }
    },
    [mutate, setError, prefixes, configs],
  );

  const onDelete = useCallback(
    (type: Policy["type"]) => {
      setPolicyType(type);
      onOpen();
    },
    [setPolicyType, onOpen],
  );

  const onDeleteClose = useCallback(() => {
    setPolicyType(null);
    onClose();
  }, [onClose, setPolicyType]);

  return (
    <FormProvider {...methods}>
      <TestCasePolicy requirements={requirements} onDelete={onDelete} />
      <button
        style={{ display: "none" }}
        ref={nextRef}
        onClick={methods.handleSubmit(onSubmit)}
        type="submit"
      />
      {isOpen && policyType !== null && (
        <DeletePolicy type={policyType} onClose={onDeleteClose} />
      )}
    </FormProvider>
  );
};
