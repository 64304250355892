import { useMutation, useQueryClient } from "react-query";
import changeControlApi from "../../api/changeControl";
import { useCompanyId } from "../../context/CurrentUserContext";
import { useToastProvider } from "@qualio/ui-components";

export const useRevertToDraft = (
  productId: string,
  changeControlId: string,
) => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();
  const { showToast } = useToastProvider();

  return useMutation({
    mutationFn: () =>
      changeControlApi.revertToDraft(companyId, productId, changeControlId),
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        `queryChangeControl-company-${companyId}-product-${productId}-changeControlId-${changeControlId}`,
      );
      showToast({
        title: "Reverted",
        description: "Successfully reverted to draft",
        status: "success",
      });
    },
    onError: () => {
      showToast({
        title: "Not reverted to draft",
        description: "Cannot revert to draft",
        status: "error",
      });
    },
  });
};
