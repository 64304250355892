import { useCurrentUser } from "@qualio/ui-components";
import { useQuery } from "react-query";

import { useCurrentProduct } from "./useCurrentProduct";
import { designControlsApi } from "../api/designControls";

export const useRetrieveDesignElementHistory = (designElementId: string) => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();

  return useQuery({
    queryKey: ["REVIEW_HISTORY", companyId, productId, designElementId],
    queryFn: () =>
      designControlsApi.retrieveDesignElementHistory(
        companyId,
        productId,
        designElementId,
      ),
  });
};
