import { ISORiskDesignElement, RiskPolicy } from "@design-controls/types";
import { z } from "zod";
import { riskScore } from "../../domain/isoRequiresControl";

export const riskToForm = (risk: ISORiskDesignElement): ISORiskForm => {
  return {
    ...risk,
    sourceRequirements: risk.sourceItems.filter((item) =>
      item.type.startsWith("req"),
    ),
    sourceTestCases: risk.sourceItems.filter((item) =>
      item.type.startsWith("testCase"),
    ),
    mitigatesRequirements: risk.mitigationItems.filter((item) =>
      item.type.startsWith("testCase"),
    ),
    mitigatesTestCases: risk.mitigationItems.filter((item) =>
      item.type.startsWith("testCase"),
    ),
  };
};

export const formToRisk = (
  formData: ISORiskForm,
  policy: RiskPolicy,
): Omit<
  ISORiskDesignElement,
  "updated" | "created" | "source" | "itemStatus" | "changeControlStatus"
> => {
  const mitigation = ({
    probability,
    severity,
  }: {
    probability?: string;
    severity?: string;
  }) => {
    if (!probability || !severity) {
      return;
    }

    return {
      probability,
      severity,
      riskLevel: riskScore(policy, probability, severity),
    };
  };

  const preMitigation = mitigation(formData.preMitigation ?? {});
  const postMitigation = mitigation(formData.postMitigation ?? {});

  return {
    id: formData.id,
    type: formData.type,
    subType: formData.subType,
    title: formData.title,
    category: formData.category?.qri ? formData.category : undefined,
    riskControl: !!formData.riskControl?.mitigation
      ? formData.riskControl
      : undefined,
    hazard: formData.hazard,
    foreseeableUseMisuse: formData.foreseeableUseMisuse,
    hazardSituation: formData.hazardSituation,
    harm: formData.harm,
    sourceItems: [
      ...(formData.sourceRequirements ?? []),
      ...(formData.sourceTestCases ?? []),
    ].map((item) => item.id),
    mitigationItems: [
      ...(formData.mitigatesRequirements ?? []),
      ...(formData.mitigatesTestCases ?? []),
    ].map((item) => item.id),
    preMitigation,
    postMitigation,
  };
};

export const ISORiskForm = z
  .object({
    sourceRequirements: ISORiskDesignElement.shape.sourceItems.optional(),
    sourceTestCases: ISORiskDesignElement.shape.sourceItems.optional(),
    mitigatesRequirements:
      ISORiskDesignElement.shape.mitigationItems.optional(),
    mitigatesTestCases: ISORiskDesignElement.shape.mitigationItems.optional(),
  })
  .merge(
    ISORiskDesignElement.omit({
      sourceItems: true,
      mitigationItems: true,
    }),
  );
export type ISORiskForm = z.infer<typeof ISORiskForm>;
