import React, { useMemo } from "react";

import { QFormControl, QSelect } from "@qualio/ui-components";
import { Controller } from "react-hook-form";

import { QualioUser } from "@design-controls/types";

type Props = {
  isLoading: boolean;
  data?: QualioUser[];
};

export const Owner: React.FC<Props> = ({ isLoading, data }) => {
  const options = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map((item) => ({
      value: item.id.toString(10),
      label: item.fullName,
    }));
  }, [data]);

  return (
    <Controller
      name="owner"
      render={({ field: { onChange, value } }) => (
        <QFormControl label="Owner">
          <QSelect
            value={value}
            isLoading={isLoading}
            onChange={(selectedItem) =>
              selectedItem && onChange(selectedItem.value)
            }
            options={options}
          />
        </QFormControl>
      )}
    />
  );
};
