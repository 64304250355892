import React from "react";

import {
  CurrentUserProvider as QCurrentUserProvider,
  QualioQFrontEndProvider,
} from "@qualio/ui-components";
import type { CurrentUser as QCurrentUser } from "@qualio/ui-components/lib/types/CurrentUser";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import ReactDOM from "react-dom";
import "./style/index.less";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";

import App from "./App";
import { App as AppV2 } from "./appV2/App";
import ErrorBoundary from "./components/ErrorBoundary";
import { FeatureFlags } from "./components/FeatureToggle/FeatureToggle.enum";
import {
  FeatureToggle,
  DisabledFeatureFlag,
  EnabledFeatureFlag,
} from "./components_new/FeatureFlag/FeatureFlag";
import { QViewsStylingFix } from "./components_new/QViewsStylingFix/QViewsStylingFix";
import { AnalyticsProvider } from "./context/analytics";
import AppContainer from "./context/container";
import { CurrentUserProvider } from "./context/CurrentUserContext";
import { theme } from "./theme";

(window as any).renderDesignControls = async (
  containerId: string,
  currentUser: QCurrentUser,
  analytics: SegmentAnalytics.AnalyticsJS,
) => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_PUBLIC_KEY as string,
    user: {
      key: `${currentUser?.companyId}`,
      custom: {
        loggedInUserId: `${currentUser?.userId}`,
        createdTime: `${currentUser.company?.createdTime}`,
        status: `${currentUser.company?.status}`,
      },
    },
    options: {},
  });

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <LDProvider>
        <FeatureToggle feature={FeatureFlags.UI_REFRESH}>
          <EnabledFeatureFlag>
            <QViewsStylingFix>
              <QualioQFrontEndProvider user={currentUser} analytics={analytics}>
                <AppV2 />
              </QualioQFrontEndProvider>
            </QViewsStylingFix>
          </EnabledFeatureFlag>
          <DisabledFeatureFlag>
            <QCurrentUserProvider user={currentUser}>
              <CurrentUserProvider>
                <ErrorBoundary>
                  <AnalyticsProvider value={analytics}>
                    <AppContainer containerId={containerId}>
                      <ThemeProvider theme={theme}>
                        <div className="old-ui">
                          <App />
                        </div>
                      </ThemeProvider>
                    </AppContainer>
                  </AnalyticsProvider>
                </ErrorBoundary>
              </CurrentUserProvider>
            </QCurrentUserProvider>
          </DisabledFeatureFlag>
        </FeatureToggle>
      </LDProvider>
    </QueryClientProvider>,
    document.getElementById(containerId),
  );
};

(window as any).unmountDesignControls = (containerId: string) => {
  try {
    console.log("attempting to unmount container", containerId);
    const container = document.getElementById(containerId);

    if (container !== null) {
      ReactDOM.unmountComponentAtNode(container);
    }
  } catch (e) {
    console.error("error unmounting design controls container", e);
  }
};

if (process.env.REACT_APP_MOCK) {
  require("./mocks");
}
