import * as z from "zod";

import { BaseQualityItem, qualityItemStatus } from "./baseQualityItem";
import { AttachmentWithIdType } from "./generatePresignedUrl";
import { QualioUser } from "./qualioUser";
import { Smartlink } from "./smartlink";
import {
  getPropMaxLengthExceededMessage,
  getPropShouldNotBeEmptyMessage,
} from "./validationError";

export type DashboardItem = Pick<
  BaseQualityItem,
  | "id"
  | "code"
  | "type"
  | "created"
  | "updated"
  | "title"
  | "codeTitle"
  | "itemStatus"
  | "statusLabel"
  | "changeControlStatus"
  | "subType"
> & {
  version: string;
  attachments?: Array<AttachmentWithIdType>;
};

export type ChangeControlQualityItem = Pick<
  BaseQualityItem,
  "id" | "code" | "type" | "itemStatus"
> & {
  title: string;
  version: string;
  subType?: string;
  latest?: string;
};

export enum ChangeControlStatus {
  IN_PROGRESS = "IN_PROGRESS",
  SENT_FOR_APPROVAL = "SENT_FOR_APPROVAL",
  APPROVED = "APPROVED",
  NOT_APPROVED = "NOT_APPROVED",
}

export type ChangeControlApprover = Pick<QualioUser, "fullName"> & {
  status?: ChangeControlStatus;
  id: string;
  modified?: string;
  note?: string;
};

export type ChangeControl = {
  id: string;
  code: string;
  description?: string;
  title?: string;
  qualityItems: ChangeControlQualityItem[];
  updated: string;
  created: string;
  status?: ChangeControlStatus;
  releaseId?: string;
  approvers?: ChangeControlApprover[];
  linkedDocuments: Smartlink[];
  createdBy?: {
    id: string;
    fullName: string;
  };
};

export const ChangeControlQualityItemPayload = z.object({
  id: z.string().uuid(),
  code: z.string().optional(),
  title: z.string(),
  type: z.string(),
  version: z.string(),
  latest: z.string().optional(),
  itemStatus: qualityItemStatus,
});
export type ChangeControlQualityItemPayloadType = z.infer<
  typeof ChangeControlQualityItemPayload
>;

export const ChangeControlApproverPayload = z.object({
  fullName: z.string(),
  status: z.nativeEnum(ChangeControlStatus).optional(),
  id: z.string(),
});
export type ChangeControlApproverPayloadType = z.infer<
  typeof ChangeControlApproverPayload
>;

export const SmartlinkPayload = z.object({
  id: z.string(),
  title: z.string(),
  uuid: z.string().uuid(),
  code: z.string(),
  status_id: z.string(),
});
export type SmartlinkPayloadType = z.infer<typeof SmartlinkPayload>;

export const ChangeControlPayload = z.object({
  title: z
    .string()
    .min(1, getPropShouldNotBeEmptyMessage("Title"))
    .max(100, getPropMaxLengthExceededMessage("Title")),
  description: z
    .string()
    .min(1, getPropShouldNotBeEmptyMessage("Description"))
    .max(150_000, getPropMaxLengthExceededMessage("Description")),
  qualityItems: z
    .array(ChangeControlQualityItemPayload)
    .max(300, "Maximum of 300 design elements")
    .optional(),
  approvers: z.array(ChangeControlApproverPayload).optional(),
  linkedDocuments: z.array(SmartlinkPayload).optional(),
});
export type ChangeControlPayloadType = z.infer<typeof ChangeControlPayload>;

export const errorStatusCodeToErrorMessage: Record<number, string> = {
  401: "Invalid credentials. Username or password is incorrect",
  403: "User is not logged into the system",
  500: "Approve/decline could not be processed. Please retry",
};
