import { TestCaseDesignElement } from "@design-controls/types";
import {
  DataProvider,
  QDataTable,
  QIcon,
  QStack,
  QTag,
  QText,
} from "@qualio/ui-components";
import React, { useMemo } from "react";
import { sanitize } from "dompurify";
import diff from "../htmldiff/htmldiff";
import {
  ResultValueToLabel,
  TestResultVariantColour,
} from "../../../constants";

type Props = {
  base: TestCaseDesignElement;
  compare: TestCaseDesignElement;
};

type Column = {
  label: string;
  base: any;
  compare: any;
};

export const TestCase: React.FC<Props> = ({ base, compare }) => {
  const comparison = diff(base?.description ?? "", compare?.description ?? "");

  const rows = useMemo(() => {
    const rows: Column[] = [];

    if (base.title !== compare.title) {
      rows.push({
        label: "Title",
        base: base.title,
        compare: compare.title,
      });
    }

    if (base.category?.qri !== base.category?.qri) {
      rows.push({
        label: "Category",
        base: base.category?.label,
        compare: compare.category?.label,
      });
    }

    if (base.testResult?.executedBy !== compare.testResult?.executedBy) {
      rows.push({
        label: "Tester",
        base: base.testResult?.executedBy,
        compare: compare.testResult?.executedBy,
      });
    }

    if (base.testResult?.date !== compare.testResult?.date) {
      rows.push({
        label: "Executed dated",
        base: base.testResult?.date,
        compare: compare.testResult?.date,
      });
    }

    if (base.testResult?.notes !== compare.testResult?.notes) {
      rows.push({
        label: "Notes",
        base: base.testResult?.notes,
        compare: compare.testResult?.notes,
      });
    }

    if (base.testResult?.result !== compare.testResult?.result) {
      rows.push({
        label: "Result",
        base: base.testResult && (
          <QTag variantColor={TestResultVariantColour[base.testResult.result]}>
            {ResultValueToLabel[base.testResult.result]}
          </QTag>
        ),
        compare: compare.testResult && (
          <QTag
            variantColor={TestResultVariantColour[compare.testResult.result]}
          >
            {ResultValueToLabel[compare.testResult.result]}
          </QTag>
        ),
      });
    }

    return rows;
  }, [base, compare]);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: "label",
        header: "Label",
        meta: {
          width: "200px",
        },
        cell: ({ row: { original: row } }: { row: { original: Column } }) => (
          <QText fontWeight={600}>{row.label}</QText>
        ),
      },
      {
        accessorKey: "base",
        header: "Base",
        meta: {
          minWidth: "200px",
        },
        cell: ({ row: { original: row } }: { row: { original: Column } }) =>
          row.base ?? <QText color="gray.500">Empty</QText>,
      },
      {
        accessorKey: "id",
        header: "icon",
        meta: {
          width: "60px",
        },
        cell: () => <QIcon iconName="ArrowRight" />,
      },
      {
        accessorKey: "compare",
        header: "Compare",
        meta: {
          minWidth: "200px",
        },
        cell: ({ row: { original: row } }: { row: { original: Column } }) =>
          row.compare ?? <QText color="gray.500">Empty</QText>,
      },
    ];
  }, []);

  return (
    <QStack gap="16px">
      {rows.length && (
        <DataProvider.Fixed data={rows}>
          <QDataTable columns={columns} hideItemCount withHeaders={false} />
        </DataProvider.Fixed>
      )}
      {base.description !== compare.description && (
        <QStack>
          <QText fontSize="sm" fontWeight={600} color="gray.900">
            Description
          </QText>

          <QText
            fontSize={14}
            style={{ wordBreak: "break-word" }}
            dangerouslySetInnerHTML={{
              __html: sanitize(comparison),
            }}
          />
        </QStack>
      )}
    </QStack>
  );
};
