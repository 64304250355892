import { AttachmentWithId } from "@design-controls/types";
import { QDivider, QIconButton, QStack, QText } from "@qualio/ui-components";
import React, { useMemo } from "react";
import { useDownloadAttachment } from "../../hooks/designElement/useDownloadAttachment";

type Props = {
  attachments: AttachmentWithId[];
  withoutBorder?: boolean;
};

const AttachmentItem: React.FC<AttachmentWithId> = ({
  filename,
  id,
  filesize,
}) => {
  const { onDownloadFile } = useDownloadAttachment();

  return (
    <QStack alignItems="center" direction="row" justify="space-between">
      <QText fontSize="sm" fontWeight="semibold">
        {filename}
      </QText>
      <QIconButton
        iconName="Download"
        aria-label="Download file"
        data-cy={`attachment-download-${id}`}
        onClick={() => onDownloadFile({ id, filename, filesize })}
      />
    </QStack>
  );
};

export const AttachmentTable: React.FC<Props> = ({
  attachments,
  withoutBorder,
}) => {
  const joinedComponents = useMemo(() => {
    return attachments.reduce(
      (
        acc: readonly React.ReactElement[],
        current: AttachmentWithId,
        index: number,
      ) =>
        index === 0
          ? [<AttachmentItem {...current} />]
          : [...acc, <QDivider />, <AttachmentItem {...current} />],
      [],
    );
  }, [attachments]);

  return (
    <QStack
      border={withoutBorder ? "0px" : "1px"}
      borderRadius="4px"
      padding="15px"
      borderColor="gray.200"
      data-cy="attachment"
    >
      {joinedComponents}
    </QStack>
  );
};
