import { RiskPolicy } from "@design-controls/types";

export const riskScore = (
  policy: RiskPolicy,
  probability?: string,
  severity?: string,
) => {
  if (!probability || !severity) {
    return;
  }

  const probabilityIndex = policy.assessment.iso.probability.findIndex(
    (p) => p === probability,
  );

  const severityIndex = policy.assessment.iso.severity.findIndex(
    (s) => s === severity,
  );

  return policy.assessment.iso.levelMapping[probabilityIndex][severityIndex];
};
