import React, { useCallback, useMemo } from "react";
import {
  createQColumnHelper,
  DataProvider,
  QButton,
  QCard,
  QDataTable,
  QIconButton,
  QLookup,
  QStack,
  QText,
} from "@qualio/ui-components";
import { ReviewRecord } from "@design-controls/types";
import { globalSearchApi } from "../../api/globalSearch";
import { qriApi } from "../../api/qri";
import { useDisclosure } from "@chakra-ui/hooks";
import { SelectableDataAccessors } from "@qualio/ui-components/lib/QOrganisms/Lookup/types";

type LinkedDocumentsProps = {
  onChange: (items: ReviewRecord["linkedDocuments"]) => void;
  value: ReviewRecord["linkedDocuments"];
  "data-cy"?: string;
};

const columnHelper = createQColumnHelper<ReviewRecord["linkedDocuments"][0]>();

export const LinkedDocuments: React.FC<LinkedDocumentsProps> = ({
  value,
  onChange,
  "data-cy": dataCy,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const defaultDataQRIs = useMemo(
    () =>
      (value ?? []).map((item: ReviewRecord["linkedDocuments"][0]) => item.qri),
    [value],
  );

  const onSelect = useCallback(
    (selection: readonly QLookup.DataProvider.V2Result[]) => {
      if (!selection || selection.length === 0) {
        onChange([]);
      } else {
        const mappedSelection = selection.map(
          (item): ReviewRecord["linkedDocuments"][0] => ({
            id: item.identifiers.id.toString(),
            code: item.identifiers.code as string,
            title: item.name,
            status_id: item.status ?? "",
            uuid: item.identifiers.matrixId ?? "",
            qri: item.identifiers.qri,
          }),
        );
        onChange(mappedSelection);
      }
      onClose();
      return Promise.resolve();
    },
    [onChange],
  );

  const isPreselected = useCallback(
    (result: QLookup.DataProvider.V2Result) => {
      return !!defaultDataQRIs.find(
        (qri: string) => qri === result.identifiers.qri,
      );
    },
    [defaultDataQRIs],
  );

  const onDeleteItem = useCallback(
    (item: ReviewRecord["linkedDocuments"][0]) => {
      onChange(value.filter((doc) => doc.qri !== item.qri));
    },
    [value, onChange],
  );

  const columns = useMemo(() => {
    return [
      columnHelper.text("code", { header: "Code", maxWidth: "100px" }),
      columnHelper.text("title", { header: "Title" }),
      {
        header: " ",
        meta: {
          maxWidth: "48px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: ReviewRecord["linkedDocuments"][0] };
        }) => {
          return (
            <QIconButton
              iconName="Trash"
              aria-label="Remove document"
              onClick={() => onDeleteItem(row)}
            />
          );
        },
      },
    ];
  }, [onDeleteItem]);

  return (
    <>
      <QCard>
        <QStack padding={4} gap={4}>
          <QStack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <QText fontSize="sm" fontWeight={600}>
              Documents
            </QText>
            <QButton variant="link" onClick={onOpen} data-cy={dataCy}>
              Add documents
            </QButton>
          </QStack>
          {value.length > 0 && (
            <DataProvider.Fixed data={value} isLoading={false}>
              <QDataTable columns={columns} withHeaders={false} hideItemCount />
            </DataProvider.Fixed>
          )}
        </QStack>
      </QCard>
      {isOpen && (
        <QLookup.DataProvider.QuickSearchV2
          quickSearchClient={globalSearchApi}
          qriClient={qriApi}
          resource="document"
          defaultDataQRIs={defaultDataQRIs}
        >
          <QLookup.MultiSelect
            isOpen={isOpen}
            onCancel={onClose}
            onSelect={onSelect}
            accessors={ACCESSORS}
            view={DESIGN_ELEMENT_VIEW}
            isItemPreSelected={isPreselected}
            data-cy={`${dataCy}-lookup`}
          />
        </QLookup.DataProvider.QuickSearchV2>
      )}
    </>
  );
};

const ACCESSORS = {
  id: "id",
} as Pick<SelectableDataAccessors<QLookup.DataProvider.V2Result>, "id">;

const DESIGN_ELEMENT_VIEW = {
  identifiers: {
    header: "ID",
    width: "",
    render: (identifiers) => <QText>{identifiers.code}</QText>,
  },
  displayLabel: {
    header: "Name",
    width: "80%",
    render: (displayLabel) => <QText>{displayLabel}</QText>,
  },
} as QLookup.DataView<QLookup.DataProvider.V2Result>;
