import { BaseList } from "@design-controls/types";
import { QTag } from "@qualio/ui-components";
import React from "react";
import { elementStatus, ElementStatusTag } from "../../domain/elementStatus";

type Props = {
  item: Pick<BaseList, "changeControlStatus" | "itemStatus">;
};

export const StatusTag: React.FC<Props> = ({ item }) => {
  const status = elementStatus(item);
  return <QTag variantColor={ElementStatusTag[status]}>{status}</QTag>;
};
