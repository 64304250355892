import React, { useCallback, useEffect } from "react";
import {
  FormToRiskPolicy,
  RiskFormValidation,
  RiskPolicyToForm,
} from "../../../components/RiskPolicy/validation";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { useRiskPolicy } from "../../../hooks/createProductSteps/useRiskPolicy";
import { RiskPolicy } from "../../../components/RiskPolicy/RiskPolicy";
import { useTestCasePolicy } from "../../../hooks/createProductSteps/useTestCasePolicy";
import { useRequirementPolicy } from "../../../hooks/createProductSteps/useRequirementPolicy";

type Props = {
  nextRef: React.RefObject<any>;
};

export const Risk: React.FC<Props> = ({ nextRef }) => {
  const { policies: requirementPolicies } = useRequirementPolicy();
  const { policies: testCasePolicies } = useTestCasePolicy();
  const { policy, storeRiskPolicy, setDisabled } = useRiskPolicy();

  const methods = useForm<RiskFormValidation>({
    defaultValues: RiskPolicyToForm(policy),
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(RiskFormValidation),
  });

  const onSubmit = useCallback(
    (form: RiskFormValidation) => {
      const hasErrors = false;
      const prefixes = new Set(
        [
          ...Object.values(requirementPolicies),
          ...Object.values(testCasePolicies),
        ]
          .filter(Boolean)
          .map((policy) => policy.codePrefix),
      );

      if (prefixes.has(form.codePrefix)) {
        methods.setError(`codePrefix`, {
          message: "Prefix in use",
        });
      }

      if (!hasErrors) {
        storeRiskPolicy(FormToRiskPolicy(form));
      }
    },
    [requirementPolicies, testCasePolicies, storeRiskPolicy, methods.setError],
  );

  const {
    formState: { isValid },
  } = methods;

  useEffect(() => {
    setDisabled(!isValid);
  }, [isValid, setDisabled]);

  return (
    <FormProvider {...methods}>
      <RiskPolicy />
      <button
        style={{ display: "none" }}
        type="submit"
        ref={nextRef}
        onClick={methods.handleSubmit(onSubmit)}
      />
    </FormProvider>
  );
};
