import { ReleaseReport } from "@design-controls/types";
import React, { useCallback } from "react";
import {
  createQColumnHelper,
  DataProvider,
  QCard,
  QCollapse,
  QDataTable,
  QHeading,
  QIconButton,
  QProgress,
  QStack,
  QText,
} from "@qualio/ui-components";
import { useDisclosure } from "@chakra-ui/hooks";
import { downloadFile } from "./downloadFIle";

const columnHelper = createQColumnHelper<ReleaseReport["reports"][0]>();

type Props = {
  data?: ReleaseReport;
  isComplete: boolean;
};

export const ReportsTable: React.FC<Props> = ({ isComplete, data }) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  const onDownloadClick = useCallback(
    (record: ReleaseReport["reports"][0]) =>
      !!record.url && downloadFile(record.url),
    [],
  );

  const columns = [
    columnHelper.text((record) => record.url?.split(".").pop()?.toUpperCase(), {
      header: "File type",
      maxWidth: "50px",
    }),
    columnHelper.text((record) => record.name?.split("-").pop(), {
      header: "Title",
      maxWidth: "648px",
    }),
    {
      header: " ",
      meta: {
        maxWidth: "50px",
      },
      cell: ({
        row: { original: row },
      }: {
        row: { original: ReleaseReport["reports"][0] };
      }) => {
        return (
          <QIconButton
            iconName="Download"
            aria-label="Download"
            data-cy="download"
            onClick={() => onDownloadClick(row)}
          />
        );
      },
    },
  ];

  if (!data) {
    return null;
  }

  return (
    <QCard>
      <QStack
        direction="row"
        justifyContent="space-between"
        padding="12px 16px"
      >
        <QHeading fontSize="md" fontWeight={600}>
          Documents
        </QHeading>
        {isComplete && (
          <QIconButton
            onClick={onToggle}
            iconName={isOpen ? "ChevronUp" : "ChevronDown"}
            aria-label={
              isOpen ? "Collapse requirements" : "Expand requirements"
            }
          />
        )}
        {!isComplete && (
          <QStack direction="column">
            <QText fontSize="xs" color="gray.500">
              Generating...
            </QText>
            <QProgress isIndeterminate />
          </QStack>
        )}
      </QStack>
      <QCollapse isOpen={isOpen}>
        {isComplete && (
          <DataProvider.Fixed data={data.reports}>
            <QDataTable
              columns={columns}
              hideItemCount
              data-cy="document-list"
              enableSorting={false}
              getRowId={(record: ReleaseReport["reports"][0]) =>
                record.name?.split("-").pop()?.trim() ?? ""
              }
            />
          </DataProvider.Fixed>
        )}
      </QCollapse>
    </QCard>
  );
};
