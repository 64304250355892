import { ChangeControlStatus, ReviewStatus } from "@design-controls/types";

export const ReviewStatusTable: Record<ReviewStatus, string> = {
  [ReviewStatus.enum.IN_PROGRESS]: "In progress",
  [ReviewStatus.enum.SENT_FOR_APPROVAL]: "For approval",
  [ReviewStatus.enum.APPROVED]: "Approved",
  [ReviewStatus.enum.NOT_APPROVED]: "Declined",
};

export const ElementReviewStatus: Record<ChangeControlStatus, string> = {
  [ChangeControlStatus.enum.NOT_IN_REVIEW]: "Not in review",
  [ChangeControlStatus.enum.IN_PROGRESS]: "In progress",
  [ChangeControlStatus.enum.APPROVED]: "Approved",
  [ChangeControlStatus.enum.NOT_APPROVED]: "Declined",
};
