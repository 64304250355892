import { useCallback } from "react";
import { AwsPresignedApi } from "../api/awsPresigned";
import { designControlsApi } from "../api/designControls";
import { useCurrentUser } from "@qualio/ui-components";
import { useCurrentProduct } from "./useCurrentProduct";

export const useUploadFile = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();

  return useCallback(
    async (file: File) => {
      const response = await designControlsApi.generatePutUrl(
        companyId,
        productId,
        {
          filename: file.name,
          filesize: file.size,
          contentType: file.type,
        },
      );

      await AwsPresignedApi.putPresignedUrl(response.presignedUrl, file);
      return response.attachment;
    },
    [companyId, productId],
  );
};
