import React, { useCallback, useMemo } from "react";

import { ReviewList as ReviewListType } from "@design-controls/types";
import {
  createQColumnHelper,
  DataProvider,
  Filtering,
  Pagination,
  QDataTable,
  Sorting,
} from "@qualio/ui-components";

import { useRetrieveReviews } from "../../hooks/useRetrieveReviews";
import { Owner } from "./filter/Owner";
import { Status } from "./filter/Status";
import { useRetrieveUsers } from "../../hooks/useRetrieveUsers";

const columnHelper = createQColumnHelper<ReviewListType>();

const StatusTable: Record<string, string> = {
  IN_PROGRESS: "In progress",
  SENT_FOR_APPROVAL: "For approval",
  APPROVED: "Approved",
  NOT_APPROVED: "Declined",
};

export const ReviewList: React.FC = () => {
  const { isLoading, data } = useRetrieveReviews();
  const { isLoading: usersIsLoading, data: users } = useRetrieveUsers();

  const columns = useMemo(() => {
    return [
      columnHelper.code("code", { header: "ID", maxWidth: "100px" }),
      columnHelper.textLink("title", (record) => `${record.id}/view`, {
        header: "Review name",
        weight: 1,
      }),
      columnHelper.text((record) => record.createdBy.fullName, {
        header: "Owner",
      }),
      columnHelper.date((record) => new Date(record.updated), {
        header: "Last modified",
        id: "updated",
        maxWidth: "150px",
      }),
      columnHelper.status((record) => StatusTable[record.status], {
        header: "Status",
        maxWidth: "150px",
        statuses: {
          "In progress": "gray",
          "For approval": "blue",
          Approved: "green",
          Declined: "red",
        },
      }),
    ];
  }, []);

  const filterDefinitions = useMemo(() => {
    return {
      owner: {
        label: "Owner",
        schema: Filtering.schemas.StringSchema().nullish(),
        activeRender: (v: any) => ({
          label: "Owner:",
          value: v && users?.find((item) => item.id === Number(v))?.fullName,
        }),
      },
      status: {
        label: "Status",
        schema: Filtering.schemas.StringSchema().nullish(),
        activeRender: (v: any) => ({
          label: "Status:",
          value: v && StatusTable[v],
        }),
      },
    };
  }, [users]);

  const filterOption = useCallback(
    (
      item: Readonly<ReviewListType>,
      searchTerm: string | undefined,
      filters: Filtering.ResolvedFilters<typeof filterDefinitions> | undefined,
    ) => {
      if (
        searchTerm &&
        !item.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
      ) {
        return false;
      }

      if (filters && Object.values(filters).some((field) => field.value)) {
        const { owner, status } = filters;

        return (
          (!owner?.value || item.createdBy.id === owner.value) &&
          (!status?.value || item.status === status.value)
        );
      }

      return true;
    },
    [],
  ) as DataProvider.FilterOptionFn<ReviewListType>;

  return (
    <Pagination.Auto pageSizeKey="size" pageIndexKey="page" clientSide>
      <Sorting.DefaultSortingProvider
        initialSort={{ column: "updated", descending: true }}
      >
        <Filtering.FilterProvider
          definitions={filterDefinitions}
          searchTermKey="search"
        >
          <DataProvider.Fixed
            data={data ?? []}
            isLoading={isLoading}
            filterOption={filterOption}
          >
            <QDataTable
              columns={columns}
              data-cy="review-list"
              getRowId={(row) => row.id}
            >
              <Filtering.FormContent>
                <Owner isLoading={usersIsLoading} data={users} />
                <Status />
              </Filtering.FormContent>
            </QDataTable>
          </DataProvider.Fixed>
        </Filtering.FilterProvider>
      </Sorting.DefaultSortingProvider>
    </Pagination.Auto>
  );
};
