import { FMEARiskDesignElement, isRiskPolicy } from "@design-controls/types";
import React from "react";
import { useCurrentConfigs } from "../../../hooks/useCurrentConfigs";
import { QTag, QText } from "@qualio/ui-components";
import { riskScore } from "../../../domain/fmeaRequiresControl";

type Props = {
  severity?: FMEARiskDesignElement["preMitigation"]["severity"];
  detectability?: FMEARiskDesignElement["preMitigation"]["detectability"];
  occurence?: FMEARiskDesignElement["preMitigation"]["occurrence"];
};

export const FormRiskScore: React.FC<Props> = ({
  severity,
  detectability,
  occurence,
}) => {
  const riskPolicy = useCurrentConfigs().find(isRiskPolicy);
  if (!riskPolicy || !severity || !detectability || !occurence) {
    return <QText>-</QText>;
  }

  const score = riskScore(severity, detectability, occurence) ?? 0;
  const threshold = Number(
    riskPolicy.assessment.fmea?.mitigationThreshold ?? 0,
  );

  return <QTag variantColor={score > threshold ? "red" : "blue"}>{score}</QTag>;
};
