import { useCurrentUser } from "@qualio/ui-components";
import { useCurrentProduct } from "../useCurrentProduct";
import { useCallback } from "react";
import { downloadFile } from "../../components/OverviewTable/downloadFIle";

export const useDownloadTraceability = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();

  const download = useCallback(() => {
    downloadFile(
      `${process.env.REACT_APP_API_BASE_URL}/company/${companyId}/product-development/product/${productId}/download`,
    );
  }, [companyId, productId]);

  return {
    onDownloadFile: download,
  };
};
