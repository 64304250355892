import React, { useCallback, useMemo, useState } from "react";

import {
  createQColumnHelper,
  DataProvider,
  QBox,
  QCard,
  QCenter,
  QCollapse,
  QDataTable,
  QHeading,
  QIcon,
  QIconButton,
  QStack,
  QTag,
  QTooltip,
} from "@qualio/ui-components";

import { TestCaseDesignElement } from "@design-controls/types";
import { useCurrentConfigs } from "../../hooks/useCurrentConfigs";
import { ItemStatusTag } from "../ItemStatusTag/ItemStatusTag";
import { Link, useNavigate } from "react-router-dom";

const columnHelper = createQColumnHelper<TestCaseDesignElement>();

type DesignElement = { latest?: string } & TestCaseDesignElement;

type Props = {
  testCases: Array<DesignElement>;
  underReview?: boolean;
  versioned?: boolean;
};

export const TestCaseTable: React.FC<Props> = ({
  testCases,
  underReview,
  versioned = false,
}) => {
  const [expanded, setExpanded] = useState(true);
  const configs = useCurrentConfigs();
  const navigate = useNavigate();

  const onClickToDiff = useCallback(
    (item: DesignElement) => {
      return () => navigate(`${item.id}`);
    },
    [navigate],
  );

  const columns = useMemo(() => {
    const columns = [
      columnHelper.text("code", { header: "Code", maxWidth: "100px" }),
      {
        accessorKey: "title",
        header: "Title",
        meta: {
          maxWidth: "648px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: DesignElement };
        }) => {
          return (
            <Link
              to={`../test-case/${row.id}${versioned ? `/version/${row.version}` : ""}`}
              target="_blank"
            >
              {row.title}
            </Link>
          );
        },
      },
      columnHelper.text((record) => record.category?.label, {
        header: "Category",
        maxWidth: "150px",
      }),
      {
        header: "Type",
        meta: {
          maxWidth: "150px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: TestCaseDesignElement };
        }) => {
          return (
            <QTag variantColor="gray">
              {
                (
                  configs.find((config) => config.type === row.type) as
                    | { label: string }
                    | undefined
                )?.label
              }
            </QTag>
          );
        },
      },
      {
        header: "Status",
        meta: {
          maxWidth: "150px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: TestCaseDesignElement };
        }) => {
          return <ItemStatusTag itemStatus={row.itemStatus} />;
        },
      },
    ];

    if (versioned) {
      columns.push({
        header: " ",
        id: "diff",
        meta: {
          maxWidth: "50px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: DesignElement };
        }) => {
          return (
            row.itemStatus !== "NEW" && (
              <QCenter>
                <QTooltip label="See difference">
                  <QBox>
                    <QIconButton
                      iconName="GitPullRequest"
                      aria-label="See difference between version"
                      onClick={onClickToDiff(row)}
                    />
                  </QBox>
                </QTooltip>
              </QCenter>
            )
          );
        },
      });
    }

    if (underReview) {
      columns.push({
        header: " ",
        meta: {
          maxWidth: "90px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: DesignElement };
        }) => {
          return row.latest && row.version !== row.latest ? (
            <QCenter>
              <QIcon
                iconName="FillExclamationTriangleFill"
                color="orange.500"
              />
            </QCenter>
          ) : null;
        },
      });
    }

    return columns;
  }, [underReview, versioned, onClickToDiff]);

  if (!testCases.length) {
    return null;
  }

  return (
    <QCard>
      <QStack
        direction="row"
        justifyContent="space-between"
        padding="12px 16px"
      >
        <QHeading fontSize="md" fontWeight={600}>
          Test cases
        </QHeading>
        <QIconButton
          onClick={() => setExpanded((s) => !s)}
          iconName={expanded ? "ChevronUp" : "ChevronDown"}
          aria-label={expanded ? "Collapse test cases" : "Expand test cases"}
        />
      </QStack>
      <QCollapse isOpen={expanded}>
        <DataProvider.Fixed data={testCases}>
          <QDataTable
            columns={columns}
            hideItemCount
            data-cy="test-case-list"
          />
        </DataProvider.Fixed>
      </QCollapse>
    </QCard>
  );
};
