import React, { useCallback, useState } from "react";
import { useDisclosure } from "@chakra-ui/hooks";
import { useApproveReview } from "../../../hooks/review/useApproveReview";
import {
  QApprovalRequestAlert,
  QButton,
  QFormControl,
  QInput,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QStack,
  QText,
  QTextarea,
} from "@qualio/ui-components";
import {
  Approval,
  ReviewStatus,
  ReviewStatusEnum,
} from "@design-controls/types";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

export const ReviewApproval: React.FC = () => {
  const { mutate, isLoading } = useApproveReview();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [approvalType, setApprovalType] = useState<
    ReviewStatusEnum.APPROVED | ReviewStatusEnum.NOT_APPROVED | null
  >(null);

  const methods = useForm<Omit<Approval, "status">>({
    defaultValues: {
      note: "",
      signature: {
        email: "",
        password: "",
      },
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(Approval.omit({ status: true })),
  });

  const {
    formState: { errors },
  } = methods;

  const onApprove = useCallback(() => {
    setApprovalType(ReviewStatus.enum.APPROVED);
    onOpen();
  }, [onOpen, setApprovalType]);

  const onReject = useCallback(() => {
    setApprovalType(ReviewStatus.enum.NOT_APPROVED);
    onOpen();
  }, [onOpen, setApprovalType]);

  const onCloseModal = useCallback(() => {
    setApprovalType(null);
    onClose();
  }, [onClose, setApprovalType]);

  const onSubmit = useCallback(
    (form: Omit<Approval, "status">) => {
      if (approvalType === null) {
        return;
      }
      mutate(
        { ...form, status: approvalType },
        {
          onSuccess: () => {
            setApprovalType(null);
            onClose();
          },
        },
      );
    },
    [mutate, approvalType, onClose, setApprovalType],
  );

  return (
    <>
      <QApprovalRequestAlert onApprove={onApprove} onReject={onReject} />
      <FormProvider {...methods}>
        <QModal isOpen={isOpen} onClose={onCloseModal}>
          <QModalHeader>
            <QText>
              {approvalType === ReviewStatus.enum.APPROVED
                ? "Approve design review"
                : "Decline design review"}
            </QText>
          </QModalHeader>
          <QModalBody>
            <QStack gap="8px">
              <QText>
                Enter your digital signature to approve this design review.
              </QText>
              <QFormControl
                label="Email"
                isInvalid={!!errors.signature?.email}
                error={errors.signature?.email?.message}
              >
                <Controller
                  name="signature.email"
                  render={({ field: { onChange, value } }) => (
                    <QInput
                      value={value}
                      onChange={onChange}
                      autoComplete="off"
                      data-cy="signature-email"
                    />
                  )}
                />
              </QFormControl>
              <QFormControl
                label="Password"
                isInvalid={!!errors.signature?.password}
                error={errors.signature?.password?.message}
              >
                <Controller
                  name="signature.password"
                  render={({ field: { onChange, value } }) => (
                    <QInput
                      value={value}
                      onChange={onChange}
                      type="password"
                      autoComplete="off"
                      data-cy="signature-password"
                    />
                  )}
                />
              </QFormControl>
              <QFormControl
                label="Comment"
                isInvalid={!!errors.note}
                error={errors.note?.message}
              >
                <Controller
                  name="note"
                  render={({ field: { onChange, value } }) => (
                    <QTextarea
                      value={value}
                      onChange={onChange}
                      data-cy="signature-note"
                    />
                  )}
                />
              </QFormControl>
            </QStack>
          </QModalBody>
          <QModalActions>
            <QButton
              variant="outline"
              onClick={onCloseModal}
              isDisabled={isLoading}
              data-cy="signature-cancel-btn"
            >
              Cancel
            </QButton>
            <QButton
              isLoading={isLoading}
              type="submit"
              onClick={methods.handleSubmit(onSubmit)}
              data-cy="signature-approve-btn"
            >
              Approve
            </QButton>
          </QModalActions>
        </QModal>
      </FormProvider>
    </>
  );
};
