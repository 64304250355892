import React, { useCallback } from "react";
import { useRetrieveReview } from "../../hooks/review/useRetrieveReview";
import { QNotFound, QPageLoader, QText } from "@qualio/ui-components";
import { ReviewForm } from "../../components/ReviewForm/ReviewForm";
import {
  ReviewFormToReviewRecord,
  ReviewForm as ReviewFormValidation,
  ReviewRecordToReviewForm,
} from "../../components/ReviewForm/validation";
import { Outlet, useNavigate } from "react-router";
import { ReviewRecord } from "@design-controls/types";
import { useUpdateReview } from "../../hooks/review/useUpdateReview";

export const EditReview: React.FC = () => {
  const { data, isLoading } = useRetrieveReview();
  const { mutate, isLoading: isSaving } = useUpdateReview();

  const navigate = useNavigate();

  const onSubmit = useCallback(
    (form: ReviewFormValidation) => {
      mutate(
        { ...ReviewFormToReviewRecord(form), id: data?.id },
        {
          onSuccess: (response: Omit<ReviewRecord, "qualityItems">) => {
            navigate(`../change-control/${response.id}/view`);
          },
        },
      );
    },
    [mutate, navigate, data],
  );

  const onClose = useCallback(() => navigate("../change-control"), [navigate]);

  if (isLoading) {
    return <QPageLoader />;
  }

  if (!data) {
    return <QNotFound />;
  }

  return (
    <>
      <ReviewForm
        isSaving={isSaving}
        onSubmit={onSubmit}
        defaultValue={ReviewRecordToReviewForm(data)}
        onClose={onClose}
      >
        <QText as="span" color="gray.500">
          {data.code}
        </QText>{" "}
        {data.title}
      </ReviewForm>
      <Outlet />
    </>
  );
};
