import { isFmeaRiskList, Policy } from "@design-controls/types";
import {
  QButton,
  QDeleteConfirmationModal,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QText,
} from "@qualio/ui-components";
import React, { useMemo } from "react";
import { useRetrieveElements } from "../../../hooks/designElement/useRetrieveEleements";
import { useCurrentConfigs } from "../../../hooks/useCurrentConfigs";
import { useDeletePolicy } from "../../../hooks/policy/useDeletePolicy";

type Props = {
  type: Policy["type"];
  onClose: () => void;
  onDelete?: () => void;
};

export const DeletePolicy: React.FC<Props> = ({ type, onClose, onDelete }) => {
  const { data, isLoading } = useRetrieveElements();
  const policy = useCurrentConfigs().find((t) => t.type === type);
  const { isLoading: isDeleting, mutate } = useDeletePolicy();

  const elementsExist = useMemo(() => {
    if (type === "risk") {
      return !!data?.filter(isFmeaRiskList).length;
    }
    return data?.some((item) => item.type === type);
  }, [type, data]);

  if (isLoading || !policy) {
    return null;
  }

  if (elementsExist) {
    return (
      <QModal isOpen={true} onClose={onClose}>
        <QModalHeader>
          <QText data-cy="policy-in-use">Policy in use</QText>
        </QModalHeader>
        <QModalBody>
          This policy is still used in the product. To delete it here, first
          delete this products {type === "risk" ? "FMEA risks" : policy.label}
        </QModalBody>
        <QModalActions>
          <QButton onClick={onClose} data-cy="policy-in-use-btn">
            Ok
          </QButton>
        </QModalActions>
      </QModal>
    );
  }

  return (
    <QDeleteConfirmationModal
      onConfirm={() => {
        if (!!onDelete) {
          onDelete();
        } else {
          mutate(type);
        }
      }}
      onClose={onClose}
      title="Delete policy?"
      inProgress={isDeleting}
      message={
        <QText fontSize="sm">
          It won’t be possible to create design elements based on this policy
          anymore.
        </QText>
      }
    />
  );
};
