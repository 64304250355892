import { QPageLoader } from "@qualio/ui-components";
import React from "react";
import { TraceabilityMatrix } from "../../components/TraceabilityMatrix/TraceabilityMatrix";
import { useReleaseTraceability } from "../../hooks/designElement/useReleaseTraceability";

type Props = {
  releaseId?: string;
};

export const ReviewTraceability: React.FC<Props> = ({ releaseId }) => {
  const { isLoading, data: traceabilityMatrix } =
    useReleaseTraceability(releaseId);

  if (isLoading || !traceabilityMatrix) {
    return <QPageLoader />;
  }

  return <TraceabilityMatrix matrix={traceabilityMatrix} isRelease />;
};
