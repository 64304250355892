import { BaseList, ChangeControlStatus } from "@design-controls/types";
import { QTagProps } from "@qualio/ui-components";

const mapItemStatus: Record<BaseList["itemStatus"], string> = {
  NEW: "New",
  CHANGED: "Changed",
  DELETED: "Deleted",
};

const mapChangeControlStatus: Record<BaseList["changeControlStatus"], string> =
  {
    [ChangeControlStatus.enum.IN_PROGRESS]: "In review",
    [ChangeControlStatus.enum.APPROVED]: "Approved",
    [ChangeControlStatus.enum.NOT_APPROVED]: "Declined",
  };

export const elementStatus = (
  item: Pick<BaseList, "changeControlStatus" | "itemStatus">,
): string => {
  return item.changeControlStatus === "unstaged"
    ? mapItemStatus[item.itemStatus]
    : mapChangeControlStatus[item.changeControlStatus];
};

export const ElementStatusTag: Record<string, QTagProps["variantColor"]> = {
  New: "gray",
  Changed: "orange",
  Deleted: "red",
  "In review": "blue",
  Approved: "green",
  Declined: "red",
};
