import { RequirementDesignElement } from "@design-controls/types";
import React, { useMemo } from "react";
import {
  DataProvider,
  QDataTable,
  QIcon,
  QStack,
  QText,
} from "@qualio/ui-components";
import { sanitize } from "dompurify";
import diff from "../htmldiff/htmldiff";

type Props = {
  base: RequirementDesignElement;
  compare: RequirementDesignElement;
};

type Column = {
  label: string;
  base: any;
  compare: any;
};

export const Requirement: React.FC<Props> = ({ base, compare }) => {
  const comparison = diff(base?.description ?? "", compare?.description ?? "");

  const rows = useMemo(() => {
    const rows: Column[] = [];

    if (base.title !== compare.title) {
      rows.push({
        label: "Title",
        base: base.title,
        compare: compare.title,
      });
    }

    if (base.component?.qri !== base.component?.qri) {
      rows.push({
        label: "Component",
        base: base.component?.label,
        compare: compare.component?.label,
      });
    }

    if (base.category?.qri !== base.category?.qri) {
      rows.push({
        label: "Category",
        base: base.category?.label,
        compare: compare.category?.label,
      });
    }

    return rows;
  }, [base, compare]);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: "label",
        header: "Label",
        meta: {
          width: "200px",
        },
        cell: ({ row: { original: row } }: { row: { original: Column } }) => (
          <QText fontWeight={600}>{row.label}</QText>
        ),
      },
      {
        accessorKey: "base",
        header: "Base",
        meta: {
          minWidth: "200px",
        },
        cell: ({ row: { original: row } }: { row: { original: Column } }) =>
          row.base ?? <QText color="gray.500">Empty</QText>,
      },
      {
        accessorKey: "id",
        header: "icon",
        meta: {
          width: "60px",
        },
        cell: () => <QIcon iconName="ArrowRight" />,
      },
      {
        accessorKey: "compare",
        header: "Compare",
        meta: {
          minWidth: "200px",
        },
        cell: ({ row: { original: row } }: { row: { original: Column } }) =>
          row.compare ?? <QText color="gray.500">Empty</QText>,
      },
    ];
  }, []);

  return (
    <QStack gap="16px">
      {rows.length && (
        <DataProvider.Fixed data={rows}>
          <QDataTable columns={columns} hideItemCount withHeaders={false} />
        </DataProvider.Fixed>
      )}
      {base.description !== compare.description && (
        <QStack>
          <QText fontSize="sm" fontWeight={600} color="gray.900">
            Description
          </QText>

          <QText
            fontSize={14}
            style={{ wordBreak: "break-word" }}
            dangerouslySetInnerHTML={{
              __html: sanitize(comparison),
            }}
          />
        </QStack>
      )}
    </QStack>
  );
};
