import {
  isDesignElementRequirement,
  isDesignElementRisk,
  isDesignElementTestCase,
  ReviewRecord,
} from "@design-controls/types";
import { z } from "zod";

export const ReviewForm = z
  .object({
    requirements: ReviewRecord.shape.qualityItems,
    testCases: ReviewRecord.shape.qualityItems,
    risks: ReviewRecord.shape.qualityItems,
  })
  .merge(
    ReviewRecord.omit({
      qualityItems: true,
      code: true,
      status: true,
      created: true,
      updated: true,
      id: true,
      createdBy: true,
    }),
  );
export type ReviewForm = z.infer<typeof ReviewForm>;

export const ReviewRecordToReviewForm = (review: ReviewRecord): ReviewForm => ({
  ...review,
  requirements: review.qualityItems.filter(isDesignElementRequirement),
  testCases: review.qualityItems.filter(isDesignElementTestCase),
  risks: review.qualityItems.filter(isDesignElementRisk),
});

export const ReviewFormToReviewRecord = (
  form: ReviewForm,
): Partial<ReviewRecord> => {
  const { requirements, testCases, risks, ...rest } = form;

  return {
    ...rest,
    description: rest.description ?? "",
    qualityItems: requirements.concat(testCases).concat(risks),
  };
};
