import React, { useCallback } from "react";
import { ProductProfile } from "../../../components/ProductProfile/ProductProfile";
import { Product } from "@design-controls/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { useCurrentProduct } from "../../../hooks/useCurrentProduct";
import { useUpdateProduct } from "../../../hooks/product/useUpdateProduct";

type Props = {
  nextRef: React.RefObject<any>;
};

export const ProductConfigure: React.FC<Props> = ({ nextRef }) => {
  const product = useCurrentProduct();
  const { mutate } = useUpdateProduct();

  const methods = useForm<Product>({
    defaultValues: product,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(Product),
  });

  const onSubmit = useCallback(
    (productData: Product) => {
      mutate(productData);
    },
    [mutate],
  );

  return (
    <FormProvider {...methods}>
      <ProductProfile />
      <button
        style={{ display: "none" }}
        ref={nextRef}
        onClick={methods.handleSubmit(onSubmit)}
        type="submit"
      />
    </FormProvider>
  );
};
