import { useDisclosure } from "@chakra-ui/hooks";
import {
  QCloseButton,
  QModal,
  QModalBody,
  QModalHeader,
  QPageLoader,
  QText,
} from "@qualio/ui-components";
import React, { useCallback } from "react";
import { useNavigate } from "react-router";
import { useDiffView } from "../../hooks/designElement/useDiffView";
import {
  DesignElement,
  isDesignElementFMEARisk,
  isDesignElementISORisk,
  isDesignElementRequirement,
  isDesignElementTestCase,
} from "@design-controls/types";
import { FMEARisk, ISORisk, Requirement, TestCase } from "./components";

type Props = {
  base: DesignElement;
  compare: DesignElement;
};

const DesignElementComponent: React.FC<Props> = ({ base, compare }) => {
  if (isDesignElementRequirement(base) && isDesignElementRequirement(compare)) {
    return <Requirement base={base} compare={compare} />;
  }

  if (isDesignElementTestCase(base) && isDesignElementTestCase(compare)) {
    return <TestCase base={base} compare={compare} />;
  }

  if (isDesignElementFMEARisk(base) && isDesignElementFMEARisk(compare)) {
    return <FMEARisk base={base} compare={compare} />;
  }

  if (isDesignElementISORisk(base) && isDesignElementISORisk(compare)) {
    return <ISORisk base={base} compare={compare} />;
  }

  return null;
};

export const DiffView: React.FC = () => {
  const { isOpen, onClose } = useDisclosure({ isOpen: true });
  const navigate = useNavigate();
  const { isLoading, baseElement, compareElement, base, compare } =
    useDiffView();

  const onCloseClick = useCallback(() => {
    navigate("..");
    onClose();
  }, [onClose, navigate]);

  return (
    <QModal isOpen={isOpen} onClose={onCloseClick} size="4xl">
      <QModalHeader>
        <QText textTransform="initial">
          {isLoading || !base || !compare
            ? "Loading comparison"
            : `Compare ${baseElement?.code}: ${base?.code} to ${compare?.code}`}
        </QText>
        <QCloseButton onClick={onCloseClick} />
      </QModalHeader>
      <QModalBody>
        {isLoading && <QPageLoader />}
        {!isLoading && baseElement && compareElement && (
          <DesignElementComponent base={baseElement} compare={compareElement} />
        )}
      </QModalBody>
    </QModal>
  );
};
