import { useCurrentUser } from "@qualio/ui-components";
import { useQuery } from "react-query";

import { designControlsApi } from "../api/designControls";
import { useCurrentProduct } from "./useCurrentProduct";

export const useRetrieveDesignElementById = (
  designElementId: string,
  version = "v0",
) => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();

  return useQuery({
    queryKey: [
      "DESIGN_ELEMENT_BY_ID",
      companyId,
      productId,
      designElementId,
      version,
    ],
    queryFn: () =>
      designControlsApi.getById(companyId, productId, designElementId, version),
  });
};
