import { useCurrentUser } from "@qualio/ui-components";
import { useCurrentProduct } from "../useCurrentProduct";
import { useMutation } from "react-query";
import { DesignElement } from "@design-controls/types";
import { designControlsApi } from "../../api/designControls";

export const useUpdateDesignElement = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();

  return useMutation({
    mutationFn: (data: Partial<DesignElement>) =>
      designControlsApi.modifyDesignElement(companyId, productId, data),
  });
};
