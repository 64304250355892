import { is } from "date-fns/locale";
import { riskLevelColorsQTag } from "../../../components/RiskLevel/riskLevelColors";
import { DesignElementType } from "../../../types/baseQualityItem";
import { ChangeControlStatus } from "../../../types/changeControl";
import { RiskSubType } from "../../../types/risk";
import { ResultOptions } from "../../../views/TestCaseResult/TestCaseResult.enums";

type BlueGreenRedYellowGray = "blue" | "green" | "red" | "yellow" | "gray";
type RiskQualityConfigColor = "blue" | "green" | "red" | "yellow" | "orange";
export enum RiskSubTypeDisplayName {
  ISO_14971 = "ISO 14971",
  FMEA = "FMEA",
}

export const changeControlLabelAndColor = (
  status?: ChangeControlStatus,
  isEnabled?: boolean,
): { label: string; color: BlueGreenRedYellowGray } => {
  switch (status) {
    case ChangeControlStatus.APPROVED:
      return { label: "Approved", color: "green" };
    case ChangeControlStatus.NOT_APPROVED:
      return { label: "Rejected", color: "red" };
    case ChangeControlStatus.SENT_FOR_APPROVAL:
      return { label: "For approval", color: "blue" };
    case ChangeControlStatus.IN_PROGRESS:
    default:
      return { label: "In progress", color: isEnabled ? "gray" : "blue" };
  }
};

export const changeControlLabelAndVariantColor = (
  status?: ChangeControlStatus,
  isEnabled?: boolean,
): { label: string; variantColor: BlueGreenRedYellowGray } => {
  const result = changeControlLabelAndColor(status, isEnabled);
  return { label: result.label, variantColor: result.color };
};

export const designElementStatusLabelAndColor = (
  status?: string,
): { label: string; variantColor: BlueGreenRedYellowGray } => {
  switch (status) {
    case "DELETED":
      return { label: "Deleted", variantColor: "red" };
    case "CHANGED":
      return { label: "Changed", variantColor: "yellow" };
    case "NEW":
    default:
      return { label: "New", variantColor: "blue" };
  }
};

export const riskLevelAndColor = (
  level: string,
  riskConfigLevels: string[],
): { label: string; variantColor: RiskQualityConfigColor } | undefined => {
  const levelIndex = riskConfigLevels.indexOf(level);
  if (levelIndex < 0) {
    return undefined;
  }

  const colorsForLevels = riskLevelColorsQTag[riskConfigLevels.length];
  const configColor = colorsForLevels[levelIndex] as RiskQualityConfigColor;
  return { label: level, variantColor: configColor };
};

export const fmeaRiskScoreColor = (
  score: number,
  fmeaRiskPolicyThreshold: number | undefined,
): RiskQualityConfigColor | "default" => {
  if (!fmeaRiskPolicyThreshold) {
    return "default";
  }
  return score < fmeaRiskPolicyThreshold ? "blue" : "red";
};

export const testResultStatusLabelAndColor = (
  result: ResultOptions,
): { label: string; variantColor: BlueGreenRedYellowGray } => {
  switch (result) {
    case ResultOptions.PASSED:
      return { label: "Passed", variantColor: "green" };
    case ResultOptions.FAILED:
      return { label: "Failed", variantColor: "red" };
    case ResultOptions.BLOCKED:
    default:
      return { label: "Blocked", variantColor: "gray" };
  }
};

export const designElementConfigLabelAndColor = (
  configType: string,
  subType?: string,
): { label: string; variantColor: BlueGreenRedYellowGray } | undefined => {
  if (!configType) {
    return undefined;
  }
  let displayLabel: string = configType;
  if (configType.toLowerCase() === DesignElementType.RISK) {
    displayLabel =
      subType && subType === RiskSubType.FMEA
        ? RiskSubTypeDisplayName.FMEA
        : RiskSubTypeDisplayName.ISO_14971;
  }

  return { label: displayLabel, variantColor: "gray" };
};

export const documentsStatusLabelAndColor = (
  status: string,
): { label: string; variantColor: BlueGreenRedYellowGray } => {
  return status === "effective"
    ? { label: "Effective", variantColor: "green" }
    : { label: status, variantColor: "gray" };
};

export const approverStatusLabelAndColor = (
  status?: ChangeControlStatus,
): { label: string; color: BlueGreenRedYellowGray } => {
  switch (status) {
    case ChangeControlStatus.APPROVED:
      return { label: "Approved", color: "green" };
    case ChangeControlStatus.NOT_APPROVED:
      return { label: "Rejected", color: "red" };
    case ChangeControlStatus.IN_PROGRESS:
    default:
      return { label: "Pending", color: "blue" };
  }
};
