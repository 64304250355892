import { FMEAAssessment, RiskPolicy } from "@design-controls/types";
import { z } from "zod";

const stringBetween = (value: string, min: number, max: number) => {
  const valueToNumber = Number(value);
  return valueToNumber >= min && valueToNumber <= max;
};

export const RiskFormValidation = z
  .object({
    codePrefix: RiskPolicy.shape.codePrefix,
    label: RiskPolicy.shape.label,
    type: RiskPolicy.shape.type,
    iso: z.object({
      levels: z
        .object({
          levelLabel: z.string(),
          requiresControl: z.union([z.literal("no"), z.literal("yes")]),
        })
        .array()
        .min(2)
        .max(5)
        .superRefine((items, ctx) => {
          const seen: string[] = [];
          items.forEach((item, index) => {
            if (seen.includes(item.levelLabel)) {
              ctx.addIssue({
                code: "custom",
                message: "Risk levels must be unique",
                path: [index, "levelLabel"],
              });
            } else {
              seen.push(item.levelLabel);
            }
          });
        }),
      riskMatrix: z.object({
        rows: z
          .string()
          .refine(
            (value) => stringBetween(value, 3, 5),
            "Must be between 3 and 5",
          ),
        columns: z
          .string()
          .refine(
            (value) => stringBetween(value, 3, 5),
            "Must be between 3 and 5",
          ),
        matrix: z.string().array().array(),
        probability: z.string().array(),
        severity: z.string().array(),
      }),
    }),
    fmeaEnabled: z.boolean(),
    fmea: FMEAAssessment.optional(),
  })
  .superRefine((formPolicy, ctx) => {
    const levels = formPolicy.iso.levels.map((item) => item.levelLabel);
    for (let row = 0; row < formPolicy.iso.riskMatrix.matrix.length; row++) {
      for (
        let column = 0;
        column < formPolicy.iso.riskMatrix.matrix[row].length;
        column++
      ) {
        if (!levels.includes(formPolicy.iso.riskMatrix.matrix[row][column])) {
          ctx.addIssue({
            code: "custom",
            message: "Invalid level",
            path: ["iso", "riskMatrix", "matrix", row, column],
          });
        }
      }
    }
  });

export type RiskFormValidation = z.infer<typeof RiskFormValidation>;

export const RiskPolicyToForm = (policy: RiskPolicy): RiskFormValidation => {
  return {
    codePrefix: policy.codePrefix,
    label: policy.label,
    type: policy.type,
    iso: {
      levels: policy.assessment.iso.levels.map((level, index) => ({
        levelLabel: level,
        requiresControl: policy.assessment.iso.acceptableLevels.includes(level)
          ? "no"
          : "yes",
      })),
      riskMatrix: {
        rows: policy.assessment.iso.levelMapping.length.toString(10),
        columns: policy.assessment.iso.levelMapping[0].length.toString(10),
        matrix: policy.assessment.iso.levelMapping,
        probability: policy.assessment.iso.probability,
        severity: policy.assessment.iso.severity,
      },
    },
    fmea: {
      scaleMaxValue: policy.assessment.fmea
        ? policy.assessment.fmea.scaleMaxValue
        : 0,
      mitigationThreshold: policy.assessment.fmea
        ? policy.assessment.fmea.mitigationThreshold
        : 0,
    },
    fmeaEnabled: !!policy.assessment.fmea,
  };
};

export const FormToRiskPolicy = (form: RiskFormValidation): RiskPolicy => ({
  codePrefix: form.codePrefix,
  label: form.label,
  type: "risk",
  codeStrategy: "GENERATED",
  assessment: {
    iso: {
      acceptableLevels: form.iso.levels
        .filter((level) => level.requiresControl === "no")
        .map((level) => level.levelLabel),
      levelMapping: form.iso.riskMatrix.matrix
        .map((row) => row.splice(0, Number(form.iso.riskMatrix.columns)))
        .splice(0, Number(form.iso.riskMatrix.rows)),
      probability: form.iso.riskMatrix.probability.splice(
        0,
        Number(form.iso.riskMatrix.rows),
      ),
      severity: form.iso.riskMatrix.severity.splice(
        0,
        Number(form.iso.riskMatrix.columns),
      ),
      levels: form.iso.levels.map((level) => level.levelLabel),
    },
    fmea:
      form.fmeaEnabled && form.fmea
        ? {
            scaleMaxValue: Number(form.fmea.scaleMaxValue),
            mitigationThreshold: Number(form.fmea.mitigationThreshold),
          }
        : undefined,
  },
});
