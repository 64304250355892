import {
  Attachment,
  BaseDesignElement,
  LinkedElement,
  DesignElement,
  ElementList,
  Policy,
  Product,
  ProductWithoutId,
  PresignedUrlResponse,
  QualioUser,
  ReviewList,
  TestResult,
  ReviewRecord,
  ReleaseRecord,
  ReleaseReport,
  Approval,
  TraceabilityMatrix,
  ReviewHistory,
} from "@design-controls/types";
import axios from "axios";

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

export const designControlsApi = {
  async createProduct(company: number, product: ProductWithoutId) {
    const response = await api.post<{ data: Product }>(
      `/company/${company}/product-development/product`,
      { data: product },
    );

    return Product.parse({
      ...response.data.data,
      // handle type issue
      type:
        (response.data.data.type as any) === ""
          ? undefined
          : response.data.data.type,
    });
  },
  async updateProduct(company: number, product: Product) {
    await api.put(
      `/company/${company}/product-development/product/${product.id}`,
      { data: product },
    );
  },
  async createPolicy(company: number, product: string, policy: Policy) {
    await api.post(
      `/company/${company}/product-development/product/${product}/config`,
      { data: policy },
    );
  },
  async updatePolicy(company: number, product: string, policy: Policy) {
    return api.put(
      `/company/${company}/product-development/product/${product}/config/${policy.type}`,
      { data: { ...policy, workflow: { states: [] } } },
    );
  },
  deletePolicy(company: number, product: string, policy: Policy["type"]) {
    return api
      .delete(
        `/company/${company}/product-development/product/${product}/config/${policy}`,
      )
      .then(() => undefined);
  },
  retrieveArchivePrefixes(
    company: number,
    product: string,
  ): Promise<Array<string>> {
    return api
      .get<{
        data: Array<string>;
      }>(
        `/company/${company}/product-development/product/${product}/archivePrefixes`,
      )
      .then((res) => res.data.data);
  },
  async query(
    company: number,
    product: string,
    type?: string,
  ): Promise<Array<ElementList>> {
    const elementTypeQuery = type ? `?type=${type}` : "";

    const response = await api.get<{ data: Array<ElementList> }>(
      `/company/${company}/product-development/product/${product}/openIssue${elementTypeQuery}`,
    );

    return ElementList.array().parse(response.data.data);
  },
  async getById(
    company: number,
    product: string,
    qualityItemId: string,
    qualityItemVersion?: string,
  ) {
    let url = `/company/${company}/product-development/product/${product}/qualityItem/${qualityItemId}`;
    if (qualityItemVersion !== undefined) {
      url = `${url}/version/${qualityItemVersion}`;
    }

    const response = await api.get<{ data: Array<DesignElement> }>(url);
    return DesignElement.parse(response.data.data);
  },
  async retrieveReviews(company: number, product: string) {
    const response = await api.get<{ data: Array<ReviewList> }>(
      `/company/${company}/product-development/product/${product}/change-control`,
    );

    return ReviewList.array().parse(response.data.data);
  },
  async retrieveUsers(company: number) {
    const response = await api.get<{ data: Array<QualioUser> }>(
      `/company/${company}/product-development/users`,
    );

    return QualioUser.array().parse(response.data.data);
  },
  async createDesignElement(
    company: number,
    product: string,
    body: Partial<DesignElement>,
  ): Promise<Partial<BaseDesignElement>> {
    const response = await api.post<{ data: DesignElement }>(
      `/company/${company}/product-development/product/${product}/qualityItem`,
      { data: body },
    );

    return BaseDesignElement.partial().parse(response.data.data);
  },
  async modifyDesignElement(
    company: number,
    product: string,
    body: Partial<DesignElement>,
  ) {
    const response = await api.patch<{ data: DesignElement }>(
      `/company/${company}/product-development/product/${product}/qualityItem/${body.id}`,
      { data: body },
    );

    return BaseDesignElement.partial().parse(response.data.data);
  },
  async generatePutUrl(
    company: number,
    product: string,
    presignedUrlRequest: Attachment,
  ) {
    const response = await api.post<{ data: unknown }>(
      `attachments/company/${company}/product/${product}/generatePresignedUrl`,
      { data: presignedUrlRequest },
    );

    return PresignedUrlResponse.parse(response.data.data);
  },
  async addTestResult(
    company: number,
    product: string,
    testResult: TestResult,
    testCase: string,
  ) {
    return api.post(
      `/company/${company}/product-development/product/${product}/test-result`,
      {
        data: {
          ...testResult,
          testCase,
        },
      },
    );
  },
  async retrieveUnstaged(company: number, product: string) {
    const response = await api.get(
      `/company/${company}/product-development/product/${product}/change-control/quality-item`,
      {
        params: { status: "unstaged" },
      },
    );

    return LinkedElement.array().parse(response.data.data);
  },
  async createReview(
    company: number,
    product: string,
    review: Partial<ReviewRecord>,
  ) {
    const response = await api.post(
      `/company/${company}/product-development/product/${product}/change-control`,
      { data: { ...review, category: "Change Control" } },
    );

    return ReviewRecord.omit({ qualityItems: true }).parse(response.data.data);
  },
  async updateReview(
    company: number,
    product: string,
    reviewId: string,
    review: Partial<ReviewRecord>,
  ) {
    const response = await api.put(
      `/company/${company}/product-development/product/${product}/change-control/${reviewId}`,
      { data: { ...review, category: "Change Control" } },
    );

    return ReviewRecord.omit({ qualityItems: true }).parse(response.data.data);
  },
  async retrieveReview(company: number, product: string, reviewId: string) {
    const response = await api.get(
      `/company/${company}/product-development/product/${product}/change-control/${reviewId}`,
    );

    return ReviewRecord.parse(response.data.data);
  },
  deleteReview(company: number, product: string, reviewId: string) {
    return api
      .delete(
        `/company/${company}/product-development/product/${product}/change-control/${reviewId}`,
      )
      .then(() => undefined);
  },
  async retrieveRelease(company: number, product: string, releaseId: string) {
    const response = await api.get(
      `/company/${company}/product-development/product/${product}/release/${releaseId}`,
    );

    return ReleaseRecord.parse(response.data.data);
  },
  async retrieveGeneratedReports(
    company: number,
    product: string,
    releaseId: string,
  ) {
    const response = await api.get(
      `/company/${company}/product-development/product/${product}/release/${releaseId}/document`,
    );

    return ReleaseReport.parse(response.data.data);
  },
  sendForApproval(
    company: number,
    product: string,
    reviewId: string,
  ): Promise<void> {
    return api.post(
      `/company/${company}/product-development/product/${product}/change-control/${reviewId}/send-for-approval`,
    );
  },
  revertToDraft(
    company: number,
    product: string,
    reviewId: string,
  ): Promise<void> {
    return api.post(
      `/company/${company}/product-development/product/${product}/change-control/${reviewId}/revert-to-draft`,
    );
  },
  approveReview(
    company: number,
    product: string,
    reviewId: string,
    approval: Approval,
  ) {
    return api.post(
      `/company/${company}/product-development/product/${product}/change-control/${reviewId}/approve`,
      {
        data: approval,
      },
    );
  },
  async retrieveDesignElementHistory(
    companyId: number,
    productId: string,
    designElementId: string,
  ) {
    const response = await api.get(
      `/company/${companyId}/product-development/product/${productId}/qualityItem/${designElementId}/history`,
    );

    return ReviewHistory.array().parse(response.data.data);
  },
  deleteRequirementById(
    companyId: number,
    productId: string,
    designElementId: string,
  ): Promise<void> {
    return api
      .delete(
        `company/${companyId}/product-development/product/${productId}/requirements/${designElementId}`,
      )
      .then((res) => res.data);
  },
  deleteTestCaseById(
    companyId: number,
    productId: string,
    designElementId: string,
  ): Promise<void> {
    return api
      .delete(
        `company/${companyId}/product-development/product/${productId}/test-cases/${designElementId}`,
      )
      .then((res) => res.data);
  },
  deleteRiskById(
    companyId: number,
    productId: string,
    designElementId: string,
  ): Promise<void> {
    return api
      .delete(
        `company/${companyId}/product-development/product/${productId}/risks/${designElementId}`,
      )
      .then((res) => res.data);
  },
  downloadReviewPackageUrl(
    company: number,
    product: string,
    release: string,
  ): string {
    return `${process.env.REACT_APP_API_BASE_URL}/release/company/${company}/product/${product}/release/${release}/download`;
  },
  async traceabilityMatrix(companyId: number, product: string) {
    const response = await api.get(
      `/company/${companyId}/product-development/product/${product}/requirement`,
    );

    return TraceabilityMatrix.parse(response.data.data);
  },
  async releaseTraceabilityMatrix(
    companyId: number,
    product: string,
    releaseId: string,
  ) {
    const response = await api.get(
      `/company/${companyId}/product-development/product/${product}/release/${releaseId}/requirement`,
    );

    return TraceabilityMatrix.parse(response.data.data);
  },
} as const;
