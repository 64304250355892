import { FMEARiskDesignElement } from "@design-controls/types";

export const riskScore = (
  severity?: FMEARiskDesignElement["preMitigation"]["severity"],
  detectability?: FMEARiskDesignElement["preMitigation"]["detectability"],
  occurence?: FMEARiskDesignElement["preMitigation"]["occurrence"],
) => {
  if (!severity || !detectability || !detectability) {
    return;
  }

  return Number(severity) * Number(detectability) * Number(occurence);
};
