import {
  QBackLink,
  QBodyLayout,
  QBox,
  QButton,
  QButtonGroup,
  QDateMetadataItem,
  QHeader,
  QHorizontalMetadata,
  QNotFound,
  QOpenPropertiesPanelButton,
  QPageLoader,
  QPropertiesPanel,
  QStack,
  QText,
  QTitle,
  QTogglePropertiesPanelButton,
} from "@qualio/ui-components";
import React, { useCallback, useMemo } from "react";
import { useCurrentProduct } from "../../hooks/useCurrentProduct";
import { useDeleteElementDrawer } from "../../hooks/useDesignElementDrawer";
import { isDesignElementISORisk } from "@design-controls/types";
import { ItemStatusTag } from "../../components/ItemStatusTag/ItemStatusTag";
import { isSoureEditableAndDeletable } from "../../domain/editableSource";
import { useNavigate } from "react-router";
import { DesignReviewOverviewTable } from "../../components/DesignReviewOverviewTable/DesignReviewOverviewTable";
import { ReviewStatusTag } from "../../components/ReviewStatusTag/ReviewStatusTag";
import { TestCaseTable } from "../../components/OverviewTable/TestCaseTable";
import { RequirementTable } from "../../components/OverviewTable/RequirementTable";
import { ISORiskLevel } from "./components/ISORiskLevel";
import { GapAnalysis } from "../../components/GapAnalysis/GapAnalysis";
import { sourceDisplayName } from "../../constants/sourceDisplayName";

export const ISORiskOverview: React.FC = () => {
  const { name } = useCurrentProduct();
  const { isLoading, designElement, designElementHistory, version } =
    useDeleteElementDrawer();
  const navigate = useNavigate();

  const onEditClick = useCallback(() => {
    navigate("edit");
  }, [navigate]);

  const sourceRequirements = useMemo(() => {
    if (!designElement) {
      return [];
    }

    return [...((designElement as any).sourceItems ?? [])].filter((element) =>
      element.type.startsWith("req"),
    );
  }, [designElement]);

  const sourceTestCases = useMemo(() => {
    if (!designElement) {
      return [];
    }

    return [...((designElement as any).sourceItems ?? [])].filter((element) =>
      element.type.startsWith("testCase"),
    );
  }, [designElement]);

  const mitigatesRequirements = useMemo(() => {
    if (!designElement) {
      return [];
    }

    return [...((designElement as any).mitigationItems ?? [])].filter(
      (element) => element.type.startsWith("req"),
    );
  }, [designElement]);

  const mitigatesTestCases = useMemo(() => {
    if (!designElement) {
      return [];
    }

    return [...((designElement as any).mitigationItems ?? [])].filter(
      (element) => element.type.startsWith("testCase"),
    );
  }, [designElement]);

  if (isLoading) {
    return <QPageLoader />;
  }

  if (!designElement || !isDesignElementISORisk(designElement)) {
    return <QNotFound />;
  }

  return (
    <QBodyLayout.Default>
      <QHeader>
        <QBackLink href="../risk/iso" data-cy="back-btn">
          Back to {name}
        </QBackLink>
        <QTitle>
          <QText as="span" color="gray.500">
            {designElement?.code}
          </QText>{" "}
          {designElement?.title}
        </QTitle>
        <QHorizontalMetadata>
          <ItemStatusTag itemStatus={designElement.itemStatus} />
          <QDateMetadataItem
            label="Created"
            value={new Date(designElement?.created ?? 0)}
          />
          <QDateMetadataItem
            label="Last modified"
            value={new Date(designElement?.updated ?? 0)}
          />
        </QHorizontalMetadata>
        <QButtonGroup>
          {isSoureEditableAndDeletable(designElement.source) &&
            version === "v0" && (
              <QButton
                leftIcon="Edit3"
                variant="outline"
                data-cy="edit"
                onClick={onEditClick}
              >
                Edit
              </QButton>
            )}
          {!isSoureEditableAndDeletable(designElement.source) && (
            <a
              href={designElement.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <QButton
                data-cy="integration-button"
                variant="outline"
                rightIcon="ExternalLink"
              >
                {sourceDisplayName(designElement.source)}
              </QButton>
            </a>
          )}
          <QOpenPropertiesPanelButton />
        </QButtonGroup>
        <GapAnalysis
          policyIssues={designElement.policyIssues}
          type={designElement.type}
        />
      </QHeader>
      <QStack gap="16px" data-cy="iso-risk-overview">
        <QText fontSize="xl" fontWeight={600}>
          Initial assessment
        </QText>

        <QStack>
          <QText fontSize="sm" fontWeight={600}>
            Hazard
          </QText>
          <QText fontSize="sm" data-cy="hazard">
            {designElement.hazard}
          </QText>
        </QStack>

        <QStack direction="row" columnGap="32px">
          <QStack>
            <QText fontSize="sm" fontWeight={600}>
              Risk level
            </QText>
            <QText fontSize="sm">
              <ISORiskLevel
                level={designElement.preMitigation?.riskLevel}
                data-cy="pre-mitigation-level"
              />
            </QText>
          </QStack>
          <QStack>
            <QText fontSize="sm" fontWeight={600}>
              Probability
            </QText>
            <QText fontSize="sm" data-cy="pre-mitigation-probabily">
              {designElement.preMitigation?.probability}
            </QText>
          </QStack>
          <QStack>
            <QText fontSize="sm" fontWeight={600}>
              Severity
            </QText>
            <QText fontSize="sm" data-cy="pre-mitigation-severity">
              {designElement.preMitigation?.severity}
            </QText>
          </QStack>
        </QStack>

        <QStack>
          <QText fontSize="sm" fontWeight={600}>
            Reasonable foreseeable misuse/use
          </QText>
          <QText fontSize="sm" data-cy="foreseeableUseMisuse">
            {designElement.foreseeableUseMisuse}
          </QText>
        </QStack>

        <QStack>
          <QText fontSize="sm" fontWeight={600}>
            Hazardous situation
          </QText>
          <QText fontSize="sm" data-cy="hazardSituation">
            {designElement.hazardSituation}
          </QText>
        </QStack>

        <QStack>
          <QText fontSize="sm" fontWeight={600}>
            Harm
          </QText>
          <QText fontSize="sm" data-cy="harm">
            {designElement.harm}
          </QText>
        </QStack>

        {designElementHistory && designElementHistory?.length > 0 && (
          <DesignReviewOverviewTable changeHistory={designElementHistory} />
        )}

        <RequirementTable requirements={sourceRequirements} />
        <TestCaseTable testCases={sourceTestCases} />

        {designElement.riskControl && (
          <>
            <QText fontSize="xl" fontWeight={600}>
              Risk control
            </QText>

            <QStack>
              <QText fontSize="sm" fontWeight={600}>
                Description
              </QText>
              <QText fontSize="sm" data-cy="mitigation">
                {designElement.riskControl.mitigation}
              </QText>
            </QStack>

            <QStack direction="row" columnGap="32px">
              <QStack>
                <QText fontSize="sm" fontWeight={600}>
                  Risk level
                </QText>
                <QText fontSize="sm">
                  {designElement.postMitigation?.riskLevel && (
                    <ISORiskLevel
                      level={designElement.postMitigation?.riskLevel}
                      data-cy="post-mitigation-level"
                    />
                  )}
                </QText>
              </QStack>
              <QStack>
                <QText fontSize="sm" fontWeight={600}>
                  Probability
                </QText>
                <QText fontSize="sm" data-cy="post-mitigation-probabily">
                  {designElement.postMitigation?.probability}
                </QText>
              </QStack>
              <QStack>
                <QText fontSize="sm" fontWeight={600}>
                  Severity
                </QText>
                <QText fontSize="sm" data-cy="post-mitigation-severity">
                  {designElement.postMitigation?.severity}
                </QText>
              </QStack>
            </QStack>

            <RequirementTable requirements={mitigatesRequirements} />
            <TestCaseTable testCases={mitigatesTestCases} />
          </>
        )}

        <QPropertiesPanel openOnMount>
          <QStack spacing="8px" w="300px">
            <QStack direction="row" justifyContent="space-between">
              <QTitle>Properties</QTitle>
              <QTogglePropertiesPanelButton />
            </QStack>
            <QStack>
              <QText fontSize="sm" fontWeight={600}>
                Risk profile
              </QText>
              <QText fontSize="sm" data-cy="risk-profile">
                ISO 14971
              </QText>
            </QStack>
            {designElement.category && (
              <QStack>
                <QText fontSize="sm" fontWeight={600}>
                  Category
                </QText>
                <QText fontSize="sm" data-cy="category">
                  {designElement.category?.label}
                </QText>
              </QStack>
            )}
            <QStack>
              <QText fontSize="sm" fontWeight={600}>
                Review status
              </QText>
              <QBox>
                <ReviewStatusTag status={designElement.changeControlStatus} />
              </QBox>
            </QStack>
          </QStack>
        </QPropertiesPanel>
      </QStack>
    </QBodyLayout.Default>
  );
};
