import { useQuery } from "react-query";
import { designControlsApi } from "../../api/designControls";
import { useCurrentProduct } from "../useCurrentProduct";
import { useCurrentUser } from "@qualio/ui-components";

export const useArchivePrefixes = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();

  return useQuery({
    queryKey: ["ARCHIVE", "PREFIXES", companyId, productId],
    queryFn: () =>
      designControlsApi.retrieveArchivePrefixes(companyId, productId),
  });
};
