import { useMutation, useQueryClient } from "react-query";
import { useCurrentUser, useToastProvider } from "@qualio/ui-components";
import { useCurrentProduct } from "../useCurrentProduct";
import { useParams } from "react-router-dom";
import { ChangeControlElement } from "../../types/router";
import { designControlsApi } from "../../api/designControls";

export const useSendForApproval = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();
  const { changeControlId: reviewId } =
    useParams<ChangeControlElement>() as ChangeControlElement;
  const queryClient = useQueryClient();
  const { showToast } = useToastProvider();

  return useMutation({
    mutationFn: () =>
      designControlsApi.sendForApproval(companyId, productId, reviewId),
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        "REVIEW",
        companyId,
        productId,
        reviewId,
      ]);
      showToast({
        title: "Sent for approval",
        description: "Successfully sent for approval",
        status: "success",
      });
    },
    onError: () => {
      showToast({
        title: "Not sent for approval",
        description: "Cannot send for approval",
        status: "error",
      });
    },
  });
};
