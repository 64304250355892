import { isRiskPolicy } from "@design-controls/types";
import { useCurrentConfigs } from "../../../hooks/useCurrentConfigs";
import { QTag, QTagProps } from "@qualio/ui-components";
import React from "react";

type Props = {
  level?: string;
  "data-cy": string;
};

export const ISORiskLevel: React.FC<Props> = ({ level, "data-cy": dataCy }) => {
  const riskPolicy = useCurrentConfigs().find(isRiskPolicy);
  if (!riskPolicy || !level) {
    return null;
  }

  const table: Record<number, QTagProps["variantColor"][]> = {
    2: ["blue", "red"],
    3: ["blue", "yellow", "red"],
    4: ["blue", "green", "yellow", "red"],
    5: ["blue", "green", "yellow", "orange", "red"],
  };

  const total = riskPolicy.assessment.iso.levels.length;
  const position = riskPolicy.assessment.iso.levels.findIndex(
    (l) => l === level,
  );

  return (
    <QTag variantColor={table[total][position]} data-cy={dataCy}>
      {level}
    </QTag>
  );
};
