import {
  QBodyLayout,
  QFooter,
  QHeader,
  QTab,
  QTitle,
} from "@qualio/ui-components";
import React, { useCallback, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router";
import { ProductConfigure } from "./components/Product";
import { RequirementConfigure } from "./components/Requirements";
import { TestCaseConfigure } from "./components/Testcases";
import { RiskConfigure } from "./components/Risks";
import { FormButtons } from "./components/FormButtons";

const tabs = ["edit", "requirements", "test-cases", "risks"] as const;

export const ConfigureProduct: React.FC = () => {
  const { page } = useParams();
  const navigate = useNavigate();
  const nextRef = useRef<HTMLButtonElement>(null);

  const index = useMemo(() => {
    const index = tabs.findIndex((item) => item === page);
    return index >= 0 ? index : 0;
  }, [page]);

  const onChange = useCallback(
    (index: number) => navigate(`../config/${tabs[index]}`),
    [navigate],
  );

  const onClose = useCallback(() => navigate(".."), [navigate]);

  const onClick = useCallback(() => {
    nextRef.current?.click();
  }, [nextRef]);

  return (
    <QBodyLayout.SplitPanelLeftWithTabs
      size="md"
      index={index}
      isLazy
      onChange={onChange}
    >
      <QHeader>
        <QTitle>Configure product</QTitle>
      </QHeader>
      <QTab data-cy="product-profile-nav">Product profile</QTab>
      <QTab data-cy="requirements-nav">Requirements</QTab>
      <QTab data-cy="test-case-nav">Test cases</QTab>
      <QTab data-cy="risk-nav">Risks</QTab>
      <ProductConfigure nextRef={nextRef} />
      <RequirementConfigure nextRef={nextRef} />
      <TestCaseConfigure nextRef={nextRef} />
      <RiskConfigure nextRef={nextRef} />
      <QFooter>
        <FormButtons onClick={onClick} onClose={onClose} />
      </QFooter>
    </QBodyLayout.SplitPanelLeftWithTabs>
  );
};
