import React, { useMemo, useState, useCallback } from "react";

import {
  isRequirementPolicy,
  RequirementList as RequirementListType,
} from "@design-controls/types";
import {
  createQColumnHelper,
  DataProvider,
  Filtering,
  QDataTable,
  TableMenuItem,
  QDeleteConfirmationModal,
  QText,
  Pagination,
  Sorting,
} from "@qualio/ui-components";
import { useNavigate } from "react-router";

import { OpenIssue } from "../../../types/openIssue";
import { CategoryFilter } from "../../filter/CategoryFilter";
import { ShowOnlyOpen } from "../../filter/ShowOnlyOpen";
import { ComponentFilter, RequirementType } from "./filter";
import { usePolicyGroupResourceQuickSearch } from "../../../hooks/qri/usePolicyGroupResourceQuickSearch";
import { useResourceLibraryQuickSearch } from "../../hooks/search/useResourceLibraryQuickSearch";
import { useCurrentConfigs } from "../../hooks/useCurrentConfigs";
import { elementStatus, ElementStatusTag } from "../../domain/elementStatus";
import { useDeleteRequirement } from "../../hooks/designElement/useDeleteRequirement";
import { useRequirementList } from "../../hooks/designElement/useRequirementsList";
import { GapTableCell } from "../../components/GapTableCell/GapTableCell";

const columnHelper = createQColumnHelper<RequirementListType>();

export const RequirementList: React.FC = () => {
  const { isLoading, data } = useRequirementList();
  const { isLoading: isDeleting, mutate: deleteElement } =
    useDeleteRequirement();
  const [itemToDelete, setItemToDelete] = useState<RequirementListType | null>(
    null,
  );
  const navigate = useNavigate();

  const { isLoading: categoryIsLoading, data: categories } =
    usePolicyGroupResourceQuickSearch(isRequirementPolicy);

  const policies = useCurrentConfigs();
  const requirementPolicy = policies.filter(isRequirementPolicy);
  const { isLoading: componentIsLoading, data: components } =
    useResourceLibraryQuickSearch(
      requirementPolicy?.[0].component?.[0].resourceIdentifier as string,
    );

  const columns = useMemo(() => {
    return [
      columnHelper.code("code", { header: "ID", maxWidth: "100px" }),
      {
        header: " ",
        meta: {
          width: "100px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: RequirementListType };
        }) => <GapTableCell openIssues={row.policyIssues} type={row.type} />,
      },
      columnHelper.textLink("title", (record) => record.id, {
        header: "Requirement",
        maxWidth: "400px",
      }),
      columnHelper.text((record) => record.component?.label, {
        header: "Component",
        maxWidth: "200px",
      }),
      columnHelper.text((record) => record.category?.label, {
        header: "Category",
        maxWidth: "200px",
      }),
      columnHelper.date((record) => new Date(record.updated), {
        header: "Last modified",
        id: "updated",
        maxWidth: "150px",
      }),
      columnHelper.status(elementStatus, {
        header: "Status",
        statuses: ElementStatusTag,
      }),
      columnHelper.menu({
        items: (
          <>
            <TableMenuItem
              onClick={(item: RequirementListType) =>
                navigate(`${item.id}/edit`)
              }
              isDisabled={(item: RequirementListType) =>
                !["product-development", "qualio"].includes(item.source)
              }
              data-cy="edit"
            >
              Edit
            </TableMenuItem>
            <TableMenuItem
              onClick={(item: RequirementListType) => setItemToDelete(item)}
              color="red.500"
              data-cy="delete"
            >
              Delete
            </TableMenuItem>
          </>
        ),
      }),
    ];
  }, [navigate, policies]);

  const filterDefinitions = useMemo(() => {
    return {
      type: {
        label: "Requirement type",
        schema: Filtering.schemas.StringSchema().array().nullish(),
        activeRender: (v: any) => {
          const item = requirementPolicy?.find((c) => c.type === v);
          if (!item) {
            return;
          }

          return {
            label: "Requirement type:",
            value: item.label,
          };
        },
      },
      component: {
        label: "Component",
        schema: Filtering.schemas.StringSchema().array().nullish(),
        activeRender: (v: any) => {
          const item = components?.results.find((c) => c.identifiers.qri === v);
          if (!item) {
            return;
          }

          return {
            label: "Component:",
            value: item.displayLabel,
          };
        },
      },
      category: {
        label: "Category",
        schema: Filtering.schemas.StringSchema().array().nullish(),
        activeRender: (v: any) => {
          const item = categories?.find((c) => c.identifiers.qri === v);
          if (!item) {
            return;
          }

          return {
            label: "Category:",
            value: item.displayLabel,
          };
        },
      },
      show_required_child: {
        label: "Show missing child",
        schema: Filtering.schemas.BooleanSchema().nullish(),
        activeRender: (v: any) => ({
          label: null,
          value: v && "Missing child",
        }),
      },
      show_required_parent: {
        label: "Show missing parent",
        schema: Filtering.schemas.BooleanSchema().nullish(),
        activeRender: (v: any) => ({
          label: null,
          value: v && "Missing parent",
        }),
      },
      show_untested_requirement: {
        label: "Show missing parent",
        schema: Filtering.schemas.BooleanSchema().nullish(),
        activeRender: (v: any) => ({
          label: null,
          value: v && "Untested requirement",
        }),
      },
    };
  }, [categories, components, requirementPolicy]);

  const filterOption = useCallback(
    (
      item: Readonly<OpenIssue>,
      searchTerm: string | undefined,
      filters: Filtering.ResolvedFilters<typeof filterDefinitions> | undefined,
    ) => {
      if (
        searchTerm &&
        !item.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
      ) {
        return false;
      }

      if (filters && Object.values(filters).some((field) => field.value)) {
        const {
          show_required_child,
          show_required_parent,
          show_untested_requirement,
          type,
          category,
          component,
        } = filters;

        return (
          (!show_required_child?.value || item.policyIssues.REQUIRES_CHILD) &&
          (!show_required_parent?.value || item.policyIssues.REQUIRES_PARENT) &&
          (!show_untested_requirement?.value ||
            item.policyIssues.UNTESTED_REQUIREMENT) &&
          (!type?.value || type.value.includes(item.type)) &&
          (!category?.value || category.value.includes(item.category?.qri)) &&
          (!component?.value || component.value.includes(item.component?.qri))
        );
      }

      return true;
    },
    [],
  ) as DataProvider.FilterOptionFn<RequirementListType>;

  return (
    <>
      <Pagination.Auto pageSizeKey="size" pageIndexKey="page" clientSide>
        <Sorting.DefaultSortingProvider
          initialSort={{ column: "updated", descending: true }}
        >
          <Filtering.FilterProvider
            definitions={filterDefinitions}
            searchTermKey="search"
          >
            <DataProvider.Fixed
              data={data ?? []}
              isLoading={isLoading}
              filterOption={filterOption}
            >
              <QDataTable
                columns={columns}
                data-cy="requirement-list"
                getRowId={(row) => row.id}
              >
                <Filtering.FormContent>
                  <RequirementType />
                  <ComponentFilter
                    isLoading={componentIsLoading}
                    components={components?.results}
                  />
                  <CategoryFilter
                    categories={categories}
                    isLoading={categoryIsLoading}
                  />
                  <ShowOnlyOpen type="requirement" />
                </Filtering.FormContent>
              </QDataTable>
            </DataProvider.Fixed>
          </Filtering.FilterProvider>
        </Sorting.DefaultSortingProvider>
      </Pagination.Auto>
      {itemToDelete && (
        <QDeleteConfirmationModal
          title="Delete requirement"
          onConfirm={() => {
            itemToDelete &&
              deleteElement(itemToDelete as any, {
                onSettled: () => setItemToDelete(null),
              });
          }}
          inProgress={isDeleting}
          onClose={() => setItemToDelete(null)}
          message={
            <QText fontSize="sm">
              Are you sure you want to delete {itemToDelete.codeTitle}? You
              cannot undo this action.
            </QText>
          }
        />
      )}
    </>
  );
};
