import { useCurrentUser, useToastProvider } from "@qualio/ui-components";
import { useCurrentProduct } from "./useCurrentProduct";
import { useMutation, useQueryClient } from "react-query";
import { designControlsApi } from "../api/designControls";
import { TestResult } from "@design-controls/types";

export const useAddTestResult = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();
  const { showToast } = useToastProvider();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ testCase, ...result }: TestResult & { testCase: string }) =>
      designControlsApi.addTestResult(companyId, productId, result, testCase),
    onSuccess: async (_, { testCase }) => {
      showToast({
        title: "Success.",
        description: "Successfully added test result.",
        status: "success",
        duration: 5000,
      });
      await Promise.all([
        queryClient.invalidateQueries([
          "DESIGN_ELEMENT_BY_ID",
          companyId,
          productId,
          testCase,
          "v0",
        ]),
        queryClient.invalidateQueries([
          "OPEN_ISSUE_LIST",
          companyId,
          productId,
        ]),
      ]);
    },
    onError: () => {
      showToast({
        title: "Error.",
        description: "Unable to add test result.",
        status: "error",
        duration: 5000,
      });
    },
  });
};
