import {
  BaseDesignElement,
  ElementList,
  isDesignElement,
  isDesignElementFMEARisk,
  isDesignElementISORisk,
  isDesignElementRequirement,
  isDesignElementTestCase,
  OrphanItem,
  Policy,
  ResourceIdentifier,
  TraceabilityMatrix as TraceabilityMatrixType,
} from "@design-controls/types";
import {
  QBox,
  QButton,
  QDivider,
  QFlex,
  QIcon,
  QSpacer,
  QStack,
  QText,
} from "@qualio/ui-components";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { GapTableCell } from "../GapTableCell/GapTableCell";
import { useCurrentConfigs } from "../../hooks/useCurrentConfigs";
import { StatusTag } from "../StatusTag/StatusTag";
import { ItemStatusTag } from "../ItemStatusTag/ItemStatusTag";

type RowItem = OrphanItem & {
  path: string;
  category?: ResourceIdentifier;
  component?: ResourceIdentifier;
};

type Row = Record<string, RowItem[]>;

type Props = {
  row: Row;
  policies: Policy[];
  isRelease?: boolean;
};

function sortByCode<T extends { code?: string }>(elA: T, elB: T): number {
  if (!elA.code) {
    return 1;
  }

  if (!elB.code) {
    return -1;
  }

  return elA.code.localeCompare(elB.code, undefined, {
    numeric: true,
  });
}

const isMatch = (selectedIdPrefix: null | string, row: RowItem): boolean => {
  if (!selectedIdPrefix) {
    return false;
  }

  if (row.path.startsWith(selectedIdPrefix)) {
    return true;
  }

  const splitPath = selectedIdPrefix.split("#");
  for (let i = 1; i < splitPath.length; i++) {
    const newPath = splitPath.slice(0, i).join("#");
    if (row.path === newPath) {
      return true;
    }
  }

  return false;
};

const CELL_WIDTH = 400;

type ItemContainerProps = {
  selectedIdPrefix: string | null;
  toggleRelations: (item: RowItem) => () => void;
  record: BaseDesignElement & Pick<RowItem, "path" | "category" | "component">;
  isRelease?: boolean;
};

const ItemContainer: React.FC<ItemContainerProps> = ({
  selectedIdPrefix,
  toggleRelations,
  record,
  isRelease,
}) => {
  const [backgroundColor, borderColor] = useMemo(() => {
    const hasIssues = Object.values(record.policyIssues).some(Boolean);

    if (isMatch(selectedIdPrefix, record)) {
      return hasIssues ? ["blue.50", "yellow.500"] : ["blue.50", "blue.50"];
    }

    return hasIssues ? ["gray.50", "yellow.500"] : ["gray.50", "gray.50"];
  }, [selectedIdPrefix, record]);

  const routeTo = useMemo(() => {
    if (isDesignElementRequirement(record)) {
      return `../requirement/${record.id}`;
    } else if (isDesignElementTestCase(record)) {
      return `../test-case/${record.id}`;
    } else if (isDesignElementISORisk(record)) {
      return `../risk/iso/${record.id}`;
    } else if (isDesignElementFMEARisk(record)) {
      return `../risk/fmea/${record.id}`;
    }

    return "";
  }, [record]);

  const organisation = useMemo(() => {
    const items = [];

    if (record.category) {
      items.push(record.category.label);
    }

    if (record.component) {
      items.push(record.component.label);
    }

    return items;
  }, [record]);

  return (
    <QStack
      p={2}
      direction="row"
      backgroundColor={backgroundColor}
      borderStyle="solid"
      borderColor={borderColor}
      borderLeftWidth="4px"
      borderRadius="4px"
      alignItems="center"
      justifyContent="space-between"
    >
      <QStack width={`${CELL_WIDTH - 48}px`} whiteSpace="normal">
        <Link to={routeTo} style={{ color: "black" }} target="_blank">
          <QText fontWeight={600} fontSize="sm">
            {record.code} {record.title}
          </QText>
        </Link>
        <QBox>
          {isRelease ? (
            <ItemStatusTag itemStatus={record.itemStatus} />
          ) : (
            <StatusTag item={record} />
          )}
        </QBox>
        {!!organisation.length && (
          <QText
            fontSize="sm"
            whiteSpace="normal"
            dangerouslySetInnerHTML={{ __html: organisation.join("<br/>") }}
          />
        )}
        <GapTableCell
          openIssues={record.policyIssues}
          type={record.type as ElementList["type"]}
          onlyIssues
        />
      </QStack>
      <QButton
        aria-label="Find related"
        onClick={toggleRelations(record)}
        variant="ghost"
      >
        <QIcon
          iconName="GitCommit"
          color={isMatch(selectedIdPrefix, record) ? "blue.500" : "gray.200"}
        />
      </QButton>
    </QStack>
  );
};

const TraceabilityRow: React.FC<Props> = ({ row, policies, isRelease }) => {
  const [selectedIdPrefix, setSelectedIdPrefix] = useState<string | null>(null);

  const toggleRelations = useCallback(
    (item: RowItem) => {
      return () => {
        setSelectedIdPrefix((s) => (s === item.path ? null : item.path));
      };
    },
    [setSelectedIdPrefix],
  );

  return (
    <>
      <QFlex width="100%">
        {policies.map((policy) => (
          <QStack
            width={`${CELL_WIDTH}px`}
            paddingInlineStart="8px"
            paddingInlineEnd="8px"
            key={policy.type}
          >
            {row[policy.type].map((record) => {
              if (isDesignElement(record)) {
                return (
                  <ItemContainer
                    record={record}
                    toggleRelations={toggleRelations}
                    selectedIdPrefix={selectedIdPrefix}
                    key={record.path}
                    isRelease={isRelease}
                  />
                );
              } else {
                return <QSpacer key={record.id} />;
              }
            })}
          </QStack>
        ))}
      </QFlex>
      <QDivider />
    </>
  );
};

type TraceabilityMatrixProps = {
  matrix: TraceabilityMatrixType;
  isRelease?: boolean;
};

export const TraceabilityMatrix: React.FC<TraceabilityMatrixProps> = ({
  matrix,
  children,
  isRelease = false,
}) => {
  const configs = useCurrentConfigs();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [shadowLeft, setShadowLeft] = useState(false);
  const [shadowRight, setShadowRight] = useState(false);

  const orderedConfigs = useMemo(() => {
    const orderedTypes = [
      "req1",
      "req2",
      "req3",
      "req4",
      "testCase1",
      "testCase2",
      "testCase3",
      "testCase",
      "risk",
    ];

    return configs.sort(
      (a, b) =>
        orderedTypes.findIndex((aType) => aType === a.type) -
        orderedTypes.findIndex((bType) => bType === b.type),
    );
  }, [configs]);

  const rows = useMemo((): Row[] => {
    if (!matrix) {
      return [];
    }

    const rows: Row[] = [];
    matrix.forEach((record) => {
      // req1
      const req1: RowItem[] = [
        {
          ...record,
          path: record.id,
        },
      ];

      const req2: RowItem[] = [];
      const req3: RowItem[] = [];
      const req4: RowItem[] = [];
      const testCase1: RowItem[] = [];
      const testCase2: RowItem[] = [];
      const testCase3: RowItem[] = [];
      const risk: RowItem[] = [];

      record.children?.forEach((record2) => {
        // req2
        req2.push({
          ...record2,
          path: `${record.id}#${record2.id}`,
        });

        record2.children?.forEach((record3: TraceabilityMatrixType[0]) => {
          req3.push({
            ...record3,
            path: `${record.id}#${record2.id}#${record3.id}`,
          });

          record3.children?.forEach((record4: TraceabilityMatrixType[0]) => {
            // req4
            req4.push({
              ...record4,
              path: `${record.id}#${record2.id}#${record3.id}#${record4.id}`,
            });

            record4.risks?.forEach((riskItem) => {
              risk.push({
                ...record4,
                path: `${record.id}#${record2.id}#${record3.id}#${record4.id}#${riskItem.id}`,
              });
            });
          });

          record3.testedBy?.forEach((record4: TraceabilityMatrixType[0]) => {
            testCase3.push({
              ...record4,
              path: `${record.id}#${record2.id}#${record3.id}#${record4.id}`,
            });

            record4.risks?.forEach((riskItem) => {
              risk.push({
                ...riskItem,
                path: `${record.id}#${record2.id}#${record3.id}#${record4.id}#${riskItem.id}`,
              });
            });
          });

          record3.risks?.forEach((riskItem) => {
            risk.push({
              ...riskItem,
              path: `${record.id}#${record2.id}#${record3.id}#${riskItem.id}`,
            });
          });
        });

        record2.testedBy?.forEach((record3: TraceabilityMatrixType[0]) => {
          testCase2.push({
            ...record3,
            path: `${record.id}#${record2.id}#${record3.id}`,
          });

          record3.risks?.forEach((riskItem) => {
            risk.push({
              ...riskItem,
              path: `${record.id}#${record2.id}#${record3.id}#${riskItem.id}`,
            });
          });
        });

        record2.risk?.forEach((record3: TraceabilityMatrixType[0]) => {
          risk.push({
            ...record3,
            path: `${record.id}#${record2.id}#${record3.id}`,
          });
        });
      });

      record.testedBy?.forEach((record2) => {
        testCase1.push({
          ...record2,
          path: `${record.id}#${record2.id}`,
        });
      });

      record.risks?.forEach((record2) => {
        risk.push({
          ...record2,
          path: `${record.id}#${record2.id}`,
        });
      });

      rows.push({
        req1,
        req2: req2.sort(sortByCode),
        req3: req3.sort(sortByCode),
        req4: req4.sort(sortByCode),
        testCase1: testCase1.sort(sortByCode),
        testCase2: testCase2.sort(sortByCode),
        testCase3: testCase3.sort(sortByCode),
        risk: risk.sort(sortByCode),
      });
    });

    return rows.sort((a, b) => sortByCode(a["req1"][0], b["req1"][0]));
  }, [matrix, configs]);

  const updateShadows = useCallback(() => {
    if (!scrollRef.current) {
      return;
    }

    const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
    setShadowLeft(scrollLeft > 0);
    setShadowRight(scrollLeft < scrollWidth - clientWidth);
  }, [setShadowLeft, setShadowRight]);

  useEffect(() => {
    const scrollEl = scrollRef.current;
    if (!scrollEl) {
      return;
    }

    updateShadows();
    scrollEl.addEventListener("scroll", updateShadows);
    window.addEventListener("resize", updateShadows);

    return () => {
      scrollEl.removeEventListener("scroll", updateShadows);
      window.removeEventListener("resize", updateShadows);
    };
  }, []);

  return (
    <QStack data-cy="traceability">
      {children}
      <QBox overflow="hidden" position="relative">
        {shadowLeft && (
          <QBox
            position="absolute"
            top={0}
            left={0}
            bottom={0}
            w="30px"
            bgGradient="linear(to-r, rgba(0, 0, 0, 0.2), transparent)"
            pointerEvents="none"
            transition="opacity 0.3s"
          />
        )}
        {shadowRight && (
          <QBox
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            w="30px"
            bgGradient="linear(to-l, rgba(0, 0, 0, 0.2), transparent)"
            pointerEvents="none"
            transition="opacity 0.3s"
          />
        )}

        <QBox overflow="auto" ref={scrollRef}>
          <QStack
            gap="0"
            marginInline={0}
            width={`${CELL_WIDTH * orderedConfigs.length}px`}
            whiteSpace="nowrap"
            alignSelf="stretch"
            paddingBottom={3}
          >
            <QFlex
              width="100%"
              backgroundColor="gray.50"
              borderStyle="solid"
              borderTop="1px"
              borderBottom="1px"
              borderColor="gray.200"
            >
              {orderedConfigs.map((config) => (
                <QBox
                  width={`${CELL_WIDTH}px`}
                  paddingInlineStart="16px"
                  paddingInlineEnd="16px"
                  paddingTop="13.5px"
                  paddingBottom="13.5px"
                  key={config.type}
                >
                  <QStack direction="row" alignItems="center">
                    <QText fontWeight={600} fontSize="sm">
                      {config.label}
                    </QText>
                  </QStack>
                </QBox>
              ))}
            </QFlex>

            {rows.map((row, index) => (
              <TraceabilityRow
                key={`${row["req1"][0].id}-${index}`}
                policies={orderedConfigs}
                row={row}
                isRelease={isRelease}
              />
            ))}
          </QStack>
        </QBox>
      </QBox>
    </QStack>
  );
};
