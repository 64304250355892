import React from "react";

import { ProductWithoutId, RiskPolicy } from "@design-controls/types";
import { createStore, StoreApi } from "zustand";

import { INITIAL_REQUIREMENT_CONFIGURATION } from "../../lib/requirementConfiguration";
import {
  CreateProductStore,
  RequirementPolicies,
  TestCasePolicies,
} from "../types/createProductStore";
import { INITIAL_TESTCASE_CONFIGURATION } from "../constants/testCasePolicy";
import { INITIAL_RISK_CONFIGURATION_VALUES } from "../constants/riskPolicy";

export const CreateProductContext =
  React.createContext<StoreApi<CreateProductStore> | null>(null);

export const CreateProductProvider: React.FC = ({ children }) => {
  const [store] = React.useState(() =>
    createStore<CreateProductStore>((set, get) => ({
      product: {
        name: "",
        type: undefined,
        tags: [],
      },
      storeProduct: (product: ProductWithoutId) =>
        set({ product, activeStep: get().activeStep + 1 }),

      requirementPolicies: {
        req1: {
          type: "req1",
          codePrefix: INITIAL_REQUIREMENT_CONFIGURATION.items.req1.prefix,
          label: INITIAL_REQUIREMENT_CONFIGURATION.items.req1.label,
          codeStrategy: "",
        },
        req2: {
          type: "req2",
          codePrefix: INITIAL_REQUIREMENT_CONFIGURATION.items.req2
            ?.prefix as string,
          label: INITIAL_REQUIREMENT_CONFIGURATION.items.req2?.label as string,
          codeStrategy: "",
        },
        req3: {
          type: "req3",
          codePrefix: INITIAL_REQUIREMENT_CONFIGURATION.items.req3
            ?.prefix as string,
          label: INITIAL_REQUIREMENT_CONFIGURATION.items.req3?.label as string,
          codeStrategy: "",
        },
      },
      storeRequirementPolicies: (policies: RequirementPolicies) =>
        set({
          requirementPolicies: policies,
          activeStep: get().activeStep + 1,
        }),

      testCasePolicies: INITIAL_TESTCASE_CONFIGURATION,
      storeTestCasePolicies: (policies: TestCasePolicies) =>
        set({ testCasePolicies: policies, activeStep: get().activeStep + 1 }),

      riskPolicy: INITIAL_RISK_CONFIGURATION_VALUES,
      storeRiskPolicy: (policy: RiskPolicy) =>
        set({ riskPolicy: policy, isComplete: true }),

      // step handling
      activeStep: 0,
      isDisabled: false,
      isComplete: false,
      previousActiveStep: () =>
        set({
          activeStep: Math.max(0, get().activeStep - 1),
          isDisabled: false,
        }),
      setDisabled: (toggle: boolean) => set({ isDisabled: toggle }),
    })),
  );

  return (
    <CreateProductContext.Provider value={store}>
      {children}
    </CreateProductContext.Provider>
  );
};
