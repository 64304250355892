import { FMEARiskDesignElement } from "@design-controls/types";
import { DataProvider, QDataTable, QIcon, QText } from "@qualio/ui-components";
import React, { useMemo } from "react";

type Props = {
  base: FMEARiskDesignElement;
  compare: FMEARiskDesignElement;
};

type Column = {
  label: string;
  base: any;
  compare: any;
};

export const FMEARisk: React.FC<Props> = ({ base, compare }) => {
  const rows: Column[] = useMemo(() => {
    const rows: Column[] = [];

    if (base.title !== compare.title) {
      rows.push({
        label: "Title",
        base: base.title,
        compare: compare.title,
      });
    }

    if (base.category?.qri !== compare.category?.qri) {
      rows.push({
        label: "Category",
        base: base.category?.label,
        compare: compare.category?.label,
      });
    }
    if (base.evaluationMethod !== compare.evaluationMethod) {
      rows.push({
        label: "Reasonable foreseeable misuse/use",
        base: base.evaluationMethod,
        compare: compare.evaluationMethod,
      });
    }
    if (base.failureCauses !== compare.failureCauses) {
      rows.push({
        label: "Harm",
        base: base.harm,
        compare: compare.harm,
      });
    }
    if (base.failureMode !== compare.failureMode) {
      rows.push({
        label: "Hazard",
        base: base.hazard,
        compare: compare.hazard,
      });
    }
    if (base.failureModeEffects !== compare.failureModeEffects) {
      rows.push({
        label: "Hazardous situation",
        base: base.hazardSituation,
        compare: compare.hazardSituation,
      });
    }
    if (base.preMitigation.severity !== compare.preMitigation.severity) {
      rows.push({
        label: "Initial probability assessment",
        base: base.preMitigation.severity,
        compare: compare.preMitigation.severity,
      });
    }
    if (base.preMitigation?.occurrence !== compare.preMitigation?.occurrence) {
      rows.push({
        label: "Initial occurrence assessment",
        base: base.preMitigation?.occurrence,
        compare: compare.preMitigation?.occurrence,
      });
    }
    if (
      base.preMitigation?.detectability !== compare.preMitigation?.detectability
    ) {
      rows.push({
        label: "Initial detectability assessment",
        base: base.preMitigation?.detectability,
        compare: compare.preMitigation?.detectability,
      });
    }
    if (base.riskControl?.mitigation !== compare.riskControl?.mitigation) {
      rows.push({
        label: "Risk control description",
        base: base.riskControl?.mitigation,
        compare: compare.riskControl?.mitigation,
      });
    }
    if (base.postMitigation?.severity !== compare.postMitigation?.severity) {
      rows.push({
        label: "Final probability assessment",
        base: base.postMitigation?.severity,
        compare: compare.postMitigation?.severity,
      });
    }
    if (
      base.postMitigation?.occurrence !== compare.postMitigation?.occurrence
    ) {
      rows.push({
        label: "Final occurrence assessment",
        base: base.postMitigation?.occurrence,
        compare: compare.postMitigation?.occurrence,
      });
    }
    if (
      base.preMitigation?.detectability !== compare.preMitigation?.detectability
    ) {
      rows.push({
        label: "Final detectability assessment",
        base: base.preMitigation?.detectability,
        compare: compare.preMitigation?.detectability,
      });
    }

    return rows;
  }, []);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: "label",
        header: "Label",
        meta: {
          width: "100px",
        },
        cell: ({ row: { original: row } }: { row: { original: Column } }) => (
          <QText fontWeight={600}>{row.label}</QText>
        ),
      },
      {
        accessorKey: "base",
        header: "Base",
        meta: {
          width: "40%",
        },
        cell: ({ row: { original: row } }: { row: { original: Column } }) =>
          row.base ?? <QText color="gray.500">Empty</QText>,
      },
      {
        accessorKey: "id",
        header: "icon",
        meta: {
          width: "60px",
        },
        cell: () => <QIcon iconName="ArrowRight" />,
      },
      {
        accessorKey: "compare",
        header: "Compare",
        meta: {
          width: "40%",
        },
        cell: ({ row: { original: row } }: { row: { original: Column } }) =>
          row.compare ?? <QText color="gray.500">Empty</QText>,
      },
    ];
  }, []);

  if (!rows) {
    return null;
  }

  return (
    <DataProvider.Fixed data={rows}>
      <QDataTable columns={columns} withHeaders={false} />
    </DataProvider.Fixed>
  );
};
