import React from "react";

import { QNotFound } from "@qualio/ui-components";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";

import { CurrentProductProvider } from "./context/currentProduct";
import { ListLayout } from "./Layout/ListLayout";
import { RiskListLayout } from "./Layout/RiskListLayout";
import { CreateProduct } from "./views/CreateProduct/CreateProduct";
import { Documents } from "./views/Documents/Documents";
import { FMEARiskList } from "./views/FMEARiskList/FMEARiskList";
import { ISORiskList } from "./views/ISORiskList/ISORiskList";
import { Products } from "./views/Products/Products";
import { RequirementList } from "./views/RequirementList/RequirementList";
import { ReviewList } from "./views/ReviewList/ReviewList";
import { TestList } from "./views/TestList/TestList";
import { EditRequirement } from "./views/EditRequirement/EditRequirement";
import { RequirementOverview } from "./views/RequirementOverview/RequirementOverview";
import { PermissionCheck } from "./components/PermissionCheck/PermissionCheck";
import { UserPermissions } from "../components";
import { ConfigureProduct } from "./views/ConfigureProduct/ConfigureProduct";
import { TestCaseOverview } from "./views/TestCaseOverview/TestCaseOverview";
import { AddTestResult } from "./components/AddTestResult/AddTestResult";
import { EditTestCase } from "./views/EditTestCase/EditTestCase";
import { CreateReview } from "./views/CreateReview/CreateReview";
import { EditReview } from "./views/EditReview/EditReview";
import { ReviewOverview } from "./views/ReviewOverview/ReviewOverview";
import { ISORiskOverview } from "./views/RiskOverview/ISORiskOverview";
import { FMEARiskOverview } from "./views/RiskOverview/FMEARiskOverview";
import { EditISORisk } from "./views/EditISORIsk/EditISORisk";
import { EditFMEARisk } from "./views/EditFMEARisk/EditIFMEARisk";
import { Traceability } from "./views/Traceability/Traceability";
import { DiffView } from "./views/DiffView/DiffView";

export const App: React.FC = () => {
  return (
    <BrowserRouter basename="/design-controls">
      <Routes>
        <Route
          path="product/:product"
          element={
            <CurrentProductProvider>
              <Outlet />
            </CurrentProductProvider>
          }
        >
          <Route path="config/:page?" element={<ConfigureProduct />} />
          <Route
            path="requirement"
            element={
              <ListLayout>
                <RequirementList />
              </ListLayout>
            }
          />
          <Route
            path="traceability"
            element={
              <ListLayout>
                <Traceability />
              </ListLayout>
            }
          />
          <Route
            path="requirement/:selectedItemId"
            element={<RequirementOverview />}
          />
          <Route
            path="requirement/:selectedItemId/version/:version"
            element={<RequirementOverview />}
          />
          <Route
            path="requirement/:selectedItemId/edit"
            element={<EditRequirement />}
          />
          <Route
            path="risk"
            element={
              <ListLayout>
                <RiskListLayout />
              </ListLayout>
            }
          >
            <Route path="iso" element={<ISORiskList />} />
            <Route path="fmea" element={<FMEARiskList />} />
          </Route>
          <Route
            path="risk/iso/:selectedItemId"
            element={<ISORiskOverview />}
          />
          <Route
            path="risk/iso/:selectedItemId/version/:version"
            element={<ISORiskOverview />}
          />
          <Route
            path="risk/iso/:selectedItemId/edit"
            element={<EditISORisk />}
          />
          <Route
            path="risk/fmea/:selectedItemId"
            element={<FMEARiskOverview />}
          />
          <Route
            path="risk/fmea/:selectedItemId/version/:version"
            element={<FMEARiskOverview />}
          />
          <Route
            path="risk/fmea/:selectedItemId/edit"
            element={<EditFMEARisk />}
          />
          <Route
            path="test-case"
            element={
              <ListLayout>
                <TestList />
              </ListLayout>
            }
          >
            <Route
              path="add-result/:selectedItemId"
              element={<AddTestResult />}
            />
          </Route>
          <Route
            path="test-case/:selectedItemId"
            element={<TestCaseOverview />}
          >
            <Route path="add-result" element={<AddTestResult />} />
          </Route>
          <Route
            path="test-case/:selectedItemId/version/:version"
            element={<TestCaseOverview />}
          />
          <Route
            path="test-case/:selectedItemId/edit"
            element={<EditTestCase />}
          />
          <Route
            path="document"
            element={
              <ListLayout>
                <Documents />
              </ListLayout>
            }
          />
          <Route
            path="change-control"
            element={
              <ListLayout>
                <ReviewList />
              </ListLayout>
            }
          />
          <Route path="change-control/create" element={<CreateReview />}>
            <Route
              path=":selectedItemId/:compareVersion"
              element={<DiffView />}
            />
          </Route>
          <Route
            path="change-control/:changeControlId/view"
            element={<ReviewOverview />}
          >
            <Route path=":selectedItemId" element={<DiffView />} />
          </Route>
          <Route
            path="change-control/:changeControlId/edit"
            element={<EditReview />}
          >
            <Route
              path=":selectedItemId/:compareVersion"
              element={<DiffView />}
            />
          </Route>
          <Route index element={<Navigate to="requirement" />} />
          <Route path="*" element={<QNotFound />} />
        </Route>

        <Route path="product/:productId/edit" element={null} />
        <Route
          path="product/create"
          element={
            <PermissionCheck
              permissions={[UserPermissions.MANAGE_PRODUCT]}
              showNotFound
            >
              <CreateProduct />
            </PermissionCheck>
          }
        />
        <Route path="product" element={<Products />} />
        <Route index element={<Navigate to="/product" />} />
        <Route path="*" element={<QNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
