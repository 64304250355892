import React, { useCallback, useEffect, useMemo } from "react";
import { useRequirementPolicy } from "../../../hooks/createProductSteps/useRequirementPolicy";
import { TestCasePolicies } from "../../../types/createProductStore";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TestCasePolicy } from "../../../components/TestCasePolicy/TestCasePolicy";
import { useTestCasePolicy } from "../../../hooks/createProductSteps/useTestCasePolicy";
import { RequirementPolicies } from "@design-controls/types";

type Props = {
  nextRef: React.RefObject<any>;
};

const testCaseToRequirementMap: Array<
  ["testCase1" | "testCase2" | "testCase3", RequirementPolicies["type"]]
> = [
  ["testCase1", "req1"],
  ["testCase2", "req2"],
  ["testCase3", "req3"],
];

export const TestCases: React.FC<Props> = ({ nextRef }) => {
  const { policies: requirementPolicies } = useRequirementPolicy();
  const { policies, storeTestCasePolicies, setDisabled } = useTestCasePolicy();

  const methods = useForm<TestCasePolicies>({
    defaultValues: policies,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(TestCasePolicies),
  });

  const requirements = useMemo(() => {
    const items: string[] = [requirementPolicies.req1.type];
    if (requirementPolicies.req2) {
      items.push(requirementPolicies.req2.type);
    }
    if (requirementPolicies.req3) {
      items.push(requirementPolicies.req3.type);
    }
    return items;
  }, [requirementPolicies]);

  const submit = useCallback(
    (formdata: TestCasePolicies) => {
      let hasErrors = false;
      const prefixes = new Set(
        Object.values(requirementPolicies)
          .filter(Boolean)
          .map((policy) => policy.codePrefix),
      );

      Object.values(formdata)
        .filter((item) => item !== undefined)
        .forEach((policy) => {
          if (prefixes.has(policy.codePrefix)) {
            methods.setError(`${policy.type}.codePrefix`, {
              message: "Prefix in use",
            });
            hasErrors = true;
          }

          prefixes.add(policy.codePrefix);
        });

      if (!hasErrors) {
        const newPolicies: TestCasePolicies = {
          testCase1: formdata.testCase1,
          testCase2: formdata.testCase2,
          testCase3: formdata.testCase3,
        };

        for (const [testCase, requirement] of testCaseToRequirementMap) {
          if (!requirementPolicies[requirement] || !formdata[testCase]) {
            newPolicies[testCase] = undefined;
          }
        }

        storeTestCasePolicies(newPolicies);
      }
    },
    [requirementPolicies, storeTestCasePolicies, methods.setError],
  );

  const {
    formState: { isValid },
  } = methods;

  useEffect(() => {
    setDisabled(!isValid);
  }, [isValid, setDisabled]);

  return (
    <FormProvider {...methods}>
      <TestCasePolicy requirements={requirements} />
      <button
        style={{ display: "none" }}
        ref={nextRef}
        onClick={methods.handleSubmit(submit)}
      />
    </FormProvider>
  );
};
