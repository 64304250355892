import { ReviewRecord } from "@design-controls/types";
import {
  createQColumnHelper,
  DataProvider,
  QCard,
  QCollapse,
  QDataTable,
  QHeading,
  QIconButton,
  QStack,
} from "@qualio/ui-components";
import React from "react";
import {
  DocumentStatus,
  DocumentStatusIdToStatusConfigMap,
} from "../../types/document";
import { TagStatusMapping } from "@qualio/ui-components/lib/QOrganisms/Table/Cells/types";
import { useDisclosure } from "@chakra-ui/hooks";

type Props = {
  documents: ReviewRecord["linkedDocuments"];
};

const columnHelper = createQColumnHelper<ReviewRecord["linkedDocuments"][0]>();

export const LinkedDocumentsTable: React.FC<Props> = ({ documents }) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  if (!documents || !documents.length) {
    return null;
  }

  const columns = [
    columnHelper.text("code", { header: "Code", maxWidth: "100px" }),
    columnHelper.textLink(
      "title",
      ({ id }) => `${window.location.origin}/workspace/documents/${id}`,
      {
        header: "Title",
        maxWidth: "648px",
        weight: 1,
        isExternal: true,
      },
    ),
    columnHelper.status(
      (document) =>
        DocumentStatusIdToStatusConfigMap[document.status_id as DocumentStatus]
          .label,
      {
        id: "status_id",
        header: "Status",
        width: "90px",
        statuses: Object.values(DocumentStatusIdToStatusConfigMap).reduce(
          (acc: TagStatusMapping, statusConfig) => {
            acc[statusConfig.label] = statusConfig.color;
            return acc;
          },
          {},
        ),
      },
    ),
  ];

  return (
    <QCard>
      <QStack
        direction="row"
        justifyContent="space-between"
        padding="12px 16px"
      >
        <QHeading fontSize="md" fontWeight={600}>
          Linked documents
        </QHeading>
        <QIconButton
          onClick={onToggle}
          iconName={isOpen ? "ChevronUp" : "ChevronDown"}
          aria-label={
            isOpen ? "Collapse linked documents" : "Expand linked documents"
          }
        />
      </QStack>
      <QCollapse isOpen={isOpen}>
        <DataProvider.Fixed data={documents}>
          <QDataTable
            hideItemCount
            columns={columns}
            data-cy="linked-documents"
          />
        </DataProvider.Fixed>
      </QCollapse>
    </QCard>
  );
};
