import React, { useCallback, useEffect } from "react";
import { useRequirementPolicy } from "../../../hooks/createProductSteps/useRequirementPolicy";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { RequirementPolicies } from "../../../types/createProductStore";
import { RequirementPolicy } from "../../../components/RequirementPolicy/RequirementPolicy";

type Props = {
  nextRef: React.RefObject<any>;
};

export const Requirements: React.FC<Props> = ({ nextRef }) => {
  const { policies, storeRequirementPolicies, setDisabled } =
    useRequirementPolicy();
  const methods = useForm<RequirementPolicies>({
    defaultValues: policies,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(RequirementPolicies),
  });

  const {
    formState: { isValid },
  } = methods;

  useEffect(() => {
    setDisabled(!isValid);
  }, [isValid, setDisabled]);

  const submit = useCallback(
    (formdata: RequirementPolicies) => {
      let hasErrors = false;

      const prefixes = new Set<string>();
      Object.values(formdata)
        .filter((item) => item !== undefined)
        .forEach((policy) => {
          if (prefixes.has(policy.codePrefix)) {
            methods.setError(`${policy.type}.codePrefix`, {
              message: "Prefix in use",
            });
            hasErrors = true;
          }

          prefixes.add(policy.codePrefix);
        });

      if (!hasErrors) {
        storeRequirementPolicies(formdata);
      }
    },
    [storeRequirementPolicies],
  );

  return (
    <FormProvider {...methods}>
      <RequirementPolicy />
      <button
        style={{ display: "none" }}
        ref={nextRef}
        onClick={methods.handleSubmit(submit)}
      />
    </FormProvider>
  );
};
