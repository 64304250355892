import React, { useCallback, useMemo, useState } from "react";
import {
  FormToRiskPolicy,
  RiskFormValidation,
  RiskPolicyToForm,
} from "../../../components/RiskPolicy/validation";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { RiskPolicy } from "../../../components/RiskPolicy/RiskPolicy";
import { useCurrentConfigs } from "../../../hooks/useCurrentConfigs";
import { isRiskPolicy, Policy } from "@design-controls/types";
import { useUpdatePolicy } from "../../../hooks/policy/useUpdatePolicy";
import { useDisclosure } from "@chakra-ui/hooks";
import { DeletePolicy } from "./DeletePolicy";
import { useArchivePrefixes } from "../../../hooks/policy/useArchivePrefixes";
import { INITIAL_RISK_CONFIGURATION_VALUES } from "../../../constants/riskPolicy";
import { handleFormError } from "../../../api/handleFormError";

type Props = {
  nextRef: React.RefObject<any>;
};

export const RiskConfigure: React.FC<Props> = ({ nextRef }) => {
  const configs = useCurrentConfigs();
  const { mutate } = useUpdatePolicy();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [policyType, setPolicyType] = useState<Policy["type"] | null>(null);
  const { data: prefixes } = useArchivePrefixes();

  const defaultValues = useMemo(() => {
    const riskPolicy =
      configs.find(isRiskPolicy) ?? INITIAL_RISK_CONFIGURATION_VALUES;
    if (!riskPolicy) {
      return {};
    }

    return RiskPolicyToForm(riskPolicy);
  }, configs);

  const methods = useForm<RiskFormValidation>({
    defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(RiskFormValidation),
  });

  const { setError } = methods;

  const onSubmit = useCallback(
    (policy: RiskFormValidation) => {
      const usedPrefixes = [
        ...configs
          .filter((item) => item.codePrefix !== policy.codePrefix)
          .map((item) => item.codePrefix),
        ...(prefixes ?? []),
      ];

      if (usedPrefixes.includes(policy.codePrefix)) {
        setError("codePrefix", {
          message: "Prefix in use",
        });
      } else {
        mutate([FormToRiskPolicy(policy)], {
          onError: (error) => {
            handleFormError<RiskFormValidation>(error, setError);
          },
        });
      }
    },
    [mutate, setError, prefixes, configs],
  );

  const onDelete = useCallback(
    (type: Policy["type"]) => {
      setPolicyType(type);
      onOpen();
    },
    [setPolicyType, onOpen],
  );

  const onDeleteConfirmed = useCallback(() => {
    onClose();
    methods.setValue("fmeaEnabled", false);
  }, [methods.setValue, onClose]);

  const onDeleteClose = useCallback(() => {
    setPolicyType(null);
    onClose();
  }, [onClose, setPolicyType]);

  return (
    <FormProvider {...methods}>
      <RiskPolicy onDelete={onDelete} />
      <button
        style={{ display: "none" }}
        ref={nextRef}
        onClick={methods.handleSubmit(onSubmit)}
        type="submit"
      />
      {isOpen && policyType !== null && (
        <DeletePolicy
          type={policyType}
          onClose={onDeleteClose}
          onDelete={onDeleteConfirmed}
        />
      )}
    </FormProvider>
  );
};
