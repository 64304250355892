import { ISORiskDesignElement } from "@design-controls/types";
import { DataProvider, QDataTable, QIcon, QText } from "@qualio/ui-components";
import React, { useMemo } from "react";

type Props = {
  base: ISORiskDesignElement;
  compare: ISORiskDesignElement;
};

type Column = {
  label: string;
  base: any;
  compare: any;
};

export const ISORisk: React.FC<Props> = ({ base, compare }) => {
  const rows: Column[] = useMemo(() => {
    const rows: Column[] = [];

    if (base.title !== compare.title) {
      rows.push({
        label: "Title",
        base: base.title,
        compare: compare.title,
      });
    }

    if (base.category?.qri !== compare.category?.qri) {
      rows.push({
        label: "Category",
        base: base.category?.label,
        compare: compare.category?.label,
      });
    }
    if (base.foreseeableUseMisuse !== compare.foreseeableUseMisuse) {
      rows.push({
        label: "Reasonable foreseeable misuse/use",
        base: base.foreseeableUseMisuse,
        compare: compare.foreseeableUseMisuse,
      });
    }
    if (base.harm !== compare.harm) {
      rows.push({
        label: "Harm",
        base: base.harm,
        compare: compare.harm,
      });
    }
    if (base.hazard !== compare.hazard) {
      rows.push({
        label: "Hazard",
        base: base.hazard,
        compare: compare.hazard,
      });
    }
    if (base.hazardSituation !== compare.hazardSituation) {
      rows.push({
        label: "Hazardous situation",
        base: base.hazardSituation,
        compare: compare.hazardSituation,
      });
    }
    if (
      base.preMitigation?.probability !== compare.preMitigation?.probability
    ) {
      rows.push({
        label: "Initial probability assessment",
        base: base.preMitigation?.probability,
        compare: compare.preMitigation?.probability,
      });
    }
    if (base.preMitigation?.severity !== compare.preMitigation?.severity) {
      rows.push({
        label: "Initial severity assessment",
        base: base.preMitigation?.severity,
        compare: compare.preMitigation?.severity,
      });
    }
    if (base.riskControl?.mitigation !== compare.riskControl?.mitigation) {
      rows.push({
        label: "Risk control description",
        base: base.riskControl?.mitigation,
        compare: compare.riskControl?.mitigation,
      });
    }
    if (
      base.postMitigation?.probability !== compare.postMitigation?.probability
    ) {
      rows.push({
        label: "Final probability assessment",
        base: base.postMitigation?.probability,
        compare: compare.postMitigation?.probability,
      });
    }
    if (base.postMitigation?.severity !== compare.postMitigation?.severity) {
      rows.push({
        label: "Final severity assessment",
        base: base.postMitigation?.severity,
        compare: compare.postMitigation?.severity,
      });
    }

    return rows;
  }, []);

  const columns = useMemo(() => {
    return [
      {
        accessorKey: "label",
        header: "Label",
        meta: {
          width: "200px",
        },
        cell: ({ row: { original: row } }: { row: { original: Column } }) => (
          <QText fontWeight={600}>{row.label}</QText>
        ),
      },
      {
        accessorKey: "base",
        header: "Base",
        meta: {
          minWidth: "200px",
        },
        cell: ({ row: { original: row } }: { row: { original: Column } }) =>
          row.base ?? <QText color="gray.500">Empty</QText>,
      },
      {
        accessorKey: "id",
        header: "icon",
        meta: {
          width: "60px",
        },
        cell: () => <QIcon iconName="ArrowRight" />,
      },
      {
        accessorKey: "compare",
        header: "Compare",
        meta: {
          minWidth: "200px",
        },
        cell: ({ row: { original: row } }: { row: { original: Column } }) =>
          row.compare ?? <QText color="gray.500">Empty</QText>,
      },
    ];
  }, []);

  if (!rows) {
    return null;
  }

  return (
    <DataProvider.Fixed data={rows}>
      <QDataTable columns={columns} withHeaders={false} hideItemCount />
    </DataProvider.Fixed>
  );
};
