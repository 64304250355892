import {
  createQColumnHelper,
  DataProvider,
  QCard,
  QCollapse,
  QDataTable,
  QHeading,
  QIconButton,
  QStack,
} from "@qualio/ui-components";
import React, { useState } from "react";
import { ReviewStatusTable } from "../../constants/reviewStatus";
import { Link } from "react-router-dom";
import { ReviewHistory } from "@design-controls/types";

type Props = {
  changeHistory: ReviewHistory[];
};

const columnHelper = createQColumnHelper<ReviewHistory>();

export const DesignReviewOverviewTable: React.FC<Props> = ({
  changeHistory,
}) => {
  const [expanded, setExpanded] = useState(true);

  const columns = [
    columnHelper.code("code", { header: "Code", maxWidth: "100px" }),
    {
      accessorKey: "title",
      header: "Title",
      meta: {
        maxWidth: "648px",
      },
      cell: ({
        row: { original: row },
      }: {
        row: { original: ReviewHistory };
      }) => {
        return (
          <Link to={`../change-control/${row.id}/view`} target="_blank">
            {row.title}
          </Link>
        );
      },
    },
    columnHelper.date((record) => new Date(record.date), {
      header: "Date",
      maxWidth: "120px",
    }),
    columnHelper.status(
      (record) => (record.status ? ReviewStatusTable[record.status] : ""),
      {
        header: "Status",
        statuses: {
          "In progress": "gray",
          "For approval": "blue",
          Approved: "green",
          Declined: "red",
        },
        maxWidth: "120px",
      },
    ),
  ];

  return (
    <QCard>
      <QStack
        direction="row"
        justifyContent="space-between"
        padding="12px 16px"
      >
        <QHeading fontSize="md" fontWeight={600}>
          Review history
        </QHeading>
        <QIconButton
          onClick={() => setExpanded((s) => !s)}
          iconName={expanded ? "ChevronUp" : "ChevronDown"}
          aria-label={
            expanded ? "Collapse review history" : "Expand review history"
          }
        />
      </QStack>
      <QCollapse isOpen={expanded}>
        <DataProvider.Fixed data={changeHistory}>
          <QDataTable columns={columns} hideItemCount enableSorting={false} />
        </DataProvider.Fixed>
      </QCollapse>
    </QCard>
  );
};
