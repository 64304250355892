import React from "react";

import { QFormControl, QMultiSelect } from "@qualio/ui-components";
import { Controller } from "react-hook-form";
import { SearchResponse } from "../../../types/globalSearch";

type ComponentFilterProps = {
  isLoading: boolean;
  components?: SearchResponse["results"];
};
export const ComponentFilter: React.FC<ComponentFilterProps> = ({
  isLoading,
  components,
}) => {
  return (
    <Controller
      name="component"
      render={({ field: { onChange, value } }) => (
        <QFormControl label="Component">
          <QMultiSelect
            value={value}
            onChange={(selectedItem) =>
              onChange(selectedItem.map((item) => item.value))
            }
            options={(components ?? []).map((item) => ({
              value: item.identifiers.qri,
              label: item.displayLabel,
            }))}
            isLoading={isLoading}
          />
        </QFormControl>
      )}
    />
  );
};
