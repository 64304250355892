import React, { useCallback, useMemo } from "react";
import { Controller, FieldErrors, useFormContext } from "react-hook-form";
import { RequirementPolicies } from "../../types/createProductStore";
import { INITIAL_REQUIREMENT_CONFIGURATION } from "../../constants/requirementPolicy";
import {
  QButton,
  QCard,
  QCardFooter,
  QFormControl,
  QHeading,
  QInput,
  QStack,
  QTag,
  QText,
} from "@qualio/ui-components";
import { Policy, RequirementLevel1Policy } from "@design-controls/types";

type Props = {
  prefix: "req1" | "req2" | "req3" | "req4";
  label: string;
  disableLevel?: (level: "req2" | "req3" | "req4") => void;
  enableLevel?: (level: "req2" | "req3" | "req4") => void;
  isDisabled: boolean;
  canDisable: boolean;
  canEnable: boolean;
  errors?: FieldErrors<RequirementLevel1Policy>;
  onDelete?: (type: Policy["type"]) => void;
};

const RequirementLevelForm: React.FC<Props> = ({
  prefix,
  label,
  disableLevel,
  enableLevel,
  isDisabled,
  canDisable,
  canEnable,
  errors,
  onDelete,
}) => {
  return (
    <QCard>
      <QStack padding={4}>
        <QStack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <QHeading size="xs" fontWeight={600}>
            {label}
          </QHeading>
          {isDisabled && <QTag variantColor="gray">Disabled</QTag>}
        </QStack>
        {!isDisabled && (
          <QStack direction="row">
            <QFormControl
              label="Prefix"
              width="72px"
              isInvalid={!!errors?.codePrefix}
              error={errors?.codePrefix?.message}
            >
              <Controller
                name={`${prefix}.codePrefix`}
                render={({ field: { onChange, value } }) => (
                  <QInput
                    onChange={onChange}
                    value={value}
                    data-cy={`${prefix}-prefix`}
                  />
                )}
              />
            </QFormControl>
            <QFormControl
              label="Name"
              isInvalid={!!errors?.label}
              error={errors?.label?.message}
            >
              <Controller
                name={`${prefix}.label`}
                render={({ field: { onChange, value } }) => (
                  <QInput
                    onChange={onChange}
                    value={value}
                    data-cy={`${prefix}-label`}
                  />
                )}
              />
            </QFormControl>
          </QStack>
        )}
      </QStack>
      {canDisable && !isDisabled && prefix !== "req1" && (
        <QCardFooter>
          <QButton
            variant="ghost"
            leftIcon="EyeOff"
            isDisabled={isDisabled}
            data-cy={`disable-${prefix}`}
            onClick={
              disableLevel
                ? () => (onDelete ? onDelete(prefix) : disableLevel(prefix))
                : undefined
            }
          >
            {!!onDelete ? "Delete" : "Disable"}
          </QButton>
        </QCardFooter>
      )}
      {isDisabled && prefix !== "req1" && (
        <QCardFooter>
          <QButton
            isDisabled={!canEnable}
            variant="ghost"
            leftIcon="Eye"
            data-cy={`enable-${prefix}`}
            onClick={enableLevel ? () => enableLevel(prefix) : undefined}
          >
            Enable
          </QButton>
        </QCardFooter>
      )}
    </QCard>
  );
};

type PolicyProps = {
  onDelete?: (type: Policy["type"]) => void;
};

export const RequirementPolicy: React.FC<PolicyProps> = ({ onDelete }) => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<RequirementPolicies>();

  const levels = watch(["req2", "req3", "req4"]);

  const disableLevel = useCallback(
    (level: "req2" | "req3" | "req4") => {
      setValue(level, undefined, { shouldValidate: true });
    },
    [setValue],
  );

  const enableLevel = useCallback(
    (level: "req2" | "req3" | "req4") => {
      setValue(
        level,
        {
          type: level,
          codePrefix: INITIAL_REQUIREMENT_CONFIGURATION[level].codePrefix,
          label: INITIAL_REQUIREMENT_CONFIGURATION[level].label,
          codeStrategy: INITIAL_REQUIREMENT_CONFIGURATION[level].codeStrategy,
        },
        { shouldValidate: true },
      );
    },
    [setValue],
  );

  const disabledLevels = useMemo(() => {
    return {
      req1: false,
      req2: !levels[0],
      req3: !levels[1],
      req4: !levels[2],
    };
  }, [levels]);

  return (
    <QStack gap={4} width={640}>
      <QStack>
        <QHeading size="md" fontWeight={600}>
          Requirements
        </QHeading>
        <QText fontSize="sm">
          You can create and manage components and categories in the{" "}
          <a href="/registry" target="_blank">
            Resource Library
          </a>{" "}
          to further organize your requirements.
        </QText>
      </QStack>
      <RequirementLevelForm
        label="Level 1"
        isDisabled={false}
        canDisable={false}
        canEnable={false}
        prefix="req1"
        errors={errors.req1}
        onDelete={onDelete}
      />
      <RequirementLevelForm
        label="Level 2"
        isDisabled={disabledLevels["req2"]}
        canDisable={disabledLevels["req3"]}
        canEnable={true}
        prefix="req2"
        disableLevel={disableLevel}
        enableLevel={enableLevel}
        errors={errors.req2}
        onDelete={onDelete}
      />
      <RequirementLevelForm
        label="Level 3"
        isDisabled={disabledLevels["req3"]}
        canDisable={true}
        canEnable={!disabledLevels["req2"]}
        prefix="req3"
        disableLevel={disableLevel}
        enableLevel={enableLevel}
        errors={errors.req3}
        onDelete={onDelete}
      />
      <RequirementLevelForm
        label="Level 4"
        isDisabled={disabledLevels["req4"]}
        canDisable={true}
        canEnable={!disabledLevels["req3"]}
        prefix="req4"
        disableLevel={disableLevel}
        enableLevel={enableLevel}
        errors={errors.req4}
        onDelete={onDelete}
      />
    </QStack>
  );
};
