import { RiskPolicy } from "@design-controls/types";

export type RiskMatrixSize = "3x3" | "5x5";

export const INITIAL_RISK_PROBABILITY: Record<RiskMatrixSize, Array<string>> = {
  "3x3": ["Low", "Medium", "High"],
  "5x5": ["Improbable", "Remote", "Occasional", "Probable", "Frequent"],
};

export const RISK_MATRIX_DIMENSION: ReadonlyArray<RiskMatrixSize> = [
  "3x3",
  "5x5",
];

export const INITIAL_RISK_SEVERITY: Record<RiskMatrixSize, Array<string>> = {
  "3x3": ["Negligible", "Moderate", "Significant"],
  "5x5": ["Negligible", "Minor", "Serious", "Major", "Critical"],
};

export const INITIAL_RISK_LEVEL_MAPPING: Record<
  RiskMatrixSize,
  Array<Array<string>>
> = {
  "3x3": [
    ["Low", "Low", "Medium"],
    ["Low", "Medium", "Medium"],
    ["Medium", "Medium", "High"],
  ],
  "5x5": [
    ["Low", "Low", "Low", "Low", "Low"],
    ["Low", "Low", "Low", "Medium", "Medium"],
    ["Low", "Low", "Low", "Medium", "Medium"],
    ["Low", "Low", "Medium", "Medium", "High"],
    ["Low", "Medium", "Medium", "High", "High"],
  ],
};

export const INITIAL_RISK_CONFIGURATION_VALUES: RiskPolicy = {
  codePrefix: "RSK",
  label: "Risk",
  codeStrategy: "GENERATED",
  type: "risk",
  assessment: {
    iso: {
      levels: ["Low", "Medium", "High"],
      acceptableLevels: ["Low", "Medium"],
      probability: INITIAL_RISK_PROBABILITY["5x5"],
      severity: INITIAL_RISK_SEVERITY["5x5"],
      levelMapping: INITIAL_RISK_LEVEL_MAPPING["5x5"],
    },
  },
};
