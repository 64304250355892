import { ErrorResponse } from "@design-controls/types";
import axios from "axios";
import { FieldValues, UseFormSetError } from "react-hook-form";

export function handleFormError<TFieldValues extends FieldValues = FieldValues>(
  error: any,
  setError: UseFormSetError<TFieldValues>,
) {
  if (axios.isAxiosError(error) && error.status === 400) {
    const response = ErrorResponse.parse(error.response?.data);
    response.errors.forEach((error) => {
      let path = error.path;
      if (path[0] === "data") {
        path = error.path.slice(1);
      }
      setError(path.join(".") as any, { message: error.message });
    });
  }

  return null;
}
