import React from "react";
import useHasPermission from "../../hooks/useHasPermission";
import { UserPermissions } from "../../../components";
import { QNotFound } from "@qualio/ui-components";

type Props = {
  permissions: readonly UserPermissions[];
  showNotFound?: boolean;
};
export const PermissionCheck: React.FC<Props> = ({
  permissions,
  showNotFound,
  children,
}) => {
  const perms = useHasPermission(permissions);

  if (!Object.entries(perms).every(([, value]) => !!value)) {
    return showNotFound ? <QNotFound data-cy="not-found" /> : null;
  }

  return <>{children}</>;
};
