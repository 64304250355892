import React, { useCallback, useMemo } from "react";
import {
  FileSizeInBytes,
  QBodyLayout,
  QButton,
  QButtonGroup,
  QDateMetadataItem,
  QDivider,
  QFooter,
  QFormControl,
  QHeader,
  QHorizontalMetadata,
  QInput,
  QMultiFileInput,
  QSelect,
  QStack,
  QText,
  QTitle,
  useToastProvider,
} from "@qualio/ui-components";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { RelateDesignElements } from "../../../components/RelateDesignElements/RelateDesignElements";
import { Editor } from "../../../../components";
import { RequirementForm } from "../validation";
import { useUploadFile } from "../../../hooks/useUploadFile";
import { ResourceComponentField } from "../../../components/ResourceComponentField";
import { useCurrentConfigs } from "../../../hooks/useCurrentConfigs";
import { DesignElement, isRequirementPolicy } from "@design-controls/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useUpdateDesignElement } from "../../../hooks/designElement/useUpdateDesignElement";
import { useNavigate } from "react-router";
import { useDownloadAttachment } from "../../../hooks/designElement/useDownloadAttachment";
import { handleFormError } from "../../../api/handleFormError";

type FormProps = {
  requirement: RequirementForm;
  onClose: () => void;
};

export const Form: React.FC<FormProps> = ({ requirement, onClose }) => {
  const { onDownloadFile } = useDownloadAttachment();

  const methods = useForm<RequirementForm>({
    defaultValues: requirement,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(RequirementForm),
  });

  const configs = useCurrentConfigs();
  const { isLoading, mutate } = useUpdateDesignElement();
  const navigate = useNavigate();
  const { showToast } = useToastProvider();

  const {
    watch,
    setError,
    formState: { errors },
  } = methods;

  const type = watch("type");
  const uploadFile = useUploadFile();

  const { categoryResourceIdentifier, componentResourceIdentifier } =
    useMemo(() => {
      const config = configs
        .filter(isRequirementPolicy)
        .find((item) => item.type === type);

      return {
        categoryResourceIdentifier: config?.category?.[0].resourceIdentifier,
        componentResourceIdentifier: config?.component?.[0].resourceIdentifier,
      };
    }, [configs, type]);

  const requirementConfigOptions = useMemo(() => {
    return configs.filter(isRequirementPolicy).map((item) => ({
      label: item.label,
      value: item.type,
    }));
  }, [configs]);

  const requirementTypes = useMemo(() => {
    const validLinks: Record<string, DesignElement["type"][]> = {
      req1: ["req2"],
      req2: ["req1", "req3"],
      req3: ["req2", "req4"],
      req4: ["req3"],
    };
    const requirementConfigs = configs.filter(isRequirementPolicy);

    return validLinks[type].filter((linkType) =>
      requirementConfigs.some((config) => config.type === linkType),
    );
  }, [configs, type]);

  const testCaseType = useMemo((): DesignElement["type"] | undefined => {
    const relatedTestCase: Record<string, DesignElement["type"]> = {
      req1: "testCase1",
      req2: "testCase2",
      req3: "testCase3",
    };

    const testType = relatedTestCase[type];
    return configs.some((item) => item.type === testType)
      ? testType
      : undefined;
  }, [configs, type]);

  const onSubmit = useCallback(
    (formData: RequirementForm) => {
      const data = {
        id: formData.id,
        type: formData.type,
        title: formData.title,
        category: formData.category?.qri ? formData.category : undefined,
        component: formData.component?.qri ? formData.component : undefined,
        description: formData.description,
        attachments: formData.attachments,
        requirements: formData.requirements?.map((item) => item.id),
        testCases: formData.testCases?.map((item) => item.id),
      };

      mutate(data as any, {
        onSuccess: () => navigate(`../requirement/${data.id}`),
        onError: (error) => {
          showToast({
            title: "Error",
            description: "Failed to save changes. Please try again",
            status: "error",
          });
          handleFormError(error, setError);
        },
      });
    },
    [mutate, navigate, setError],
  );

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        data-cy="edit-requirement"
      >
        <QBodyLayout.Default size="sm">
          <QHeader>
            <QTitle>
              <QText as="span" color="gray.500">
                {requirement.code}
              </QText>{" "}
              {requirement.title}
            </QTitle>
            <QHorizontalMetadata>
              <QDateMetadataItem
                label="Created"
                value={new Date(requirement.created)}
                withTime={true}
              />
              <QDateMetadataItem
                label="Last modified"
                value={new Date(requirement.updated)}
                withTime={true}
              />
            </QHorizontalMetadata>
          </QHeader>
          <QStack gap="24px">
            <QText fontStyle="italic">
              Required fields are marked with an asterisk (
              <QText color="red.500" as="span">
                *
              </QText>
              )
            </QText>
            <QFormControl
              label="Title"
              isRequired
              isInvalid={!!errors?.title}
              error={errors?.title?.message}
            >
              <Controller
                name="title"
                render={({ field: { value, onChange } }) => (
                  <QInput value={value} onChange={onChange} data-cy="title" />
                )}
              />
            </QFormControl>
            <QFormControl
              label="Type"
              isRequired
              isInvalid={!!errors?.type}
              error={errors?.type?.message}
            >
              <Controller
                name="type"
                render={({ field: { value, onChange } }) => (
                  <QSelect
                    isDisabled
                    value={value}
                    onChange={onChange}
                    options={requirementConfigOptions}
                  />
                )}
              />
            </QFormControl>
            {componentResourceIdentifier && (
              <ResourceComponentField
                name="component"
                label="Component"
                resourceSubType={componentResourceIdentifier}
              />
            )}
            {categoryResourceIdentifier && (
              <ResourceComponentField
                name="category"
                label="Category"
                resourceSubType={categoryResourceIdentifier}
              />
            )}
            <QFormControl
              label="Description"
              isInvalid={!!errors?.description}
              error={errors?.description?.message}
            >
              <Controller
                name="description"
                render={({ field: { value, onChange } }) => (
                  <Editor onChange={onChange} value={value} />
                )}
              />
            </QFormControl>
            <QDivider />
            <QStack gap="16px">
              <QStack gap="8px">
                <QText fontSize="lg" fontWeight={600}>
                  Related design elements
                </QText>
                <QText fontSize="sm">
                  You may link any related requirements and validation test
                  cases.
                </QText>
              </QStack>
              {requirementTypes.length > 0 && (
                <QFormControl isInvalid={!!errors?.requirements}>
                  <Controller
                    name="requirements"
                    render={({ field: { value, onChange } }) => (
                      <RelateDesignElements
                        label="Requirements"
                        resource="dc-requirement"
                        resourceSubType={requirementTypes}
                        onChange={onChange}
                        value={value}
                        data-cy="add-requirement"
                      />
                    )}
                  />
                </QFormControl>
              )}

              {testCaseType && (
                <QFormControl isInvalid={!!errors?.testCases}>
                  <Controller
                    name="testCases"
                    render={({ field: { value, onChange } }) => (
                      <RelateDesignElements
                        label="Test cases"
                        resource="dc-testCase"
                        resourceSubType={[testCaseType]}
                        onChange={onChange}
                        value={value}
                        data-cy="add-test-case"
                      />
                    )}
                  />
                </QFormControl>
              )}
            </QStack>
            <QDivider />
            <QText fontSize="lg" fontWeight={600}>
              Attachments
            </QText>
            <QFormControl
              data-cy="attachments"
              isInvalid={!!errors?.attachments}
            >
              <Controller
                name="attachments"
                render={({ field: { value, onChange } }) => (
                  <QMultiFileInput
                    maxFiles={10}
                    value={value}
                    maxFileSize={FileSizeInBytes.mb(100)}
                    maxCombinedSize={FileSizeInBytes.mb(500)}
                    description="Up to 100 MB per file"
                    onChange={onChange}
                    onUploadFile={uploadFile}
                    onDownloadFile={onDownloadFile}
                  />
                )}
              />
            </QFormControl>
          </QStack>
          <QFooter>
            <QButtonGroup>
              <QButton
                variant="outline"
                onClick={onClose}
                isDisabled={isLoading}
                data-cy="cancel"
              >
                Cancel
              </QButton>
              <QButton
                type="submit"
                isLoading={isLoading}
                data-cy="save-changes"
              >
                Save changes
              </QButton>
            </QButtonGroup>
          </QFooter>
        </QBodyLayout.Default>
      </form>
    </FormProvider>
  );
};
