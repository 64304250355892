import { isDesignElementFMEARisk } from "@design-controls/types";
import React, { useCallback, useMemo } from "react";
import { useCurrentProduct } from "../../hooks/useCurrentProduct";
import { useDeleteElementDrawer } from "../../hooks/useDesignElementDrawer";
import { useNavigate } from "react-router";
import {
  QBackLink,
  QBodyLayout,
  QBox,
  QButton,
  QButtonGroup,
  QDateMetadataItem,
  QHeader,
  QHorizontalMetadata,
  QNotFound,
  QOpenPropertiesPanelButton,
  QPageLoader,
  QPropertiesPanel,
  QStack,
  QText,
  QTitle,
  QTogglePropertiesPanelButton,
} from "@qualio/ui-components";
import { ItemStatusTag } from "../../components/ItemStatusTag/ItemStatusTag";
import { isSoureEditableAndDeletable } from "../../domain/editableSource";
import { DesignReviewOverviewTable } from "../../components/DesignReviewOverviewTable/DesignReviewOverviewTable";
import { ReviewStatusTag } from "../../components/ReviewStatusTag/ReviewStatusTag";
import { RequirementTable } from "../../components/OverviewTable/RequirementTable";
import { TestCaseTable } from "../../components/OverviewTable/TestCaseTable";
import { FMEARiskLevel } from "./components/FMEARiskLevel";
import { GapAnalysis } from "../../components/GapAnalysis/GapAnalysis";
import { sourceDisplayName } from "../../constants/sourceDisplayName";

export const FMEARiskOverview: React.FC = () => {
  const { name } = useCurrentProduct();
  const { isLoading, designElement, designElementHistory, version } =
    useDeleteElementDrawer();
  const navigate = useNavigate();

  const onEditClick = useCallback(() => {
    navigate("edit");
  }, [navigate]);

  const sourceRequirements = useMemo(() => {
    if (!designElement) {
      return [];
    }

    return [...((designElement as any).sourcedItems ?? [])].filter((element) =>
      element.type.startsWith("req"),
    );
  }, [designElement]);

  const sourceTestCases = useMemo(() => {
    if (!designElement) {
      return [];
    }

    return [...((designElement as any).sourcedItems ?? [])].filter((element) =>
      element.type.startsWith("testCase"),
    );
  }, [designElement]);

  const mitigatesRequirements = useMemo(() => {
    if (!designElement) {
      return [];
    }

    return [...((designElement as any).mitigates ?? [])].filter((element) =>
      element.type.startsWith("req"),
    );
  }, [designElement]);

  const mitigatesTestCases = useMemo(() => {
    if (!designElement) {
      return [];
    }

    return [...((designElement as any).mitigates ?? [])].filter((element) =>
      element.type.startsWith("testCase"),
    );
  }, [designElement]);

  if (isLoading) {
    return <QPageLoader />;
  }

  if (!designElement || !isDesignElementFMEARisk(designElement)) {
    return <QNotFound />;
  }
  return (
    <QBodyLayout.Default>
      <QHeader>
        <QBackLink href="../risk/fmea" data-cy="back-btn">
          Back to {name}
        </QBackLink>
        <QTitle>
          <QText as="span" color="gray.500">
            {designElement?.code}
          </QText>{" "}
          {designElement?.title}
        </QTitle>
        <QHorizontalMetadata>
          <ItemStatusTag itemStatus={designElement.itemStatus} />
          <QDateMetadataItem
            label="Created"
            value={new Date(designElement?.created ?? 0)}
          />
          <QDateMetadataItem
            label="Last modified"
            value={new Date(designElement?.updated ?? 0)}
          />
        </QHorizontalMetadata>
        <QButtonGroup>
          {isSoureEditableAndDeletable(designElement.source) &&
            version === "v0" && (
              <QButton
                leftIcon="Edit3"
                variant="outline"
                data-cy="edit"
                onClick={onEditClick}
              >
                Edit
              </QButton>
            )}
          {!isSoureEditableAndDeletable(designElement.source) && (
            <a
              href={designElement.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <QButton
                data-cy="integration-button"
                variant="outline"
                rightIcon="ExternalLink"
              >
                {sourceDisplayName(designElement.source)}
              </QButton>
            </a>
          )}
          <QOpenPropertiesPanelButton />
        </QButtonGroup>
        <GapAnalysis
          policyIssues={designElement.policyIssues}
          type={designElement.type}
        />
      </QHeader>
      <QStack gap="16px" data-cy="fmea-risk-overview">
        <QText fontSize="xl" fontWeight={600}>
          Initial assessment
        </QText>

        <QStack>
          <QText fontSize="sm" fontWeight={600}>
            Failure mode
          </QText>
          <QText fontSize="sm" data-cy="failureMode">
            {designElement.failureMode}
          </QText>
        </QStack>

        <QStack direction="row" columnGap="32px">
          <QStack>
            <QText fontSize="sm" fontWeight={600}>
              Risk score
            </QText>
            <QText fontSize="sm">
              <FMEARiskLevel
                mitigation={designElement.preMitigation}
                data-cy="pre-mitigation-level"
              />
            </QText>
          </QStack>
          <QStack>
            <QText fontSize="sm" fontWeight={600}>
              Severity
            </QText>
            <QText fontSize="sm" data-cy="pre-mitigation-severity">
              {designElement.preMitigation?.severity}
            </QText>
          </QStack>
          <QStack>
            <QText fontSize="sm" fontWeight={600}>
              Occurence
            </QText>
            <QText fontSize="sm" data-cy="pre-mitigation-occurrence">
              {designElement.preMitigation?.occurrence}
            </QText>
          </QStack>
          <QStack>
            <QText fontSize="sm" fontWeight={600}>
              Detectability
            </QText>
            <QText fontSize="sm" data-cy="pre-mitigation-detectability">
              {designElement.preMitigation?.detectability}
            </QText>
          </QStack>
        </QStack>

        <QStack>
          <QText fontSize="sm" fontWeight={600}>
            Effects of Failure Mode
          </QText>
          <QText fontSize="sm" data-cy="failureModeEffects">
            {designElement.failureModeEffects}
          </QText>
        </QStack>

        <QStack>
          <QText fontSize="sm" fontWeight={600}>
            Causes of failure
          </QText>
          <QText fontSize="sm" data-cy="failureCauses">
            {designElement.failureCauses}
          </QText>
        </QStack>

        <QStack>
          <QText fontSize="sm" fontWeight={600}>
            Current Controls / Evaluation Method
          </QText>
          <QText fontSize="sm" data-cy="evaluationMethod">
            {designElement.evaluationMethod}
          </QText>
        </QStack>

        {designElementHistory && designElementHistory?.length > 0 && (
          <DesignReviewOverviewTable changeHistory={designElementHistory} />
        )}
        <RequirementTable requirements={sourceRequirements} />
        <TestCaseTable testCases={sourceTestCases} />

        {designElement.riskControl && (
          <>
            <QText fontSize="xl" fontWeight={600}>
              Risk control
            </QText>

            <QStack>
              <QText fontSize="sm" fontWeight={600}>
                Description
              </QText>
              <QText fontSize="sm" data-cy="mitigation">
                {designElement.riskControl.mitigation}
              </QText>
            </QStack>

            {designElement.postMitigation && (
              <QStack direction="row" columnGap="32px">
                <QStack>
                  <QText fontSize="sm" fontWeight={600}>
                    Risk score
                  </QText>
                  <QText fontSize="sm">
                    <FMEARiskLevel
                      mitigation={designElement.postMitigation}
                      data-cy="post-mitigation-level"
                    />
                  </QText>
                </QStack>
                <QStack>
                  <QText fontSize="sm" fontWeight={600}>
                    Severity
                  </QText>
                  <QText fontSize="sm" data-cy="post-mitigation-severity">
                    {designElement.postMitigation?.severity}
                  </QText>
                </QStack>
                <QStack>
                  <QText fontSize="sm" fontWeight={600}>
                    Occurence
                  </QText>
                  <QText fontSize="sm" data-cy="post-mitigation-occurrence">
                    {designElement.postMitigation?.occurrence}
                  </QText>
                </QStack>
                <QStack>
                  <QText fontSize="sm" fontWeight={600}>
                    Detectability
                  </QText>
                  <QText fontSize="sm" data-cy="pre-mitigation-detectability">
                    {designElement.postMitigation?.detectability}
                  </QText>
                </QStack>
              </QStack>
            )}
            <RequirementTable requirements={mitigatesRequirements} />
            <TestCaseTable testCases={mitigatesTestCases} />
          </>
        )}

        <QPropertiesPanel openOnMount>
          <QStack spacing="8px" w="300px">
            <QStack direction="row" justifyContent="space-between">
              <QTitle>Properties</QTitle>
              <QTogglePropertiesPanelButton />
            </QStack>
            <QStack>
              <QText fontSize="sm" fontWeight={600}>
                Risk profile
              </QText>
              <QText fontSize="sm" data-cy="risk-profile">
                FMEA
              </QText>
            </QStack>
            {designElement.category && (
              <QStack>
                <QText fontSize="sm" fontWeight={600}>
                  Category
                </QText>
                <QText fontSize="sm" data-cy="category">
                  {designElement.category?.label}
                </QText>
              </QStack>
            )}
            <QStack>
              <QText fontSize="sm" fontWeight={600}>
                Review status
              </QText>
              <QBox>
                <ReviewStatusTag status={designElement.changeControlStatus} />
              </QBox>
            </QStack>
          </QStack>
        </QPropertiesPanel>
      </QStack>
    </QBodyLayout.Default>
  );
};
