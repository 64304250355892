import axios from "axios";
import {
  RetrieveDocumentsPayload,
  RetrieveDocumentsResponse,
  Tag,
} from "../types/document";

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: process.env.REACT_APP_MEDTECH_API_URL,
  withCredentials: true,
});

export const MedtechAPI = {
  async getDocumentTags(companyId: number): Promise<Array<Tag>> {
    const res = await api.get("tags", { params: { company_id: companyId } });
    return Tag.array().parse(res.data);
  },
  async retrieveDocuments(
    companyId: number,
    {
      searchTerm,
      tagIds,
      offset,
      statuses,
      limit,
      sort,
    }: RetrieveDocumentsPayload,
  ): Promise<RetrieveDocumentsResponse> {
    const searchParams = new URLSearchParams();

    offset && searchParams.append("offset", String(offset));
    limit && searchParams.append("limit", String(limit));
    sort?.column &&
      searchParams.append(
        "order_by",
        `${sort.descending ? "-" : ""}${columnIdToSortKey[sort.column] ?? sort.column}`,
      );
    statuses?.forEach((status: string) =>
      searchParams.append("status_id", status),
    );
    searchTerm && searchParams.append("query", searchTerm);
    tagIds?.forEach((tagId: number) =>
      searchParams.append("tag_id", String(tagId)),
    );

    const queryString = searchParams.toString()
      ? `?${searchParams.toString()}`
      : "";

    const response = await api.get(`/v2/${companyId}/documents${queryString}`);
    return RetrieveDocumentsResponse.parse(response.data);
  },
} as const;

const columnIdToSortKey: Record<string, string> = {
  owner: "author_name",
};
