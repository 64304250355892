import { useCurrentUser, useToastProvider } from "@qualio/ui-components";
import { useMutation, useQueryClient } from "react-query";
import { designControlsApi } from "../../api/designControls";
import { Product } from "@design-controls/types";
import { useNavigate } from "react-router";

export const useUpdateProduct = () => {
  const { companyId } = useCurrentUser();
  const navigate = useNavigate();
  const { showToast } = useToastProvider();
  const client = useQueryClient();

  return useMutation({
    mutationFn: (product: Product) =>
      designControlsApi.updateProduct(companyId, product),
    onSuccess: async (_: unknown, product: Product) => {
      showToast({
        title: "Success",
        description: "Changes saved to product configuration",
        status: "success",
      });
      await client.invalidateQueries(["PRODUCT", companyId, product.id]);
      navigate("..");
    },
    onError: () =>
      showToast({
        title: "Error",
        description: "Failed to save changes. Please try again.",
        status: "error",
      }),
  });
};
