import { useCurrentUser, useToastProvider } from "@qualio/ui-components";
import { useCurrentProduct } from "../useCurrentProduct";
import { useMutation, useQueryClient } from "react-query";
import { designControlsApi } from "../../api/designControls";
import { ChangeControlElement } from "../../types/router";
import { Approval, ReviewStatus } from "@design-controls/types";
import { useParams } from "react-router-dom";

export const useApproveReview = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();
  const { showToast } = useToastProvider();
  const queryClient = useQueryClient();
  const { changeControlId: reviewId } =
    useParams<ChangeControlElement>() as ChangeControlElement;

  return useMutation({
    mutationFn: (approval: Approval) =>
      designControlsApi.approveReview(companyId, productId, reviewId, approval),
    onSuccess: (_, approval: Approval) => {
      showToast({
        status: "success",
        title:
          approval.status === ReviewStatus.enum.APPROVED
            ? "Approval success"
            : "Decline success",
        description:
          approval.status === ReviewStatus.enum.APPROVED
            ? "You have successfully approved this review."
            : "You have successfully declined this review.",
      });

      return Promise.all([
        queryClient.invalidateQueries(["REVIEW_LIST", companyId, productId]),
        queryClient.invalidateQueries([
          "REVIEW",
          companyId,
          productId,
          reviewId,
        ]),
      ]);
    },
  });
};
