import {
  QBox,
  QButton,
  QCard,
  QCardFooter,
  QDivider,
  QFormControl,
  QHeading,
  QIconButton,
  QInput,
  QSelect,
  QSelectItem,
  QSpacer,
  QStack,
  QTag,
  QText,
  QTheme,
} from "@qualio/ui-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { RiskFormValidation } from "./validation";
import styled from "@emotion/styled";
import { Policy } from "@design-controls/types";

const riskColours: Record<number, [string, string][]> = {
  2: [
    [QTheme.colors.blue["50"], QTheme.colors.blue["400"]],
    [QTheme.colors.red["50"], QTheme.colors.red["400"]],
  ],
  3: [
    [QTheme.colors.blue["50"], QTheme.colors.blue["400"]],
    [QTheme.colors.yellow["50"], QTheme.colors.yellow["400"]],
    [QTheme.colors.red["50"], QTheme.colors.red["400"]],
  ],
  4: [
    [QTheme.colors.blue["50"], QTheme.colors.blue["400"]],
    [QTheme.colors.green["50"], QTheme.colors.green["400"]],
    [QTheme.colors.yellow["50"], QTheme.colors.yellow["400"]],
    [QTheme.colors.red["50"], QTheme.colors.red["400"]],
  ],
  5: [
    [QTheme.colors.blue["50"], QTheme.colors.blue["400"]],
    [QTheme.colors.green["50"], QTheme.colors.green["400"]],
    [QTheme.colors.yellow["50"], QTheme.colors.yellow["400"]],
    [QTheme.colors.orange["50"], QTheme.colors.orange["400"]],
    [QTheme.colors.red["50"], QTheme.colors.red["400"]],
  ],
};

const MappingContainer = styled.div<{ options: number; index: number }>`
  background: ${(props) => riskColours?.[props.options]?.[props.index]?.[0]};

  > div > div {
    border: 1px solid
      ${(props) => riskColours?.[props.options]?.[props.index]?.[1]};
  }

  .chakra-divider + div {
    background: none;
  }
`;

type RiskLevelProps = {
  handleRiskLevelLabelChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    oldValue: string,
    defaultOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  ) => void;
};

const RiskLevels: React.FC<RiskLevelProps> = ({
  handleRiskLevelLabelChange,
}) => {
  const { control, setValue, watch } = useFormContext<RiskFormValidation>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "iso.levels",
  });

  const addLevel = useCallback(() => {
    const requiresControl =
      fields[fields.length - 1].requiresControl === "yes" ? "yes" : "no";
    append({ levelLabel: "", requiresControl });
  }, [append, fields]);

  const updateRequiresControl = useCallback(
    (onChange, index) => {
      return (item: QSelectItem<"Yes" | "No", string> | null) => {
        onChange(item?.value);
        if (item?.value === "yes") {
          for (let i = index + 1; i < fields.length; i++) {
            setValue(`iso.levels.${i}.requiresControl`, "yes");
          }
        }
      };
    },
    [fields, setValue],
  );

  const watchRiskLevels = watch("iso.levels");

  return (
    <>
      {fields.map((field, index) => (
        <QStack direction="row" key={field.id}>
          <QStack width="312px">
            <QFormControl>
              <Controller
                name={`iso.levels.${index}.levelLabel`}
                render={({ field: { value, onChange } }) => (
                  <QInput
                    value={value}
                    onChange={(e) =>
                      handleRiskLevelLabelChange(e, value, onChange)
                    }
                  />
                )}
              />
            </QFormControl>
          </QStack>
          <QStack width="312px">
            <QFormControl>
              <Controller
                name={`iso.levels.${index}.requiresControl`}
                render={({ field: { onChange, value } }) => (
                  <QSelect
                    onChange={updateRequiresControl(onChange, index)}
                    isDisabled={
                      index > 0 &&
                      watchRiskLevels[index - 1].requiresControl === "yes"
                    }
                    value={value}
                    options={[
                      { value: "no", label: "No" },
                      { value: "yes", label: "Yes" },
                    ]}
                  />
                )}
              />
            </QFormControl>
          </QStack>
          <QIconButton
            iconName="Trash"
            aria-label="Delete row"
            isDisabled={fields.length <= 2}
            onClick={() => remove(index)}
          />
        </QStack>
      ))}
      <QStack>
        <QButton
          leftIcon="Plus"
          variant="ghost"
          onClick={addLevel}
          isDisabled={fields.length > 4}
        >
          Add risk
        </QButton>
      </QStack>
    </>
  );
};

type Props = {
  onDelete?: (type: Policy["type"]) => void;
};

export const RiskPolicy: React.FC<Props> = ({ onDelete }) => {
  const {
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useFormContext<RiskFormValidation>();
  const [levels, setLevels] = useState(() =>
    getValues().iso.levels.map((level) => level.levelLabel),
  );

  const toggleFmea = useCallback(
    (toggle: boolean) => {
      setValue("fmeaEnabled", toggle, { shouldValidate: true });
    },
    [setValue],
  );

  const fmeaEnabled = watch("fmeaEnabled");
  const [rows, columns] = watch([
    "iso.riskMatrix.rows",
    "iso.riskMatrix.columns",
  ]);

  // ensure level options are updated
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name?.match(/iso\.levels/)) {
        const localLevels = value.iso.levels.map((level) => level.levelLabel);
        setLevels(localLevels);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, getValues, setLevels]);

  // ensure values are updated for level mapping
  const handleRiskLevelLabelChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      oldValue: string,
      defaultOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    ) => {
      const {
        iso: {
          riskMatrix: { matrix },
        },
      } = getValues();

      const {
        target: { value: newValue },
      } = event;

      let localLevelMapping = [...matrix];
      localLevelMapping = localLevelMapping.map((row) =>
        row.map((cell: string) => {
          return cell === oldValue ? newValue : cell;
        }),
      );
      setValue("iso.riskMatrix.matrix", localLevelMapping);
      defaultOnChange(event);
    },
    [getValues, setValue],
  );

  const actualRows = useMemo(() => {
    return Math.max(3, Math.min(5, Number(rows)));
  }, [rows]);
  const actualColumns = useMemo(() => {
    return Math.max(3, Math.min(5, Number(columns)));
  }, [columns]);

  const isoMatrix = useMemo(() => {
    const rows = Array(actualRows + 1).fill(null);
    const columns = Array(actualColumns + 1).fill(null);

    const options = levels.map((level) => ({
      value: level,
      label: level,
    }));

    return rows.map((_, rowIndex) => {
      return columns
        .map((_, columnIndex) => {
          if (!rowIndex && !columnIndex) {
            return <QSpacer key="spacer" />;
          }

          if (rowIndex === 0) {
            return (
              <Controller
                key={`severity.${columnIndex}`}
                name={`iso.riskMatrix.severity.${columnIndex - 1}`}
                render={({ field: { onChange, value } }) => (
                  <QInput onChange={onChange} value={value} />
                )}
              />
            );
          }

          if (rowIndex > 0 && columnIndex === 0) {
            return (
              <Controller
                key={`probability.${columnIndex}`}
                name={`iso.riskMatrix.probability.${rowIndex - 1}`}
                render={({ field: { onChange, value } }) => (
                  <QInput onChange={onChange} value={value} />
                )}
              />
            );
          }

          return (
            <QFormControl
              key={`levelMapping-${columnIndex}-${rowIndex}`}
              isInvalid={
                !!errors.iso?.riskMatrix?.matrix?.[rowIndex - 1]?.[
                  columnIndex - 1
                ]
              }
              data-cy="level-mapping"
            >
              <Controller
                name={`iso.riskMatrix.matrix.${rowIndex - 1}.${columnIndex - 1}`}
                render={({ field: { onChange, value } }) => (
                  <MappingContainer
                    options={options.length}
                    index={options.findIndex(
                      (option) => option.value === value,
                    )}
                  >
                    <QSelect
                      options={options as any}
                      onChange={(item) => onChange(item?.value)}
                      value={value}
                    />
                  </MappingContainer>
                )}
              />
            </QFormControl>
          );
        })
        .flat();
    });
  }, [actualRows, actualColumns, levels, errors]);

  const gridTemplate = useMemo(() => {
    return {
      3: "repeat(4, 163px)",
      4: "repeat(5, 128px)",
      5: "repeat(6, 105px)",
    }[actualColumns];
  }, [actualColumns]);

  return (
    <QStack gap={4} width={720} data-cy="risk-policy">
      <QStack>
        <QHeading size="md" fontWeight={600}>
          Risk assessment policies
        </QHeading>
        <QText fontSize="sm">
          You can create and manage components and categories in the{" "}
          <a href="/registry" target="_blank">
            Resource Library
          </a>{" "}
          to further organize your risks.
        </QText>
      </QStack>

      <QCard>
        <QStack padding={4}>
          <QText fontSize="md" fontWeight={600}>
            Risks
          </QText>
          <QStack direction="row">
            <QFormControl
              label="Prefix"
              width="72px"
              isInvalid={!!errors?.codePrefix}
              error={errors?.codePrefix?.message}
            >
              <Controller
                name="codePrefix"
                render={({ field: { onChange, value } }) => (
                  <QInput onChange={onChange} value={value} data-cy="prefix" />
                )}
              />
            </QFormControl>
            <QFormControl
              label="Name"
              isInvalid={!!errors?.label}
              error={errors?.label?.message}
            >
              <Controller
                name="label"
                render={({ field: { onChange, value } }) => (
                  <QInput onChange={onChange} value={value} data-cy="label" />
                )}
              />
            </QFormControl>
          </QStack>
        </QStack>
      </QCard>

      <QCard>
        <QStack padding={4}>
          <QText fontSize="md" fontWeight={600}>
            ISO 14971
          </QText>
          <QStack gap="16px" direction="column">
            <QStack gap="4px">
              <QStack direction="row">
                <QText fontWeight={600} fontSize="sm" width="312px">
                  Risk level
                </QText>
                <QText fontWeight={600} fontSize="sm" width="312px">
                  Requires risk control
                </QText>
              </QStack>
              <RiskLevels
                handleRiskLevelLabelChange={handleRiskLevelLabelChange}
              />
            </QStack>
            <QDivider />
            <QText fontWeight={600} fontSize="sm">
              Risk matrix
            </QText>

            <QStack direction="row">
              <QStack width="105px">
                <QText fontWeight={600}>Rows</QText>
                <QFormControl
                  isInvalid={!!errors.iso?.riskMatrix?.rows}
                  error={errors.iso?.riskMatrix?.rows?.message}
                >
                  <Controller
                    name="iso.riskMatrix.rows"
                    render={({ field: { value, onChange } }) => (
                      <QInput
                        value={value}
                        onChange={onChange}
                        data-cy="rows"
                      />
                    )}
                  />
                </QFormControl>
              </QStack>
              <QStack width="105px">
                <QText fontWeight={600}>Columns</QText>
                <QFormControl
                  isInvalid={!!errors.iso?.riskMatrix?.columns}
                  error={errors.iso?.riskMatrix?.columns?.message}
                >
                  <Controller
                    name="iso.riskMatrix.columns"
                    render={({ field: { value, onChange } }) => (
                      <QInput
                        value={value}
                        onChange={onChange}
                        data-cy="columns"
                      />
                    )}
                  />
                </QFormControl>
              </QStack>
            </QStack>

            <QBox display="grid" gridTemplateColumns={gridTemplate} gap={3}>
              {isoMatrix}
            </QBox>
          </QStack>
        </QStack>
      </QCard>

      <QCard>
        <QStack padding={4} gap={4}>
          <QStack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <QText fontSize="md" fontWeight={600}>
              FMEA
            </QText>
            {!fmeaEnabled && <QTag variantColor="gray">Disabled</QTag>}
          </QStack>
          {fmeaEnabled && (
            <>
              <QFormControl label="Scaling for Severity, Occurence, and Detectability">
                <Controller
                  name="fmea.scaleMaxValue"
                  render={({ field: { value, onChange } }) => (
                    <QStack direction="row" alignItems="center">
                      <QBox width="40px">
                        <QText fontWeight={600}>1 to</QText>
                      </QBox>
                      <QBox width="128px">
                        <QInput
                          value={value}
                          onChange={onChange}
                          type="number"
                          data-cy="scaleMaxValue"
                        />
                      </QBox>
                    </QStack>
                  )}
                />
              </QFormControl>
              <QDivider />
              <QFormControl
                label="Mitigation threshold (RPN)"
                helper="The RPN (Risk Priority Number) of a risk is calculated by multiple Severity x Occurance X Detectability"
              >
                <Controller
                  name="fmea.mitigationThreshold"
                  render={({ field: { value, onChange } }) => (
                    <QBox width="128px">
                      <QInput
                        value={value}
                        onChange={onChange}
                        type="number"
                        data-cy="mitigationThreshold"
                      />
                    </QBox>
                  )}
                />
              </QFormControl>
            </>
          )}
        </QStack>
        <QCardFooter>
          {fmeaEnabled ? (
            <QButton
              variant="ghost"
              leftIcon="EyeOff"
              onClick={() =>
                !!onDelete ? onDelete("risk") : toggleFmea(false)
              }
              data-cy="disable-fmea"
            >
              Disable
            </QButton>
          ) : (
            <QButton
              variant="ghost"
              leftIcon="Eye"
              onClick={() => toggleFmea(true)}
              data-cy="enable-fmea"
            >
              Enable
            </QButton>
          )}
        </QCardFooter>
      </QCard>
    </QStack>
  );
};
