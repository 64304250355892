import React, { useCallback, useMemo, useState } from "react";
import {
  QBodyLayout,
  QBox,
  QButton,
  QButtonGroup,
  QDateMetadataItem,
  QFooter,
  QFormControl,
  QHeader,
  QHorizontalMetadata,
  QInput,
  QSelect,
  QStack,
  QText,
  QTextarea,
  QTitle,
  useToastProvider,
} from "@qualio/ui-components";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FMEARiskForm, formToRisk } from "../validation";
import { zodResolver } from "@hookform/resolvers/zod";
import { ResourceComponentField } from "../../../components/ResourceComponentField";
import {
  isRequirementPolicy,
  isRiskPolicy,
  isTestCasePolicy,
} from "@design-controls/types";
import { useCurrentConfigs } from "../../../hooks/useCurrentConfigs";
import { RelateDesignElements } from "../../../components/RelateDesignElements/RelateDesignElements";
import { FormRiskScore } from "./FormRiskScore";
import { useUpdateDesignElement } from "../../../hooks/designElement/useUpdateDesignElement";
import { useNavigate } from "react-router";
import { riskScore } from "../../../domain/fmeaRequiresControl";
import { handleFormError } from "../../../api/handleFormError";

type FormProps = {
  risk: FMEARiskForm;
  onClose: () => void;
};

const containsRiskControl = (risk: FMEARiskForm) =>
  !!(
    risk.mitigatesRequirements?.length ||
    risk.mitigatesTestCases?.length ||
    risk.riskControl?.mitigation
  );

export const Form: React.FC<FormProps> = ({ risk, onClose }) => {
  const configs = useCurrentConfigs();
  const riskConfig = configs.find(isRiskPolicy);
  const [manualRiskControlEnabled, setManualRiskControlEnabled] =
    useState<boolean>(containsRiskControl(risk));

  const { isLoading, mutate } = useUpdateDesignElement();
  const navigate = useNavigate();
  const { showToast } = useToastProvider();

  const methods = useForm<FMEARiskForm>({
    defaultValues: risk,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(FMEARiskForm),
  });

  const { categoryResourceIdentifier } = useMemo(() => {
    return {
      categoryResourceIdentifier: riskConfig?.category?.[0].resourceIdentifier,
    };
  }, [riskConfig]);

  const requirementTypes = useMemo(() => {
    return configs.filter(isRequirementPolicy).map((item) => item.type);
  }, [configs]);

  const testCaseTypes = useMemo(() => {
    return configs.filter(isTestCasePolicy).map((item) => item.type) as (
      | "testCase1"
      | "testCase2"
      | "testCase3"
    )[];
  }, [configs]);

  const {
    watch,
    setError,
    formState: { errors },
  } = methods;

  const [
    preMitigationSeverity,
    preMitigationOccurence,
    preMitigationDetectability,
  ] = watch([
    "preMitigation.severity",
    "preMitigation.occurrence",
    "preMitigation.detectability",
  ]) as string[];

  const [
    postMitigationSeverity,
    postMitigationOccurence,
    postMitigationDetectability,
  ] = watch([
    "postMitigation.severity",
    "postMitigation.occurrence",
    "postMitigation.detectability",
  ]) as string[];

  const riskControlRequired = useMemo(() => {
    if (!riskConfig) {
      return false;
    }

    const threshold = Number(
      riskConfig.assessment.fmea?.mitigationThreshold ?? 0,
    );

    return (
      (riskScore(
        preMitigationSeverity,
        preMitigationDetectability,
        preMitigationOccurence,
      ) ?? 0) > threshold
    );
  }, [
    riskConfig,
    preMitigationSeverity,
    preMitigationOccurence,
    preMitigationDetectability,
  ]);

  const onSubmit = useCallback(
    (formData: FMEARiskForm) => {
      if (!riskConfig) {
        return;
      }

      const data = formToRisk(formData);
      mutate(data as any, {
        onSuccess: () => navigate(`../risk/fmea/${data.id}`),
        onError: (error) => {
          showToast({
            title: "Error",
            description: "Failed to save changes. Please try again",
            status: "error",
          });
          handleFormError(error, setError);
        },
      });
    },
    [riskConfig, mutate, navigate, setError],
  );

  const maxScaleOptions = useMemo(() => {
    if (!riskConfig?.assessment.fmea?.scaleMaxValue) {
      return [];
    }

    return Array(Number(riskConfig?.assessment.fmea?.scaleMaxValue))
      .fill(1)
      .map((_, index) => ({
        value: (index + 1).toString(10),
        label: (index + 1).toString(10),
      }));
  }, [riskConfig]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} data-cy="edit-fmea-risk">
        <QBodyLayout.Default size="md">
          <QHeader>
            <QTitle>
              <QText as="span" color="gray.500">
                {risk.code}
              </QText>{" "}
              {risk.title}
            </QTitle>
            <QHorizontalMetadata>
              <QDateMetadataItem
                label="Created"
                value={new Date(risk.created)}
                withTime={true}
              />
              <QDateMetadataItem
                label="Last modified"
                value={new Date(risk.updated)}
                withTime={true}
              />
            </QHorizontalMetadata>
          </QHeader>
          <QStack gap="16px">
            <QText fontStyle="italic">
              Required fields are marked with an asterisk (
              <QText color="red.500" as="span">
                *
              </QText>
              )
            </QText>
            <QFormControl
              label="Title"
              isRequired
              isInvalid={!!errors?.title}
              error={errors?.title?.message}
            >
              <Controller
                name="title"
                render={({ field: { value, onChange } }) => (
                  <QInput value={value} onChange={onChange} data-cy="title" />
                )}
              />
            </QFormControl>
            {categoryResourceIdentifier && (
              <ResourceComponentField
                name="category"
                label="Category"
                resourceSubType={categoryResourceIdentifier}
              />
            )}
            <QText fontSize="xl" fontWeight={600}>
              Initial assessment
            </QText>

            <QFormControl
              label="Failure mode"
              isInvalid={!!errors?.failureMode}
              error={errors?.failureMode?.message}
            >
              <Controller
                name="failureMode"
                render={({ field: { value, onChange } }) => (
                  <QTextarea
                    value={value}
                    onChange={onChange}
                    data-cy="failureMode"
                  />
                )}
              />
            </QFormControl>

            <QStack direction="row" columnGap="24px">
              <QBox width="188px">
                <QFormControl
                  label="Severity"
                  isInvalid={!!errors?.preMitigation?.severity}
                  data-cy="premitigation-severity"
                >
                  <Controller
                    name="preMitigation.severity"
                    render={({ field: { value, onChange } }) => (
                      <QSelect
                        value={value}
                        options={maxScaleOptions}
                        onChange={(item) => item && onChange(item.value)}
                      />
                    )}
                  />
                </QFormControl>
              </QBox>
              <QBox width="188px">
                <QFormControl
                  label="Occurence"
                  isInvalid={!!errors?.preMitigation?.occurrence}
                  data-cy="premitigation-occurrence"
                >
                  <Controller
                    name="preMitigation.occurrence"
                    render={({ field: { value, onChange } }) => (
                      <QSelect
                        value={value}
                        options={maxScaleOptions}
                        onChange={(item) => item && onChange(item.value)}
                      />
                    )}
                  />
                </QFormControl>
              </QBox>

              <QBox width="188px">
                <QFormControl
                  label="Detectability"
                  isInvalid={!!errors?.preMitigation?.detectability}
                  data-cy="premitigation-detectability"
                >
                  <Controller
                    name="preMitigation.detectability"
                    render={({ field: { value, onChange } }) => (
                      <QSelect
                        value={value}
                        options={maxScaleOptions}
                        onChange={(item) => item && onChange(item.value)}
                      />
                    )}
                  />
                </QFormControl>
              </QBox>
              <QStack gap="4px" width="103px">
                <QText fontSize="sm" fontWeight={600}>
                  Risk
                </QText>
                <QBox>
                  <FormRiskScore
                    severity={preMitigationSeverity}
                    occurence={preMitigationOccurence}
                    detectability={preMitigationDetectability}
                  />
                </QBox>
              </QStack>
              <QStack gap="4px" width="103px">
                <QText fontSize="sm" fontWeight={600}>
                  Risk control
                </QText>
                {riskControlRequired ? (
                  <QText fontSize="sm" fontWeight={600}>
                    Required
                  </QText>
                ) : (
                  <QText fontSize="sm">Not required</QText>
                )}
              </QStack>
            </QStack>

            <QFormControl
              label="Effects of failure mode"
              isInvalid={!!errors?.failureModeEffects}
              error={errors?.failureModeEffects?.message}
            >
              <Controller
                name="failureModeEffects"
                render={({ field: { value, onChange } }) => (
                  <QTextarea
                    value={value}
                    onChange={onChange}
                    data-cy="failureModeEffects"
                  />
                )}
              />
            </QFormControl>

            <QFormControl
              label="Causes of failure"
              isInvalid={!!errors?.failureCauses}
              error={errors?.failureCauses?.message}
            >
              <Controller
                name="failureCauses"
                render={({ field: { value, onChange } }) => (
                  <QTextarea
                    value={value}
                    onChange={onChange}
                    data-cy="failureCauses"
                  />
                )}
              />
            </QFormControl>

            <QFormControl
              label="Current controls / evaluation method"
              isInvalid={!!errors?.evaluationMethod}
              error={errors?.evaluationMethod?.message}
            >
              <Controller
                name="evaluationMethod"
                render={({ field: { value, onChange } }) => (
                  <QTextarea
                    value={value}
                    onChange={onChange}
                    data-cy="evaluationMethod"
                  />
                )}
              />
            </QFormControl>

            {requirementTypes.length > 0 && (
              <QFormControl>
                <Controller
                  name="sourceRequirements"
                  render={({ field: { value, onChange } }) => (
                    <RelateDesignElements
                      label="Source requirements"
                      resource="dc-requirement"
                      resourceSubType={requirementTypes}
                      onChange={onChange}
                      value={value}
                      data-cy="add-source-requirement"
                      buttonLabel="Add requirements"
                    />
                  )}
                />
              </QFormControl>
            )}

            {testCaseTypes.length > 0 && (
              <QFormControl>
                <Controller
                  name="sourceTestCases"
                  render={({ field: { value, onChange } }) => (
                    <RelateDesignElements
                      label="Source test cases"
                      resource="dc-testCase"
                      resourceSubType={testCaseTypes}
                      onChange={onChange}
                      value={value}
                      data-cy="add-source-testcase"
                      buttonLabel="Add test cases"
                    />
                  )}
                />
              </QFormControl>
            )}

            <QStack>
              <QText fontSize="xl" fontWeight={600}>
                Risk control
              </QText>
              <QText>
                Risk control may be required based on the initial assessment,
                but you can add this later.
              </QText>
            </QStack>

            {!manualRiskControlEnabled && !riskControlRequired && (
              <QButton
                variant="outline"
                onClick={() => setManualRiskControlEnabled(true)}
                data-cy="add-risk-control-btn"
              >
                Add risk control
              </QButton>
            )}

            {(manualRiskControlEnabled || riskControlRequired) && (
              <>
                <QFormControl
                  label="Risk control description"
                  isInvalid={!!errors?.riskControl?.mitigation}
                  error={errors?.riskControl?.mitigation?.message}
                >
                  <Controller
                    name="riskControl.mitigation"
                    render={({ field: { value, onChange } }) => (
                      <QTextarea
                        value={value}
                        onChange={onChange}
                        data-cy="riskControl.mitigation"
                      />
                    )}
                  />
                </QFormControl>

                {requirementTypes.length > 0 && (
                  <QFormControl>
                    <Controller
                      name="mitigatesRequirements"
                      render={({ field: { value, onChange } }) => (
                        <RelateDesignElements
                          label="Risk control requirements"
                          resource="dc-requirement"
                          resourceSubType={requirementTypes}
                          onChange={onChange}
                          value={value}
                          data-cy="add-mitigation-requirement"
                          buttonLabel="Add requirements"
                        />
                      )}
                    />
                  </QFormControl>
                )}

                {testCaseTypes.length > 0 && (
                  <QFormControl>
                    <Controller
                      name="mitigatesTestCases"
                      render={({ field: { value, onChange } }) => (
                        <RelateDesignElements
                          label="Risk control test cases"
                          resource="dc-testCase"
                          resourceSubType={testCaseTypes}
                          onChange={onChange}
                          value={value}
                          data-cy="add-mitigation-testcase"
                          buttonLabel="Add test cases"
                        />
                      )}
                    />
                  </QFormControl>
                )}

                <QText fontSize="xl" fontWeight={600}>
                  Final assessment
                </QText>

                <QStack direction="row" columnGap="24px">
                  <QBox width="188px">
                    <QFormControl
                      label="Severity"
                      isInvalid={!!errors?.postMitigation?.severity}
                      data-cy="postmitigation-severity"
                    >
                      <Controller
                        name="postitigation.severity"
                        render={({ field: { value, onChange } }) => (
                          <QSelect
                            value={value}
                            options={maxScaleOptions}
                            onChange={(item) => item && onChange(item.value)}
                          />
                        )}
                      />
                    </QFormControl>
                  </QBox>
                  <QBox width="188px">
                    <QFormControl
                      label="Occurence"
                      isInvalid={!!errors?.postMitigation?.occurrence}
                      data-cy="postitigation-occurrence"
                    >
                      <Controller
                        name="postMitigation.occurrence"
                        render={({ field: { value, onChange } }) => (
                          <QSelect
                            value={value}
                            options={maxScaleOptions}
                            onChange={(item) => item && onChange(item.value)}
                          />
                        )}
                      />
                    </QFormControl>
                  </QBox>

                  <QBox width="188px">
                    <QFormControl
                      label="Detectability"
                      isInvalid={!!errors?.postMitigation?.detectability}
                      data-cy="postmitigation-detectability"
                    >
                      <Controller
                        name="postMitigation.detectability"
                        render={({ field: { value, onChange } }) => (
                          <QSelect
                            value={value}
                            options={maxScaleOptions}
                            onChange={(item) => item && onChange(item.value)}
                          />
                        )}
                      />
                    </QFormControl>
                  </QBox>
                  <QStack gap="4px" width="103px">
                    <QText fontSize="sm" fontWeight={600}>
                      Risk
                    </QText>
                    <QBox>
                      <FormRiskScore
                        severity={postMitigationSeverity}
                        occurence={postMitigationOccurence}
                        detectability={postMitigationDetectability}
                      />
                    </QBox>
                  </QStack>
                </QStack>
              </>
            )}
          </QStack>

          <QFooter>
            <QButtonGroup>
              <QButton
                variant="outline"
                onClick={onClose}
                data-cy="cancel"
                isDisabled={isLoading}
              >
                Cancel
              </QButton>
              <QButton
                type="submit"
                data-cy="save-changes"
                isLoading={isLoading}
              >
                Save changes
              </QButton>
            </QButtonGroup>
          </QFooter>
        </QBodyLayout.Default>
      </form>
    </FormProvider>
  );
};
