import React, { useCallback, useState } from "react";

import {
  createQColumnHelper,
  DataProvider,
  QButton,
  QButtonGroup,
  QCard,
  QCollapse,
  QDataTable,
  QHeading,
  QIconButton,
  QStack,
  QTag,
  QText,
} from "@qualio/ui-components";
import { TestResult } from "@design-controls/types";
import { ResultValueToLabel, TestResultVariantColour } from "../../constants";
import { useNavigate } from "react-router";
import { AttachmentTable } from "./AttachmentTable";

const columnHelper = createQColumnHelper<any>();

type Props = {
  result?: TestResult;
};

export const TestResultTable: React.FC<Props> = ({ result }) => {
  const [expanded, setExpanded] = useState(true);
  const navigate = useNavigate();

  const addTestResult = useCallback(() => navigate("add-result"), [navigate]);

  const columns = [
    columnHelper.date("date", { header: "Date", maxWidth: "100px" }),
    columnHelper.text("executedBy", { header: "Tester", maxWidth: "200px" }),
    columnHelper.text("notes", { header: "Comments", maxWidth: "500px" }),
    {
      header: "Last results",
      meta: {
        maxWidth: "150px",
      },
      cell: ({ row: { original: row } }: { row: { original: TestResult } }) => {
        return (
          <QTag variantColor={TestResultVariantColour[row.result]}>
            {ResultValueToLabel[row.result]}
          </QTag>
        );
      },
    },
  ];

  return (
    <QCard>
      <QStack
        direction="row"
        justifyContent="space-between"
        padding="12px 16px"
      >
        <QHeading fontSize="md" fontWeight={600}>
          Test results
        </QHeading>
        <QButtonGroup>
          <QButton variant="link" onClick={addTestResult}>
            Add test result
          </QButton>
          <QIconButton
            onClick={() => setExpanded((s) => !s)}
            iconName={expanded ? "ChevronUp" : "ChevronDown"}
            aria-label={
              expanded ? "Collapse requirements" : "Expand requirements"
            }
          />
        </QButtonGroup>
      </QStack>
      <QCollapse isOpen={expanded}>
        {result && (
          <DataProvider.Fixed data={[result]}>
            <QDataTable columns={columns} hideItemCount data-cy="test-result" />
          </DataProvider.Fixed>
        )}
        {!!result?.attachments?.length && (
          <QStack margin={2}>
            <QStack gap="8px">
              <QHeading fontSize="md" fontWeight={600}>
                Attachments
              </QHeading>
              <QText fontSize="sm">
                Supporting documents and files associated with this test result.
              </QText>
            </QStack>
            <AttachmentTable attachments={result?.attachments} withoutBorder />
          </QStack>
        )}
      </QCollapse>
    </QCard>
  );
};
