import { useCurrentUser } from "@qualio/ui-components";
import { useCreateProductStore } from "../useCreateProductStore";
import { useMutation } from "react-query";
import { designControlsApi } from "../../api/designControls";
import { useNavigate } from "react-router";
import { useCallback } from "react";
import { Policy } from "@design-controls/types";

export const useCreateProduct = () => {
  const { companyId } = useCurrentUser();
  const navigate = useNavigate();
  const { product, requirements, testCases, risk } = useCreateProductStore(
    (state) => ({
      product: state.product,
      requirements: state.requirementPolicies,
      testCases: state.testCasePolicies,
      risk: state.riskPolicy,
    }),
  );

  const createProductMutation = useMutation({
    mutationFn: () => designControlsApi.createProduct(companyId, product),
  });

  // create policies
  const createPolicies = useMutation({
    mutationFn: (productId: string) =>
      Promise.all(
        [...Object.values(requirements), ...Object.values(testCases), risk]
          .filter(Boolean)
          .map((policy) =>
            designControlsApi.createPolicy(companyId, productId, {
              ...policy,
              codeStrategy: "GENERATED",
              workflow: { states: [] }, // casting required as workflow is removed but not from backend
            } as unknown as Policy),
          ),
      ),
  });

  const createProduct = useCallback(async () => {
    const product = await createProductMutation.mutateAsync();
    createPolicies.mutate(product.id, {
      onSuccess: () => navigate(`/product/${product.id}`),
    });
  }, [createProductMutation, createPolicies]);

  return {
    isLoading: createProductMutation.isLoading || createPolicies.isLoading,
    createProduct,
  };
};
