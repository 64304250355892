import React, { useMemo } from "react";

import { isTestCasePolicy, Policy } from "@design-controls/types";
import { QFormControl, QMultiSelect } from "@qualio/ui-components";
import { Controller } from "react-hook-form";

type Props = {
  policies: Policy[];
};

export const TestCaseType: React.FC<Props> = ({ policies }) => {
  const options = useMemo(() => {
    return policies
      .filter(isTestCasePolicy)
      .map((config) => ({ label: config.label, value: config.type }));
  }, [policies]);

  return (
    <Controller
      name="type"
      render={({ field: { onChange, value } }) => (
        <QFormControl label="Test type">
          <QMultiSelect
            value={value}
            onChange={(selectedItem) =>
              onChange(selectedItem.map((item) => item.value))
            }
            options={options}
          />
        </QFormControl>
      )}
    />
  );
};
