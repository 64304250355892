import React, { useCallback, useMemo } from "react";

import {
  QBackLink,
  QBodyLayout,
  QButtonGroup,
  QHeader,
  QMenuButton,
  QMenuItem,
  QStack,
  QTabs,
  QText,
  QTitle,
} from "@qualio/ui-components";
import { useLocation, useNavigate } from "react-router";

import { RequirementModal } from "../components/CreateDesignElement/RequirementModal";
import { RiskModal } from "../components/CreateDesignElement/RiskModal";
import { TestCaseModal } from "../components/CreateDesignElement/TestCaseModal";
import { useCurrentProduct } from "../hooks/useCurrentProduct";
import { useRequirementModal } from "../hooks/useRequirementModal";
import { useRiskModal } from "../hooks/useRiskModal";
import { useTestCaseModal } from "../hooks/useTestCaseModal";
import { ManageProduct } from "../components/ManageProduct/ManageProduct";

const TABS: [string, string, string][] = [
  ["requirement", "requirement", "Requirements"],
  ["testCase", "test-case", "Tests"],
  ["risk", "risk", "Risks"],
  ["traceability", "traceability", "Traceability"],
  ["document", "document", "Documents"],
  ["reviews", "change-control", "Reviews"],
];

export const ListLayout: React.FC = ({ children }) => {
  const product = useCurrentProduct();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { openModal: openRequirementModal } = useRequirementModal();
  const { openModal: openTestCaseModal } = useTestCaseModal();
  const { openModal: openRiskModal } = useRiskModal();

  const onItemChange = useCallback(
    (index: number) => {
      const item = TABS.at(index);
      if (!item) {
        return;
      }

      navigate(`/product/${product.id}/${item[1]}`);
    },
    [navigate, product],
  );

  const createReview = useCallback(() => {
    navigate("../change-control/create");
  }, [navigate]);

  const activeTabId = useMemo(() => {
    const item = TABS.find(([, routePath]) => pathname.includes(routePath));

    if (!item) {
      return "requirement";
    }

    return item[0];
  }, [pathname]);

  const onClick = useCallback(
    (type: "requirement" | "testCase") => () => {
      if (type === "requirement") {
        openRequirementModal();
      } else {
        openTestCaseModal();
      }
    },
    [openRequirementModal, openTestCaseModal],
  );

  return (
    <QBodyLayout.Default>
      <QHeader showDivider={false}>
        <QBackLink href="../../">Back</QBackLink>
        <QTitle>
          <QText data-cy="list-product-name" as="span">
            {product.name}
          </QText>
        </QTitle>
        <QButtonGroup>
          <QMenuButton buttonLabel="New" variant="solid" data-cy="create-new">
            <QMenuItem
              onClick={onClick("requirement")}
              data-cy="create-requirement"
            >
              Requirement
            </QMenuItem>
            <QMenuItem onClick={onClick("testCase")} data-cy="create-test-case">
              Test case
            </QMenuItem>
            <QMenuItem onClick={openRiskModal} data-cy="create-risk">
              Risk
            </QMenuItem>
            <QMenuItem onClick={createReview} data-cy="create-review">
              Review
            </QMenuItem>
          </QMenuButton>
          <ManageProduct />
        </QButtonGroup>
      </QHeader>
      <QStack gap={4}>
        <QTabs
          activeTabId={activeTabId}
          onChange={onItemChange}
          tabs={TABS.map(([id, , title]) => ({ id, title }))}
        />
        {children}
      </QStack>
      <RequirementModal />
      <TestCaseModal />
      <RiskModal />
    </QBodyLayout.Default>
  );
};
