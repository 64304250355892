import React, { useCallback, useMemo } from "react";
import {
  FileSizeInBytes,
  QBodyLayout,
  QButton,
  QButtonGroup,
  QDateMetadataItem,
  QDivider,
  QFooter,
  QFormControl,
  QHeader,
  QHorizontalMetadata,
  QInput,
  QMultiFileInput,
  QSelect,
  QStack,
  QText,
  QTitle,
  useToastProvider,
} from "@qualio/ui-components";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { RelateDesignElements } from "../../../components/RelateDesignElements/RelateDesignElements";
import { Editor } from "../../../../components";
import { TestCaseForm } from "../validation";
import { useUploadFile } from "../../../hooks/useUploadFile";
import { ResourceComponentField } from "../../../components/ResourceComponentField";
import { useCurrentConfigs } from "../../../hooks/useCurrentConfigs";
import {
  DesignElement,
  isRequirementPolicy,
  isTestCasePolicy,
} from "@design-controls/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useUpdateDesignElement } from "../../../hooks/designElement/useUpdateDesignElement";
import { useNavigate } from "react-router";
import { useDownloadAttachment } from "../../../hooks/designElement/useDownloadAttachment";

type FormProps = {
  testCase: TestCaseForm;
  onClose: () => void;
};

export const Form: React.FC<FormProps> = ({ testCase, onClose }) => {
  const { onDownloadFile } = useDownloadAttachment();

  const methods = useForm<TestCaseForm>({
    defaultValues: testCase,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(TestCaseForm),
  });

  const configs = useCurrentConfigs();
  const { isLoading, mutate } = useUpdateDesignElement();
  const navigate = useNavigate();
  const { showToast } = useToastProvider();

  const type = methods.watch("type");
  const uploadFile = useUploadFile();

  const { categoryResourceIdentifier } = useMemo(() => {
    const config = configs
      .filter(isTestCasePolicy)
      .find((item) => item.type === type);

    return {
      categoryResourceIdentifier: config?.category?.[0].resourceIdentifier,
    };
  }, [configs, type]);

  const testCaseConfigOptions = useMemo(() => {
    return configs.filter(isTestCasePolicy).map((item) => ({
      label: item.label,
      value: item.type,
    }));
  }, [configs]);

  const requirementTypes = useMemo(() => {
    const validLinks: Record<string, DesignElement["type"][]> = {
      testCase1: ["req1"],
      testCase2: ["req2"],
      testCase3: ["req3"],
    };
    const requirementConfigs = configs.filter(isRequirementPolicy);

    return validLinks[type].filter((linkType) =>
      requirementConfigs.some((config) => config.type === linkType),
    );
  }, [configs, type]);

  const onSubmit = useCallback(
    (formData: TestCaseForm) => {
      const data = {
        id: formData.id,
        type: formData.type,
        title: formData.title,
        category: formData.category?.qri ? formData.category : undefined,
        description: formData.description,
        attachments: formData.attachments,
        requirements: formData.requirements?.map((item) => item.id),
      };

      mutate(data as any, {
        onSuccess: () => navigate(`../test-case/${data.id}`),
        onError: () => {
          showToast({
            title: "Error",
            description: "Failed to save changes. Please try again",
            status: "error",
          });
        },
      });
    },
    [mutate, navigate],
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} data-cy="edit-test-case">
        <QBodyLayout.Default size="sm">
          <QHeader>
            <QTitle>
              <QText as="span" color="gray.500">
                {testCase.code}
              </QText>{" "}
              {testCase.title}
            </QTitle>
            <QHorizontalMetadata>
              <QDateMetadataItem
                label="Created"
                value={new Date(testCase.created)}
                withTime={true}
              />
              <QDateMetadataItem
                label="Last modified"
                value={new Date(testCase.updated)}
                withTime={true}
              />
            </QHorizontalMetadata>
          </QHeader>
          <QStack gap="24px">
            <QText fontStyle="italic">
              Required fields are marked with an asterisk (
              <QText color="red.500" as="span">
                *
              </QText>
              )
            </QText>
            <QFormControl label="Title" isRequired>
              <Controller
                name="title"
                render={({ field: { value, onChange } }) => (
                  <QInput value={value} onChange={onChange} data-cy="title" />
                )}
              />
            </QFormControl>
            <QFormControl label="Type" isRequired>
              <Controller
                name="type"
                render={({ field: { value, onChange } }) => (
                  <QSelect
                    isDisabled
                    value={value}
                    onChange={onChange}
                    options={testCaseConfigOptions}
                  />
                )}
              />
            </QFormControl>
            {categoryResourceIdentifier && (
              <ResourceComponentField
                name="category"
                label="Category"
                resourceSubType={categoryResourceIdentifier}
              />
            )}
            <QFormControl label="Description">
              <Controller
                name="description"
                render={({ field: { value, onChange } }) => (
                  <Editor onChange={onChange} value={value} />
                )}
              />
            </QFormControl>
            <QDivider />
            <QStack gap="16px">
              <QStack gap="8px">
                <QText fontSize="lg" fontWeight={600}>
                  Related design elements
                </QText>
                <QText fontSize="sm">
                  You may link any related requirements and validation test
                  cases.
                </QText>
              </QStack>
              {requirementTypes.length > 0 && (
                <QFormControl>
                  <Controller
                    name="requirements"
                    render={({ field: { value, onChange } }) => (
                      <RelateDesignElements
                        label="Requirements"
                        resource="dc-requirement"
                        resourceSubType={requirementTypes}
                        onChange={onChange}
                        value={value}
                        data-cy="add-requirement"
                      />
                    )}
                  />
                </QFormControl>
              )}
            </QStack>
            <QDivider />
            <QText fontSize="lg" fontWeight={600}>
              Attachments
            </QText>
            <QFormControl data-cy="attachments">
              <Controller
                name="attachments"
                render={({ field: { value, onChange } }) => (
                  <QMultiFileInput
                    maxFiles={10}
                    value={value}
                    maxFileSize={FileSizeInBytes.mb(100)}
                    maxCombinedSize={FileSizeInBytes.mb(500)}
                    description="Up to 100 MB per file"
                    onChange={onChange}
                    onUploadFile={uploadFile}
                    onDownloadFile={onDownloadFile}
                  />
                )}
              />
            </QFormControl>
          </QStack>
          <QFooter>
            <QButtonGroup>
              <QButton
                variant="outline"
                onClick={onClose}
                isDisabled={isLoading}
                data-cy="cancel"
              >
                Cancel
              </QButton>
              <QButton
                type="submit"
                isLoading={isLoading}
                data-cy="save-changes"
              >
                Save changes
              </QButton>
            </QButtonGroup>
          </QFooter>
        </QBodyLayout.Default>
      </form>
    </FormProvider>
  );
};
