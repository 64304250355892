import {
  FileSizeInBytes,
  QButton,
  QFormControl,
  QHeading,
  QInput,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QMultiFileInput,
  QSelect,
  QStack,
  QText,
  QTextarea,
} from "@qualio/ui-components";
import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { useRetrieveDesignElementById } from "../../hooks/useRetrieveDesignElementById";
import { SelectedElement } from "../../types/router";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useUploadFile } from "../../hooks/useUploadFile";
import { TestResult } from "@design-controls/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { ResultValueToLabel } from "../../constants";
import { useAddTestResult } from "../../hooks/useAddTestResult";
import { useDownloadAttachment } from "../../hooks/designElement/useDownloadAttachment";
import { handleFormError } from "../../api/handleFormError";

export const AddTestResult: React.FC = () => {
  const navigate = useNavigate();
  const onClose = useCallback(() => navigate(".."), [navigate]);
  const { selectedItemId } = useParams<SelectedElement>() as SelectedElement;
  const { data: designElement } = useRetrieveDesignElementById(selectedItemId);
  const uploadFile = useUploadFile();
  const { mutate, isLoading } = useAddTestResult();
  const { onDownloadFile } = useDownloadAttachment();

  const options = useMemo(
    () =>
      Object.entries(ResultValueToLabel).map(([value, label]) => ({
        label,
        value,
      })),
    [ResultValueToLabel],
  );

  const methods = useForm<Omit<TestResult, "date">>({
    defaultValues: {
      executedBy: "",
      result: "",
      notes: "",
      attachments: [],
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(TestResult.omit({ date: true })),
  });

  const {
    formState: { errors },
    setError,
  } = methods;

  const onSubmit = useCallback(
    (form: TestResult) => {
      if (!designElement) {
        return;
      }

      mutate(
        {
          ...form,
          testCase: designElement.id,
        },
        {
          onSuccess: () => onClose(),
          onError: (error) => {
            handleFormError(error, setError);
          },
        },
      );
    },
    [designElement, setError],
  );

  if (!designElement) {
    return null;
  }

  return (
    <QModal isOpen={true} onClose={onClose} size="xl">
      <QModalHeader>
        <QText>Add test results</QText>
      </QModalHeader>
      <QModalBody>
        <QHeading fontSize="md" fontWeight={600}>
          <QText as="span" color="gray.500">
            {designElement?.code}
          </QText>{" "}
          {designElement?.title}
        </QHeading>
        <FormProvider {...methods}>
          <QStack gap="8px">
            <QFormControl
              label="Tester"
              isRequired
              isInvalid={!!errors.executedBy}
              error={errors.executedBy?.message}
            >
              <Controller
                name="executedBy"
                render={({ field: { onChange, value } }) => (
                  <QInput value={value} onChange={onChange} data-cy="tester" />
                )}
              />
            </QFormControl>
            <QFormControl
              label="Result"
              isRequired
              isInvalid={!!errors.result}
              error={errors.result?.message}
              data-cy="test-result"
            >
              <Controller
                name="result"
                render={({ field: { onChange, value } }) => (
                  <QSelect
                    options={options}
                    value={value}
                    onChange={(item) => onChange(item?.value)}
                  />
                )}
              />
            </QFormControl>
            <QFormControl
              label="Notes"
              isInvalid={!!errors.notes}
              error={errors.notes?.message}
            >
              <Controller
                name="notes"
                render={({ field: { onChange, value } }) => (
                  <QTextarea
                    value={value}
                    onChange={onChange}
                    data-cy="notes"
                  />
                )}
              />
            </QFormControl>
            <QFormControl
              label="Attachments"
              isInvalid={!!errors.attachments}
              data-cy="attachments"
            >
              <Controller
                name="attachments"
                render={({ field: { onChange, value } }) => (
                  <QMultiFileInput
                    maxFiles={5}
                    value={value}
                    maxFileSize={FileSizeInBytes.mb(100)}
                    maxCombinedSize={FileSizeInBytes.mb(500)}
                    description="Up to 100 MB per file"
                    onChange={onChange}
                    onUploadFile={uploadFile}
                    onDownloadFile={onDownloadFile}
                  />
                )}
              />
            </QFormControl>
          </QStack>
        </FormProvider>
      </QModalBody>
      <QModalActions>
        <QButton variant="outline" onClick={onClose} isDisabled={isLoading}>
          Cancel
        </QButton>
        <QButton
          onClick={methods.handleSubmit(onSubmit)}
          isLoading={isLoading}
          data-cy="add-result-button"
        >
          Add result
        </QButton>
      </QModalActions>
    </QModal>
  );
};
