import { useMutation, useQueryClient } from "react-query";
import changeControlApi from "../../api/changeControl";
import { useCompanyId } from "../../context/CurrentUserContext";
import { useToastProvider } from "@qualio/ui-components";

export const useSendForApproval = (
  productId: string,
  changeControlId: string,
) => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();
  const { showToast } = useToastProvider();

  return useMutation({
    mutationFn: () =>
      changeControlApi.sendForApproval(companyId, productId, changeControlId),
    onSuccess: async () => {
      await queryClient.invalidateQueries(
        `queryChangeControl-company-${companyId}-product-${productId}-changeControlId-${changeControlId}`,
      );
      showToast({
        title: "Sent for approval",
        description: "Successfully sent for approval",
        status: "success",
      });
    },
    onError: () => {
      showToast({
        title: "Not sent for approval",
        description: "Cannot send for approval",
        status: "error",
      });
    },
  });
};
