import React, { useCallback } from "react";
import {
  ReviewForm as ReviewFormValidation,
  ReviewFormToReviewRecord,
} from "../../components/ReviewForm/validation";
import { ReviewRecord } from "@design-controls/types";
import { useCreateReview } from "../../hooks/review/useCreateReview";
import { Outlet, useNavigate } from "react-router";
import { ReviewForm } from "../../components/ReviewForm/ReviewForm";

export const CreateReview: React.FC = () => {
  const { mutate, isLoading: isSaving } = useCreateReview();
  const navigate = useNavigate();

  const onSubmit = useCallback(
    (form: ReviewFormValidation) => {
      mutate(ReviewFormToReviewRecord(form), {
        onSuccess: (response: Omit<ReviewRecord, "qualityItems">) => {
          navigate(`../change-control/${response.id}/view`);
        },
      });
    },
    [mutate, navigate],
  );

  const onClose = useCallback(() => navigate("../change-control"), [navigate]);

  return (
    <>
      <ReviewForm
        isSaving={isSaving}
        onSubmit={onSubmit}
        defaultValue={{
          testCases: [],
          requirements: [],
          risks: [],
          title: "",
          description: "",
          linkedDocuments: [],
          approvers: [],
        }}
        onClose={onClose}
      >
        Create design review
      </ReviewForm>
      <Outlet />
    </>
  );
};
