import {
  QriAttributes,
  useCurrentUser,
  useRelatedRecordsDrawer,
} from "@qualio/ui-components";
import { useCallback, useState } from "react";
import { useCurrentProduct } from "./useCurrentProduct";

export const useReferenceDrawer = (
  domainType: string,
  id?: string,
  label?: string,
) => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();
  const [qriAttributes, setQriAttributes] = useState<QriAttributes | undefined>(
    undefined,
  );

  const handleClick = useCallback(() => {
    if (!id) {
      return;
    }

    const qriAttributes = {
      companyId: companyId.toString(10),
      domain: "design-controls",
      domainType,
      id: `${productId}#${id}`,
    };

    setQriAttributes(qriAttributes);
  }, [id, companyId, domainType]);

  useRelatedRecordsDrawer(
    {
      qri: qriAttributes,
      isLoading: false,
      label: label ?? "",
    },
    () => {
      setQriAttributes(undefined);
    },
  );

  return { handleClick };
};
