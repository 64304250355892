import { QFileInputValue, useCurrentUser } from "@qualio/ui-components";
import { useCurrentProduct } from "../useCurrentProduct";
import { useCallback } from "react";
import { downloadFile } from "../../components/OverviewTable/downloadFIle";

export const useDownloadAttachment = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();

  const download = useCallback(
    ({ id }: QFileInputValue) => {
      downloadFile(
        `${process.env.REACT_APP_API_BASE_URL}/attachments/company/${companyId}/product/${productId}/download/attachment/${id}`,
      );
    },
    [companyId, productId],
  );

  return {
    onDownloadFile: download,
  };
};
