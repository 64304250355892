import { useCurrentUser } from "@qualio/ui-components";
import { useCurrentProduct } from "../useCurrentProduct";
import { useQuery } from "react-query";
import { designControlsApi } from "../../api/designControls";

export const useTraceability = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();

  return useQuery({
    queryFn: () => designControlsApi.traceabilityMatrix(companyId, productId),
    queryKey: ["TRACEABILITY", companyId, productId],
  });
};
