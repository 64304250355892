import { useMutation, useQueryClient } from "react-query";
import { designControlsApi } from "../../api/designControls";
import { useCurrentUser, useToastProvider } from "@qualio/ui-components";
import { useCurrentProduct } from "../useCurrentProduct";
import { useNavigate } from "react-router";

export const useDeleteReview = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();
  const queryClient = useQueryClient();
  const { showToast } = useToastProvider();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (reviewId: string) =>
      designControlsApi.deleteReview(companyId, productId, reviewId),
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        "REVIEW_LIST",
        companyId,
        productId,
      ]);
      showToast({
        title: "Deleted",
        description: "Successfully deleted review",
        status: "success",
      });
      navigate("../change-control");
    },
    onError: () => {
      showToast({
        title: "Not deleted review",
        description: "Cannot delete review",
        status: "error",
      });
    },
  });
};
