import { useParams } from "react-router-dom";
import { ChangeControlElement, DiffViewRoute } from "../../types/router";
import { useRetrieveDesignElementHistory } from "../useRetrieveDesignElementHistory";
import { designControlsApi } from "../../api/designControls";
import { useCurrentUser } from "@qualio/ui-components";
import { useCurrentProduct } from "../useCurrentProduct";
import { useQueries } from "react-query";
import { useMemo } from "react";

export const useDiffView = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();
  const { selectedItemId, baseVersion, compareVersion } =
    useParams<DiffViewRoute>() as DiffViewRoute;

  const { changeControlId } =
    useParams<ChangeControlElement>() as ChangeControlElement;

  const { data: designElementHistory } =
    useRetrieveDesignElementHistory(selectedItemId);

  const sortedHistory = useMemo(() => {
    if (!designElementHistory) {
      return [];
    }

    return designElementHistory.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    );
  }, [designElementHistory]);

  const [baseV, compareV] = useMemo(() => {
    if (!sortedHistory || !sortedHistory.length) {
      return [undefined, undefined];
    }

    const compareTo = sortedHistory.findIndex(
      (item) => item.id === changeControlId,
    );

    const base = baseVersion
      ? sortedHistory.find((item) => item.version === baseVersion)
      : sortedHistory[
          compareTo === -1 ? sortedHistory.length - 1 : compareTo - 1
        ];

    return [
      base,
      compareVersion
        ? { code: "Latest", version: compareVersion }
        : sortedHistory[compareTo],
    ];
  }, [baseVersion, compareVersion, sortedHistory, changeControlId]);

  const queries = useQueries([
    {
      queryKey: [
        "DESIGN_ELEMENT_BY_ID",
        companyId,
        productId,
        selectedItemId,
        baseV,
      ],
      queryFn: () =>
        designControlsApi.getById(
          companyId,
          productId,
          selectedItemId,
          baseV?.version,
        ),
      enabled: !!baseV?.version,
    },
    {
      queryKey: [
        "DESIGN_ELEMENT_BY_ID",
        companyId,
        productId,
        selectedItemId,
        compareV,
      ],
      queryFn: () =>
        designControlsApi.getById(
          companyId,
          productId,
          selectedItemId,
          compareV?.version,
        ),
      enabled: !!compareV?.version,
    },
  ]);

  return useMemo(() => {
    return {
      isLoading: queries.some((query) => query.isLoading),
      baseElement: queries[0].data,
      compareElement: queries[1].data,
      base: baseV,
      compare: compareV,
    };
  }, [queries, baseV, compareV]);
};
