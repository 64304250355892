import { FMEARiskDesignElement } from "@design-controls/types";
import { z } from "zod";
import { riskScore } from "../../domain/fmeaRequiresControl";

export const riskToForm = (risk: FMEARiskDesignElement): FMEARiskForm => {
  return {
    ...risk,
    sourceRequirements: risk.sourceItems.filter((item) =>
      item.type.startsWith("req"),
    ),
    sourceTestCases: risk.sourceItems.filter((item) =>
      item.type.startsWith("testCase"),
    ),
    mitigatesRequirements: risk.mitigationItems.filter((item) =>
      item.type.startsWith("testCase"),
    ),
    mitigatesTestCases: risk.mitigationItems.filter((item) =>
      item.type.startsWith("testCase"),
    ),
  };
};

export const formToRisk = (
  formData: FMEARiskForm,
): Omit<
  FMEARiskDesignElement,
  "updated" | "created" | "source" | "itemStatus" | "changeControlStatus"
> => {
  const mitigation = ({
    severity,
    occurrence,
    detectability,
  }: {
    severity?: string;
    occurrence?: string;
    detectability?: string;
  }) => {
    if (!severity || !occurrence || !detectability) {
      return;
    }

    return {
      severity,
      occurrence,
      detectability,
      riskLevel: riskScore(severity, detectability, occurrence),
    };
  };

  const preMitigation = mitigation(formData.preMitigation);
  const postMitigation = mitigation(formData.postMitigation ?? {});

  return {
    id: formData.id,
    type: formData.type,
    subType: formData.subType,
    title: formData.title,
    category: formData.category?.qri ? formData.category : undefined,
    riskControl: !!formData.riskControl?.mitigation
      ? formData.riskControl
      : undefined,
    failureCauses: formData.failureCauses,
    failureModeEffects: formData.failureModeEffects,
    failureMode: formData.failureMode,
    evaluationMethod: formData.evaluationMethod,
    sourceItems: [
      ...(formData.sourceRequirements ?? []),
      ...(formData.sourceTestCases ?? []),
    ].map((item) => item.id),
    mitigationItems: [
      ...(formData.mitigatesRequirements ?? []),
      ...(formData.mitigatesTestCases ?? []),
    ].map((item) => item.id),
    preMitigation,
    postMitigation,
  };
};

export const FMEARiskForm = z
  .object({
    sourceRequirements: FMEARiskDesignElement.shape.sourceItems.optional(),
    sourceTestCases: FMEARiskDesignElement.shape.sourceItems.optional(),
    mitigatesRequirements:
      FMEARiskDesignElement.shape.mitigationItems.optional(),
    mitigatesTestCases: FMEARiskDesignElement.shape.mitigationItems.optional(),
  })
  .merge(
    FMEARiskDesignElement.omit({
      sourceItems: true,
      mitigationItems: true,
    }),
  );
export type FMEARiskForm = z.infer<typeof FMEARiskForm>;
