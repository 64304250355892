import { RequirementPolicies } from "@design-controls/types";
import { useProductStore } from "./useProductStore";

export const useRequirementModal = () =>
  useProductStore((state) => ({
    isOpen: state.requirementModal,
    openModal: state.openRequirementModal,
    closeModal: state.closeRequirementModal,
    type: state.type as RequirementPolicies["type"],
  }));
