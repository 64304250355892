import { z } from "zod";
import { LinkedElement } from "./designElement";

export enum ReviewStatusEnum {
  IN_PROGRESS = "IN_PROGRESS",
  SENT_FOR_APPROVAL = "SENT_FOR_APPROVAL",
  APPROVED = "APPROVED",
  NOT_APPROVED = "NOT_APPROVED",
}

export const ReviewStatus = z.nativeEnum(ReviewStatusEnum);
export type ReviewStatus = z.infer<typeof ReviewStatus>;

export const ReviewList = z.object({
  code: z.string(),
  created: z.string(),
  createdBy: z.object({
    fullName: z.string(),
    id: z.string(),
  }),
  description: z.string().nullish(),
  id: z.string(),
  status: ReviewStatus,
  updated: z.string(),
  title: z.string(),
});
export type ReviewList = z.infer<typeof ReviewList>;

export const ReviewRecord = z
  .object({
    qualityItems: LinkedElement.merge(
      z.object({
        latest: z.string().optional(),
        version: z.string().optional(),
      }),
    )
      .array()
      .max(300, "Maximum of 300 design elements"),
    approvers: z
      .object({
        status: ReviewStatus.optional(),
        fullName: z.string().optional(),
        id: z.string(),
        modified: z.string().nullish(),
        note: z.string().nullish(),
      })
      .array(),
    linkedDocuments: z
      .object({
        id: z.string(),
        title: z.string(),
        uuid: z.string().uuid(),
        code: z.string(),
        status_id: z.string(),
        qri: z.string(),
      })
      .array(),
    releaseId: z.string().optional(),
  })
  .merge(ReviewList);
export type ReviewRecord = z.infer<typeof ReviewRecord>;

export enum ReleaseStatusEnum {
  PROCESSING = "processing",
  COMPLETED = "completed",
  ERROR = "error",
}

export const ReleaseStatus = z.nativeEnum(ReleaseStatusEnum);
export type ReleaseStatus = z.infer<typeof ReleaseStatus>;

export const ReleaseRecord = z.object({
  createdAt: z.string(),
  name: z.string(),
  description: z.string().nullish(),
  changeControlId: z.string(),
  id: z.string(),
  reviewPackageAvailable: z.boolean().nullish(),
  version: z.string(),
  status: ReleaseStatus,
});
export type ReleaseRecord = z.infer<typeof ReleaseRecord>;

export const ReleaseReport = z.object({
  reports: z
    .object({
      url: z.string().nullish(),
      name: z.string().nullish(),
      status: ReleaseStatus,
      type: z.string(),
    })
    .array(),
});
export type ReleaseReport = z.infer<typeof ReleaseReport>;

export const Approval = z.object({
  status: z.union([
    z.literal(ReviewStatus.enum.APPROVED),
    z.literal(ReviewStatus.enum.NOT_APPROVED),
  ]),
  note: z.string().max(750, "Note too long").optional(),
  signature: z.object({
    email: z.string(),
    password: z.string().min(1, "Password too short"),
  }),
});
export type Approval = z.infer<typeof Approval>;

export const ReviewHistory = z.object({
  id: z.string(),
  code: z.string(),
  status: ReviewStatus,
  version: z.string(),
  title: z.string(),
  date: z.string(),
});
export type ReviewHistory = z.infer<typeof ReviewHistory>;
