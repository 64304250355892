import { QStack, QText } from "@qualio/ui-components";
import React, { useMemo } from "react";
import { ReviewApproval } from "./ReviewApproval";
import {
  isDesignElementRequirement,
  isDesignElementRisk,
  isDesignElementTestCase,
  ReleaseReport,
  ReviewRecord,
} from "@design-controls/types";
import { sanitize } from "dompurify";
import { ReportsTable } from "../../../components/OverviewTable/ReportsTable";
import { RequirementTable } from "../../../components/OverviewTable/RequirementTable";
import { RisksTable } from "../../../components/OverviewTable/RisksTable";
import { TestCaseTable } from "../../../components/OverviewTable/TestCaseTable";
import { LinkedDocumentsTable } from "../../../components/OverviewTable/LinkedDocumentsTable";

type Props = {
  canApprove: boolean;
  data: ReviewRecord;
  reportData?: ReleaseReport;
  isComplete: boolean;
  hasOutdatedDesignElements?: boolean;
};

export const ReviewDetail: React.FC<Props> = ({
  canApprove,
  data,
  reportData,
  isComplete,
  hasOutdatedDesignElements,
}) => {
  const requirements = useMemo(() => {
    return (data?.qualityItems ?? []).filter(isDesignElementRequirement);
  }, [data]);
  const testCases = useMemo(() => {
    return (data?.qualityItems ?? []).filter(isDesignElementTestCase);
  }, [data]);
  const risks = useMemo(() => {
    return (data?.qualityItems ?? []).filter(isDesignElementRisk);
  }, [data]);

  return (
    <QStack gap="24px">
      {canApprove && <ReviewApproval />}
      <QStack gap="8px">
        <QText fontSize="sm" fontWeight={600} color="gray.900">
          Description
        </QText>
        {data.description && (
          <QText
            fontSize={14}
            style={{ wordBreak: "break-word" }}
            dangerouslySetInnerHTML={{
              __html: sanitize(data.description),
            }}
          />
        )}
      </QStack>

      <ReportsTable data={reportData} isComplete={isComplete} />
      <RequirementTable
        requirements={requirements}
        underReview={hasOutdatedDesignElements}
        versioned
      />
      <RisksTable
        risks={risks}
        underReview={hasOutdatedDesignElements}
        versioned
      />
      <TestCaseTable
        testCases={testCases}
        underReview={hasOutdatedDesignElements}
        versioned
      />
      <LinkedDocumentsTable documents={data.linkedDocuments} />
    </QStack>
  );
};
