import { useCurrentUser } from "@qualio/ui-components";
import { useQueries } from "react-query";
import { useCurrentProduct } from "../useCurrentProduct";
import { designControlsApi } from "../../api/designControls";
import {
  ReleaseRecord,
  ReleaseReport,
  ReleaseStatus,
} from "@design-controls/types";
import { useCallback, useMemo, useState } from "react";
import { downloadFile } from "../../../lib/downloadFile";

export const useRetrieveGeneratedDocuments = (releaseId?: string) => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();
  const [refetchCount, setRefetchCount] = useState(0);

  const queries = useQueries([
    {
      queryFn: () =>
        designControlsApi.retrieveGeneratedReports(
          companyId,
          productId,
          releaseId as string,
        ),
      queryKey: [
        "RELEASE_GENERATED_DOCUMENTS",
        companyId,
        productId,
        releaseId,
      ],
      refetchInterval: (data: ReleaseReport | undefined) => {
        const isCompleted = data?.reports.every(
          (report) => report.status === ReleaseStatus.enum.COMPLETED,
        );
        const isErrored = data?.reports.some(
          (report) => report.status === ReleaseStatus.enum.ERROR,
        );

        return isCompleted || isErrored || refetchCount > 20 ? false : 30_000;
      },
      onSettled: () => setRefetchCount((s) => s + 1),
      enabled: !!releaseId,
    },
    {
      queryFn: () =>
        designControlsApi.retrieveRelease(
          companyId,
          productId,
          releaseId as string,
        ),
      queryKey: ["RELEASE", companyId, productId, releaseId],
      refetchInterval: (data: ReleaseRecord | undefined) => {
        const isCompleted = data?.status === ReleaseStatus.enum.COMPLETED;
        const isErrored = data?.status === ReleaseStatus.enum.ERROR;
        return isCompleted || isErrored || refetchCount > 20 ? false : 30_000;
      },
      enabled: !!releaseId,
    },
  ]);

  const downloadExportPacket = useCallback(() => {
    if (!releaseId) {
      return;
    }

    downloadFile(
      designControlsApi.downloadReviewPackageUrl(
        companyId,
        productId,
        releaseId,
      ),
    );
  }, [companyId, productId, releaseId, downloadFile]);

  return useMemo(() => {
    const isLoading = queries.some((query) => query.isLoading);
    const release = queries[1].data;
    const data = queries[0].data;

    const isComplete = !!(
      data?.reports.every(
        (report) => report.status === ReleaseStatus.enum.COMPLETED,
      ) && release?.status === ReleaseStatus.enum.COMPLETED
    );

    const isError =
      queries.some((query) => query.isError) ||
      data?.reports.some(
        (report) => report.status === ReleaseStatus.enum.ERROR,
      );

    return {
      isLoading,
      isError,
      isComplete,
      downloadExportPacket,
      data,
    };
  }, [queries, downloadExportPacket]);
};
