import React, { useMemo } from "react";

import { QIconButton, QText } from "@qualio/ui-components";
import ReactTooltip from "react-tooltip";

import { ChipContainer } from "./Chip.styles";

interface ChipProps {
  text: string;
  onClick?: () => void;
}

const Chip: React.FC<ChipProps> = ({ text, onClick }) => {
  const singleLineText = useMemo(() => text.replace(/\n/g, " "), [text]);

  return (
    <ChipContainer>
      <div>
        <QText
          fontSize="sm"
          data-tip=""
          data-for={singleLineText}
          data-type="info"
          data-effect="solid"
        >
          {text}
        </QText>
        <QIconButton
          iconName={"XCircle"}
          aria-label={"Remove"}
          onClick={onClick}
          data-cy={`remove-${singleLineText}`}
        />
      </div>
      <ReactTooltip className="tooltip" id={singleLineText} multiline={true}>
        {text}
      </ReactTooltip>
    </ChipContainer>
  );
};

export default Chip;
