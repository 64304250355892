import { useCurrentUser, useToastProvider } from "@qualio/ui-components";
import { useCurrentProduct } from "../useCurrentProduct";
import { useMutation, useQueryClient } from "react-query";
import { designControlsApi } from "../../api/designControls";
import { ReviewRecord } from "@design-controls/types";

export const useCreateReview = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();
  const { showToast } = useToastProvider();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (review: Partial<ReviewRecord>) =>
      designControlsApi.createReview(companyId, productId, review),
    onSuccess: async () => {
      showToast({
        title: "Success.",
        description: "Successfully added design review.",
        status: "success",
        duration: 5000,
      });
      await queryClient.invalidateQueries([
        "REVIEW_LIST",
        companyId,
        productId,
      ]);
    },
    onError: () => {
      showToast({
        title: "Error.",
        description: "Unable to add design review.",
        status: "error",
        duration: 5000,
      });
    },
  });
};
