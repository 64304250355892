import { ElementList, Policy } from "@design-controls/types";
import { PolicyIssueEnum } from "@design-controls/types/src/openIssue";
import { QStack, QTag, QText, QTooltip } from "@qualio/ui-components";
import React from "react";
import { useCurrentConfigs } from "../../hooks/useCurrentConfigs";

type Props = {
  openIssues: Partial<Record<PolicyIssueEnum, boolean>>;
  type: ElementList["type"];
  onlyIssues?: boolean;
};

const validTypes: Partial<Record<ElementList["type"], PolicyIssueEnum[]>> = {
  req1: [
    PolicyIssueEnum.REQUIRES_PARENT,
    PolicyIssueEnum.REQUIRES_CHILD,
    PolicyIssueEnum.UNTESTED_REQUIREMENT,
  ],
  req2: [
    PolicyIssueEnum.REQUIRES_PARENT,
    PolicyIssueEnum.REQUIRES_CHILD,
    PolicyIssueEnum.UNTESTED_REQUIREMENT,
  ],
  req3: [
    PolicyIssueEnum.REQUIRES_PARENT,
    PolicyIssueEnum.REQUIRES_CHILD,
    PolicyIssueEnum.UNTESTED_REQUIREMENT,
  ],
  req4: [PolicyIssueEnum.REQUIRES_PARENT],
  testCase1: [
    PolicyIssueEnum.REQUIRES_PARENT,
    PolicyIssueEnum.FAILING_TEST,
    PolicyIssueEnum.OUTDATED_TESTRESULT,
  ],
  testCase2: [
    PolicyIssueEnum.REQUIRES_PARENT,
    PolicyIssueEnum.FAILING_TEST,
    PolicyIssueEnum.OUTDATED_TESTRESULT,
  ],
  testCase3: [
    PolicyIssueEnum.REQUIRES_PARENT,
    PolicyIssueEnum.FAILING_TEST,
    PolicyIssueEnum.OUTDATED_TESTRESULT,
  ],
  risk: [PolicyIssueEnum.UNMITIGATED],
};

const tableCode: Record<PolicyIssueEnum, string> = {
  [PolicyIssueEnum.FAILING_TEST]: "F",
  [PolicyIssueEnum.REQUIRES_CHILD]: "C",
  [PolicyIssueEnum.REQUIRES_PARENT]: "P",
  [PolicyIssueEnum.UNTESTED_REQUIREMENT]: "T",
  [PolicyIssueEnum.UNMITIGATED]: "M",
  [PolicyIssueEnum.OUTDATED_TESTRESULT]: "D",
};

const requiresChild: Partial<Record<Policy["type"], string>> = {
  req1: "req2",
  req2: "req3",
  req3: "req4",
} as const;

const requiresParent: Partial<Record<Policy["type"], string>> = {
  req2: "req1",
  req3: "req2",
  req4: "req3",
  testCase1: "req1",
  testCase2: "req2",
  testCase3: "req3",
} as const;

const openIssueTypeText = (
  policyIssue: PolicyIssueEnum,
  type: Policy["type"],
  policies: Policy[],
  hasGap: boolean,
) => {
  switch (policyIssue) {
    case "UNTESTED_REQUIREMENT":
      return hasGap ? "Missing test case" : "Requirement tested";
    case "UNMITIGATED":
      return hasGap ? "Missing mitigation" : "Mitigated risk";
    case "REQUIRES_CHILD":
      return `${hasGap ? "Missing" : "Has"} ${policies.find((policy) => policy.type === requiresChild[type])?.label ?? ""}`;
    case "REQUIRES_PARENT":
      return `${hasGap ? "Missing" : "Has"} ${policies.find((policy) => policy.type === requiresParent[type])?.label ?? ""}`;
    case "FAILING_TEST":
      return hasGap ? "Failing test" : "Test passed";
    case "OUTDATED_TESTRESULT":
      return hasGap ? "Outdated test result" : "Up to date result";
    default:
      return "";
  }
};

export const GapTableCell: React.FC<Props> = ({
  openIssues,
  type,
  onlyIssues,
}) => {
  const policies = useCurrentConfigs();

  const tags = Object.entries(openIssues)
    .filter(([key]) => validTypes[type]?.includes(key as PolicyIssueEnum))
    .sort(
      (a, b) =>
        (validTypes[type] ?? []).findIndex((aType) => aType == a[0]) -
        (validTypes[type] ?? []).findIndex((bType) => bType == b[0]),
    );

  return (
    <QStack direction="row">
      {tags.map(([key, value]) => {
        if (
          !onlyIssues &&
          type === "req1" &&
          key === PolicyIssueEnum.REQUIRES_PARENT
        ) {
          return (
            <div style={{ visibility: "hidden" }} key={`${key}-${type}`}>
              <QTag variantColor="green" data-cy={`${key}-ok`}>
                {tableCode[key as PolicyIssueEnum]}
              </QTag>
            </div>
          );
        }

        if (!!value) {
          return (
            <QTooltip
              key={`${key}-${type}`}
              label={
                <QText>
                  {openIssueTypeText(
                    key as PolicyIssueEnum,
                    type,
                    policies,
                    value,
                  )}
                </QText>
              }
            >
              <QTag variantColor="yellow" data-cy={`${key}-error`}>
                {tableCode[key as PolicyIssueEnum]}
              </QTag>
            </QTooltip>
          );
        }

        if (!onlyIssues) {
          return (
            <QTooltip
              key={`${key}-${type}`}
              label={
                <QText>
                  {openIssueTypeText(
                    key as PolicyIssueEnum,
                    type,
                    policies,
                    value,
                  )}
                </QText>
              }
            >
              <QTag variantColor="green" data-cy={`${key}-ok`}>
                {tableCode[key as PolicyIssueEnum]}
              </QTag>
            </QTooltip>
          );
        }

        return null;
      })}
    </QStack>
  );
};
