import { QTag, QTagProps } from "@qualio/ui-components";
import React from "react";
import { ReviewStatus } from "@design-controls/types";

type Props = {
  status: ReviewStatus;
};

export const ReviewStatusTag: React.FC<Props> = ({ status }) => {
  const variantColor: Record<ReviewStatus, QTagProps["variantColor"]> = {
    [ReviewStatus.enum.IN_PROGRESS]: "gray",
    [ReviewStatus.enum.SENT_FOR_APPROVAL]: "blue",
    [ReviewStatus.enum.APPROVED]: "green",
    [ReviewStatus.enum.NOT_APPROVED]: "red",
  };

  const label: Record<ReviewStatus, string> = {
    [ReviewStatus.enum.IN_PROGRESS]: "In progress",
    [ReviewStatus.enum.SENT_FOR_APPROVAL]: "For approval",
    [ReviewStatus.enum.APPROVED]: "Approved",
    [ReviewStatus.enum.NOT_APPROVED]: "Declined",
  };

  return <QTag variantColor={variantColor[status]}>{label[status]}</QTag>;
};
