import React from "react";
import { QButton, QPageLoader, QSpacer, QStack } from "@qualio/ui-components";
import { useTraceability } from "../../hooks/designElement/useTraceability";
import { useDownloadTraceability } from "../../hooks/designElement/useDownloadTraceability";
import { TraceabilityMatrix } from "../../components/TraceabilityMatrix/TraceabilityMatrix";

export const Traceability: React.FC = () => {
  const { isLoading, data: traceabilityMatrix } = useTraceability();
  const { onDownloadFile } = useDownloadTraceability();

  if (isLoading || !traceabilityMatrix) {
    return <QPageLoader />;
  }

  return (
    <TraceabilityMatrix matrix={traceabilityMatrix}>
      <QStack direction="row">
        <QSpacer />
        <QButton
          variant="outline"
          onClick={onDownloadFile}
          data-cy="download-matrix"
        >
          Download traceability matrix
        </QButton>
      </QStack>
    </TraceabilityMatrix>
  );
};
