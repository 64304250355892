import { isDesignElementISORisk } from "@design-controls/types";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRetrieveDesignElementById } from "../../hooks/useRetrieveDesignElementById";
import { QNotFound, QPageLoader } from "@qualio/ui-components";
import { SelectedElement } from "../../types/router";
import { Form } from "./components/Form";
import { riskToForm } from "./validation";
import { isSoureEditableAndDeletable } from "../../domain/editableSource";

export const EditISORisk: React.FC = () => {
  const navigate = useNavigate();
  const { selectedItemId } = useParams<SelectedElement>() as SelectedElement;
  const { isLoading, data } = useRetrieveDesignElementById(selectedItemId);

  const onClose = useCallback(() => navigate("../risk/"), [navigate]);

  if (isLoading) {
    return <QPageLoader />;
  }

  if (
    !data ||
    !isDesignElementISORisk(data) ||
    !isSoureEditableAndDeletable(data.source)
  ) {
    return <QNotFound data-cy="not-found" />;
  }

  return <Form risk={riskToForm(data)} onClose={onClose} />;
};
