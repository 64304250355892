import {
  DashboardItem,
  ChangeControl,
  ChangeControlPayloadType,
} from "../types/changeControl";
import { Payload } from "../types/payload";
import { Approval } from "../views/ChangeControlDetails/types";
import api from "./index";

export type SaveReviewPayload = Pick<
  ChangeControl,
  "title" | "description" | "qualityItems" | "approvers" | "linkedDocuments"
>;

const changeControlApi = {
  retrieveUnstaged(company: number, product: string): Promise<DashboardItem[]> {
    return api
      .get(
        `/company/${company}/product-development/product/${product}/change-control/quality-item`,
        {
          params: { status: "unstaged" },
        },
      )
      .then((res) => res.data as Payload<DashboardItem[]>)
      .then((res) => res.data);
  },
  retrieveDashboard(
    company: number,
    product: string,
  ): Promise<DashboardItem[]> {
    return api
      .get(
        `/company/${company}/product-development/product/${product}/change-control/quality-item`,
        {
          params: { "status.gte": "staged" },
        },
      )
      .then((res) => res.data as Payload<DashboardItem[]>)
      .then((res) => res.data);
  },
  createReview(
    company: number,
    product: string,
    reviewPayload?: ChangeControlPayloadType,
  ): Promise<ChangeControl> {
    return api
      .post(
        `/company/${company}/product-development/product/${product}/change-control`,
        {
          data: reviewPayload
            ? { ...reviewPayload, category: "Change Control" }
            : {},
        },
      )
      .then((res) => res.data as Payload<ChangeControl>)
      .then((res) => res.data);
  },
  retrieveChangeControlRecord(
    company: number,
    product: string,
    changeControlId: string,
  ): Promise<ChangeControl | undefined> {
    return api
      .get(
        `/company/${company}/product-development/product/${product}/change-control/${changeControlId}`,
      )
      .then((res) => res.data as Payload<ChangeControl>)
      .then((res) => res.data);
  },
  sendForApproval(
    company: number,
    product: string,
    changeControlId: string,
  ): Promise<void> {
    return api.post(
      `/company/${company}/product-development/product/${product}/change-control/${changeControlId}/send-for-approval`,
    );
  },
  revertToDraft(
    company: number,
    product: string,
    changeControlId: string,
  ): Promise<void> {
    return api.post(
      `/company/${company}/product-development/product/${product}/change-control/${changeControlId}/revert-to-draft`,
    );
  },
  saveReview(
    company: number,
    product: string,
    review: string,
    payload: SaveReviewPayload | ChangeControlPayloadType,
  ): Promise<void> {
    return api
      .put(
        `/company/${company}/product-development/product/${product}/change-control/${review}`,
        {
          data: { ...payload, category: "Change Control" },
        },
      )
      .then(() => undefined);
  },
  deleteReview(
    company: number,
    product: string,
    review: string,
  ): Promise<void> {
    return api
      .delete(
        `/company/${company}/product-development/product/${product}/change-control/${review}`,
      )
      .then(() => undefined);
  },
  query(company: number, product: string): Promise<ChangeControl[]> {
    return api
      .get(
        `/company/${company}/product-development/product/${product}/change-control`,
      )
      .then((res) => res.data as Payload<ChangeControl[]>)
      .then((res) => res.data);
  },
  approve(
    company: number,
    product: string,
    changeControlId: string,
    approval: Approval,
  ): Promise<ChangeControl> {
    return api
      .post(
        `/company/${company}/product-development/product/${product}/change-control/${changeControlId}/approve`,
        {
          data: approval,
        },
      )
      .then((res) => res.data as Payload<ChangeControl>)
      .then((res) => res.data);
  },
};

export default changeControlApi;
