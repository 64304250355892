import React from "react";

import { QFormControl, QMultiSelect } from "@qualio/ui-components";
import { Controller } from "react-hook-form";
import { SearchResponse } from "../types/globalSearch";

type Props = {
  categories: SearchResponse["results"];
  isLoading: boolean;
};

export const CategoryFilter: React.FC<Props> = ({ categories, isLoading }) => {
  return (
    <Controller
      name="category"
      render={({ field: { onChange, value } }) => (
        <QFormControl label="Category">
          <QMultiSelect
            value={value}
            onChange={(selectedItem) =>
              onChange(selectedItem.map((item) => item.value))
            }
            options={categories.map((item) => ({
              value: item.identifiers.qri,
              label: item.displayLabel,
            }))}
            isLoading={isLoading}
          />
        </QFormControl>
      )}
    />
  );
};
