import { useCurrentUser, useToastProvider } from "@qualio/ui-components";
import { useCurrentProduct } from "../useCurrentProduct";
import { useMutation, useQueryClient } from "react-query";
import { designControlsApi } from "../../api/designControls";
import { ReviewRecord } from "@design-controls/types";

export const useUpdateReview = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();
  const { showToast } = useToastProvider();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (review: Partial<ReviewRecord>) =>
      designControlsApi.updateReview(
        companyId,
        productId,
        review.id as string,
        review,
      ),
    onSuccess: async ({ id: reviewId }) => {
      showToast({
        title: "Success.",
        description: "Successfully updated design review.",
        status: "success",
        duration: 5000,
      });

      return Promise.all([
        queryClient.invalidateQueries(["REVIEW_LIST", companyId, productId]),
        queryClient.invalidateQueries([
          "REVIEW",
          companyId,
          productId,
          reviewId,
        ]),
      ]);
    },
    onError: () => {
      showToast({
        title: "Error.",
        description: "Unable to add design review.",
        status: "error",
        duration: 5000,
      });
    },
  });
};
