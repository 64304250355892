import React, {useCallback, useMemo, useState} from 'react';

import { Product } from '@design-controls/types';
import {
  QTbody,
  QSelectItem,
  QTable,
  QTh,
  QThead,
  QTr,
  QIconButton,
  QText, QStack
} from '@qualio/ui-components';

import { FeatureFlags } from '../../../components/FeatureToggle/FeatureToggle.enum';
import useFeatureFlag from '../../../components/FeatureToggle/hooks/useFeatureFlag';
import { TestCaseTrace } from '../../../types/trace';
import { ResultValueToLabel } from '../testResultValueToLabel';
import TestResultRow from './TestResultRow';

type TestResultTableProps = {
  product: Product;
  testCases: Array<TestCaseTrace>;
  onRowClick?: (testCaseId: string) => void;
  selectedItemId?: string;
};

const ROWS_PER_PAGE = 20;

const TestResultTable: React.FC<TestResultTableProps> = ({ product, testCases, onRowClick, selectedItemId }) => {
  const resultOptions: Array<QSelectItem> = useMemo(() => {
    return Object.entries(ResultValueToLabel).map(([key, value]) => ({
      label: value,
      value: key,
    }));
  }, []);

  const features = useFeatureFlag([FeatureFlags.DESIGN_CONTROLS_CATEGORIES]);
  const thStyle = { textAlign: 'left' as const };



  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = useMemo( () => Math.ceil(testCases.length / ROWS_PER_PAGE), [testCases.length] )

  const goToPreviousPage = useCallback(() => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  }, []);

  const goToNextPage = useCallback(() => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  }, [totalPages]);

  const paginatedTestCases = useMemo(() => {
    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    return testCases.slice(startIndex, startIndex + ROWS_PER_PAGE);
  }, [testCases, currentPage]);


  return (
    <>
      <QTable>
        <QThead>
          <QTr>
            <QTh width="95px" style={thStyle} key={'Header_ID'}>
              ID
            </QTh>
            <QTh width="30%" style={thStyle} key={'Header_Title'}>
              Test case title
            </QTh>

            {features[FeatureFlags.DESIGN_CONTROLS_CATEGORIES] && (
              <QTh width="105px" style={thStyle} key={'Header_Category'}>
                Category
              </QTh>
            )}
            <QTh width="105px" style={thStyle} key={'Header_Result'}>
              Result
            </QTh>
            <QTh width="180px" style={thStyle} key={'Header_Responsible'}>
              Responsible
            </QTh>
            <QTh style={thStyle} key={'Header_Note'}>
              Note
            </QTh>
            <QTh width="38px" style={thStyle} key={'Header_Empty1'} />
            <QTh width="60px" style={thStyle} key={'Header_Empty2'} />
          </QTr>
        </QThead>
        <QTbody
          onClick={(event: any) => {
            if (event.nativeEvent.target instanceof Element) {
              const drawerValue =
                event.nativeEvent.target.getAttribute('data-drawer') ||
                (event.nativeEvent.target.parentElement as HTMLElement).getAttribute('data-drawer');

              if (drawerValue && onRowClick) {
                onRowClick(drawerValue);
              }
            }
          }}
          data-cy={'result-records'}
        >
          {paginatedTestCases.map((testCase) => (
            <TestResultRow
              productId={product.id}
              options={resultOptions}
              key={testCase.id}
              testCase={testCase}
              selectedItemId={selectedItemId}
            />
          ))}
        </QTbody>
      </QTable>
      <QStack direction="row" justifyContent="right" padding={2}>
        <QIconButton
            iconName="ChevronLeft"
            isDisabled={currentPage === 1}
            onClick={goToPreviousPage}
            aria-label="xs"
        />
        <QStack paddingTop="5px" direction="row">
          <QText>Page</QText>
          <QText weight="semibold">{currentPage} </QText>
          <QText> of {totalPages}</QText>
        </QStack>
        <QIconButton
            iconName="ChevronRight"
            isDisabled={currentPage === totalPages}
            onClick={goToNextPage}
            aria-label="xs"
        />
      </QStack>

    </>
  );
};

export default TestResultTable;
