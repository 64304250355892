import React from "react";

import { QCheckbox, QTag } from "@qualio/ui-components";
import { Controller } from "react-hook-form";

type Props = {
  type: "requirement" | "testCase" | "risk";
};

export const ShowOnlyOpen: React.FC<Props> = ({ type }) => {
  if (type === "requirement") {
    return (
      <>
        <Controller
          name="show_required_parent"
          render={({ field: { value, onChange } }) => (
            <QCheckbox
              isChecked={value}
              onChange={onChange}
              data-cy="show_required_parent"
            >
              <QTag variantColor="yellow">P</QTag> Show missing parent
            </QCheckbox>
          )}
        />
        <Controller
          name="show_required_child"
          render={({ field: { value, onChange } }) => (
            <QCheckbox
              isChecked={value}
              onChange={onChange}
              data-cy="show_required_child"
            >
              <QTag variantColor="yellow">C</QTag> Show missing child
            </QCheckbox>
          )}
        />
        <Controller
          name="show_untested_requirement"
          render={({ field: { value, onChange } }) => (
            <QCheckbox
              isChecked={value}
              onChange={onChange}
              data-cy="show_untested_requirement"
            >
              <QTag variantColor="yellow">T</QTag> Show untested requirement
            </QCheckbox>
          )}
        />
      </>
    );
  }

  if (type === "testCase") {
    return (
      <>
        <Controller
          name="show_required_parent"
          render={({ field: { value, onChange } }) => (
            <QCheckbox
              isChecked={value}
              onChange={onChange}
              data-cy="show_required_parent"
            >
              <QTag variantColor="yellow">P</QTag> Show missing parent
            </QCheckbox>
          )}
        />
        <Controller
          name="show_failing"
          render={({ field: { value, onChange } }) => (
            <QCheckbox
              isChecked={value}
              onChange={onChange}
              data-cy="show_failing"
            >
              <QTag variantColor="yellow">F</QTag> Show failing test case
            </QCheckbox>
          )}
        />
        <Controller
          name="show_outdated"
          render={({ field: { value, onChange } }) => (
            <QCheckbox
              isChecked={value}
              onChange={onChange}
              data-cy="show_outdated"
            >
              <QTag variantColor="yellow">D</QTag> Show outdated result
            </QCheckbox>
          )}
        />
      </>
    );
  }

  return (
    <Controller
      name="show_unmitigated"
      render={({ field: { value, onChange } }) => (
        <QCheckbox
          isChecked={value}
          onChange={onChange}
          data-cy="show_unmitigated"
        >
          <QTag variantColor="yellow">M</QTag> Show unmitigated risk
        </QCheckbox>
      )}
    />
  );
};
