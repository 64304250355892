import React, { useCallback, useMemo, useState } from "react";

import {
  FMEARiskList as FMEARiskListType,
  isRiskPolicy,
} from "@design-controls/types";
import {
  createQColumnHelper,
  DataProvider,
  Filtering,
  Pagination,
  QDataTable,
  QDeleteConfirmationModal,
  QText,
  Sorting,
  TableMenuItem,
} from "@qualio/ui-components";
import { Outlet, useNavigate } from "react-router";

import { usePolicyGroupResourceQuickSearch } from "../../../hooks/qri/usePolicyGroupResourceQuickSearch";
import { CategoryFilter } from "../../filter/CategoryFilter";
import { elementStatus, ElementStatusTag } from "../../domain/elementStatus";
import { useDeleteRisk } from "../../hooks/designElement/useDeleteRisk";
import { useFMEARiskList } from "../../../hooks/useFMEARiskList";
import { GapTableCell } from "../../components/GapTableCell/GapTableCell";
import { ShowOnlyOpen } from "../../filter/ShowOnlyOpen";

const columnHelper = createQColumnHelper<FMEARiskListType>();

export const FMEARiskList = () => {
  const { isLoading, data } = useFMEARiskList();
  const [itemToDelete, setItemToDelete] = useState<FMEARiskListType | null>(
    null,
  );
  const { isLoading: isDeleting, mutate: deleteElement } = useDeleteRisk();
  const navigate = useNavigate();

  const { isLoading: categoryIsLoading, data: categories } =
    usePolicyGroupResourceQuickSearch(isRiskPolicy);

  const filterDefinitions = useMemo(() => {
    return {
      category: {
        label: "Category",
        schema: Filtering.schemas.StringSchema().array().nullish(),
        activeRender: (v: any) => {
          const item = categories?.find((c) => c.identifiers.qri === v);
          if (!item) {
            return;
          }

          return {
            label: "Category:",
            value: item.displayLabel,
          };
        },
      },
      show_unmitigated: {
        label: "Show unmitigated",
        schema: Filtering.schemas.BooleanSchema().nullish(),
        activeRender: (v: any) => ({
          label: null,
          value: v && "Unmitigated",
        }),
      },
    };
  }, [categories]);

  const filterOption = useCallback(
    (
      item: Readonly<FMEARiskListType>,
      searchTerm: string | undefined,
      filters: Filtering.ResolvedFilters<typeof filterDefinitions> | undefined,
    ) => {
      if (
        searchTerm &&
        !item.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
      ) {
        return false;
      }

      if (filters && Object.values(filters).some((field) => field.value)) {
        const { category } = filters;

        return !category?.value || category.value.includes(item.category?.qri);
      }

      return true;
    },
    [],
  ) as DataProvider.FilterOptionFn<FMEARiskListType>;

  const columns = useMemo(() => {
    return [
      columnHelper.code("code", { header: "ID", maxWidth: "100px" }),
      {
        header: " ",
        meta: {
          width: "40px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: FMEARiskListType };
        }) => <GapTableCell openIssues={row.policyIssues} type={row.type} />,
      },
      columnHelper.textLink("title", (record) => record.id, {
        header: "Risk name",
        maxWidth: "400px",
      }),
      columnHelper.text((record) => record.category?.label, {
        header: "Category",
        maxWidth: "200px",
      }),
      columnHelper.date((record) => new Date(record.updated), {
        header: "Last modified",
        id: "updated",
        maxWidth: "150px",
      }),
      columnHelper.status(elementStatus, {
        header: "Status",
        statuses: ElementStatusTag,
      }),
      columnHelper.menu({
        items: (
          <>
            <TableMenuItem
              onClick={(item: FMEARiskListType) => navigate(`${item.id}/edit`)}
              data-cy="edit"
            >
              Edit
            </TableMenuItem>
            <TableMenuItem
              onClick={(item: FMEARiskListType) => setItemToDelete(item)}
              color="red.500"
              data-cy="delete"
            >
              Delete
            </TableMenuItem>
          </>
        ),
        hide: (record) => record.source !== "product-development",
      }),
    ];
  }, []);

  return (
    <>
      <Pagination.Auto pageSizeKey="size" pageIndexKey="page" clientSide>
        <Sorting.DefaultSortingProvider
          initialSort={{ column: "updated", descending: true }}
        >
          <Filtering.FilterProvider
            definitions={filterDefinitions}
            searchTermKey="search"
          >
            <DataProvider.Fixed
              data={data ?? []}
              isLoading={isLoading}
              filterOption={filterOption}
            >
              <QDataTable
                columns={columns}
                data-cy="fmea-risk-list"
                getRowId={(row) => row.id}
              >
                <Filtering.FormContent>
                  <CategoryFilter
                    categories={categories}
                    isLoading={categoryIsLoading}
                  />
                  <ShowOnlyOpen type="risk" />
                </Filtering.FormContent>
              </QDataTable>
            </DataProvider.Fixed>
          </Filtering.FilterProvider>
        </Sorting.DefaultSortingProvider>
      </Pagination.Auto>
      <Outlet />
      {itemToDelete && (
        <QDeleteConfirmationModal
          title="Delete risk"
          onConfirm={() => {
            itemToDelete &&
              deleteElement(itemToDelete as any, {
                onSettled: () => setItemToDelete(null),
              });
          }}
          inProgress={isDeleting}
          onClose={() => setItemToDelete(null)}
          message={
            <QText fontSize="sm">
              Are you sure you want to delete {itemToDelete.codeTitle}? You
              cannot undo this action.
            </QText>
          }
        />
      )}
    </>
  );
};
