import React, { useCallback, useMemo, useState } from "react";

import { QIconButton } from "@qualio/ui-components";
import { Table, Typography } from "antd";
import { ColumnsType, ExpandableConfig } from "antd/lib/table/interface";
import { useParams, useNavigate } from "react-router-dom";

import { UserPermissions } from "../../../../../components";
import BorderedValue from "../../../../../components/BorderedValue";
import DefaultCollapsedIcon from "../../../../../components/CustomIcons/DefaultCollapsed";
import DefaultExpandedIcon from "../../../../../components/CustomIcons/DefaultExpanded";
import Req2Icon from "../../../../../components/CustomIcons/Req2";
import Req3Icon from "../../../../../components/CustomIcons/Req3";
import { FeatureFlags } from "../../../../../components/FeatureToggle/FeatureToggle.enum";
import useFeatureFlag from "../../../../../components/FeatureToggle/hooks/useFeatureFlag";
import ItemCode from "../../../../../components/ItemCode";
import commonTableStyles from "../../../../../components/StyledTable/commonStyles.module.less";
import expandedRowStyles from "../../../../../components/StyledTable/ExpandedRowCommonStyles.module.less";
import useHasPermission from "../../../../../components/UserGroupToggle/hooks/useHasPermission";
import { useDrawerProvider } from "../../../../../components_new/DesginElementDetailsDrawer/context/useDrawerProvider";
import { useQualityConfigs } from "../../../../../context/qualityConfigs";
import { designControlStatus } from "../../../../../lib/designControlStatus";
import { sortByCode } from "../../../../../lib/sortByCode";
import { isSoureEditableAndDeletable } from "../../../../../lib/source";
import { ProductParam } from "../../../../../paramTypes";
import {
  BaseQualityItem,
  QRIAttribute,
  DesignElementType,
} from "../../../../../types/baseQualityItem";
import { TestCase, TestCaseWithResult } from "../../../../../types/testCase";
import { RequirementTrace } from "../../../../../types/trace";
import { ResultValueToLabel } from "../../../../TestCaseResult/testResultValueToLabel";
import { isRequirement } from "../helpers";
import styles from "../RequirementTraceTable.module.less";
import RowIcon from "../RowIcon";
import ExpandedRowItemDetails from "./ExpandedRowItemDetails";

type ChildRowProps = {
  requirement: RequirementTrace;
  onDelete: (designElement: BaseQualityItem) => void;
};

const testConfigMapper = {
  [DesignElementType.REQ1]: DesignElementType.TEST_CASE_1,
  [DesignElementType.REQ2]: DesignElementType.TEST_CASE_2,
  [DesignElementType.REQ3]: DesignElementType.TEST_CASE_3,
  [DesignElementType.REQ4]: "",
};

const ExpandedRow: React.FC<ChildRowProps> = ({ requirement, onDelete }) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const { product } = useParams<ProductParam>() as ProductParam;
  const { configs } = useQualityConfigs();
  const navigate = useNavigate();
  const permissions = useHasPermission([
    UserPermissions.EDIT_DESIGN_ELEMENTS,
    UserPermissions.DELETE_DESIGN_ELEMENTS,
  ]);
  const features = useFeatureFlag([
    FeatureFlags.DESIGN_CONTROLS_CATEGORIES,
    FeatureFlags.DESIGN_CONTROLS_REMOVE_STATUS_LABEL,
  ]);
  const { handleItemSelected } = useDrawerProvider();

  const requirementType = requirement.type as
    | DesignElementType.REQ1
    | DesignElementType.REQ2
    | DesignElementType.REQ3
    | DesignElementType.REQ4;

  const testCaseLabel = useMemo<string | undefined>(() => {
    let config = configs.find((config) => config.type === "testCase");
    const specificConfig = configs.find(
      (config) => testConfigMapper[requirementType] === config.type,
    );
    config = specificConfig ?? config;

    return config ? config.label : undefined;
  }, [configs, requirementType]);

  const editButton = (cyTarget: string, editLink: string) => {
    return (
      <QIconButton
        iconName={"Edit2"}
        aria-label={"Edit"}
        data-cy={cyTarget}
        onClick={() => navigate(editLink)}
      />
    );
  };

  const deleteButton = (cyTarget: string, record: any) => {
    return (
      <QIconButton
        iconName={"Trash"}
        aria-label={"Delete"}
        data-cy={cyTarget}
        onClick={() => onDelete(record)}
      />
    );
  };

  const editDeleteActionsRequirementColumn = {
    render: (text: any, record: any) => {
      if (!isSoureEditableAndDeletable(record.source)) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "48px",
            }}
          >
            &nbsp;
          </div>
        );
      }

      const editDataCyTarget = `edit-requirement-${record.id}`;
      const deleteDataCyTarget = `delete-requirement-${record.id}`;
      const editTargetLink = `/product/${product}/requirement/edit/${record.id}`;

      return (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          {editButton(editDataCyTarget, editTargetLink)}
          {deleteButton(deleteDataCyTarget, record)}
        </div>
      );
    },
    width: 22,
  };

  const editDeleteActionsTestCaseColumn = {
    render: (text: any, record: any) => {
      if (!isSoureEditableAndDeletable(record.source)) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "48px",
            }}
          >
            &nbsp;
          </div>
        );
      }

      const editDataCyTarget = `edit-testCase-${record.id}`;
      const deleteDataCyTarget = `delete-testCase-${record.id}`;
      const editTargetLink = `/product/${product}/testCase/edit/${record.id}`;

      return (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          {editButton(editDataCyTarget, editTargetLink)}
          {deleteButton(deleteDataCyTarget, record)}
        </div>
      );
    },
    width: 22,
  };

  let requirementColumns: ColumnsType<RequirementTrace> = [
    {
      dataIndex: "title",
      sorter: sortByCode,
      defaultSortOrder: "ascend",
      render: (title, row) => (
        <span
          className={`${commonTableStyles.primaryColumn} ${
            !isRequirement(row) ? styles.secondaryRow : ""
          } ${commonTableStyles.clickTitle}`}
          onClick={() => handleItemSelected(row.id)}
        >
          <aside className={commonTableStyles.primaryColumnIcon}>
            <RowIcon
              row={row}
              requirementIcon={
                row.type === "req2" ? (
                  <Req2Icon style={{ fontSize: 20 }} />
                ) : (
                  <Req3Icon style={{ fontSize: 22 }} />
                )
              }
            />
          </aside>

          <main>
            {row.code && <ItemCode code={row.code} />} <strong>{title}</strong>
          </main>
        </span>
      ),
    },
    {
      title: "Tests",
      dataIndex: "testedBy",
      sorter: sortByCode,
      defaultSortOrder: "ascend",
      className: `${styles.topWorkflowColumn} ${commonTableStyles.rightAlignedColumn}`,
      render: (testedBy) => {
        if (!testedBy || !testedBy.length) {
          return null;
        }

        return (
          <BorderedValue borderType={"circle"}>{testedBy.length}</BorderedValue>
        );
      },
      width: 80,
    },
    {
      title: "Workflow",
      dataIndex: "statusLabel",
      className: `${styles.workflowColumn} ${commonTableStyles.rightAlignedColumn}`,
      render: (label) => (
        <BorderedValue borderType={"rectangle"}>{label}</BorderedValue>
      ),
    },
    {
      title: "Design Control",
      className: `${styles.topWorkflowColumn} ${commonTableStyles.rightAlignedColumn}`,
      render: (item: BaseQualityItem) => (
        <BorderedValue borderType={"rectangle"}>
          {designControlStatus(item)}
        </BorderedValue>
      ),
    },
  ];

  let testCaseColumns: ColumnsType<TestCase | TestCaseWithResult> = [
    {
      dataIndex: "title",
      render: (title, row) => (
        <span
          className={`${commonTableStyles.primaryColumn} ${styles.secondaryRow} ${commonTableStyles.clickTitle}`}
          onClick={() => handleItemSelected(row.id)}
        >
          <aside className={commonTableStyles.primaryColumnIcon}>
            <RowIcon row={row} requirementIcon={null} />
          </aside>

          <main>
            {row.code && <ItemCode code={row.code} />} <strong>{title}</strong>
          </main>
        </span>
      ),
    },
    {
      title: "Workflow",
      dataIndex: "statusLabel",
      className: `${styles.workflowColumn} ${commonTableStyles.rightAlignedColumn}`,
      render: (label, testCase) => {
        if (testCase.type === DesignElementType.TEST_CASE) {
          const log = (testCase as TestCase).log;
          return log ? (
            <BorderedValue borderType={"rectangle"}>
              {log.statusLabel}
            </BorderedValue>
          ) : null;
        }

        const testResult = (testCase as TestCaseWithResult).testResult;
        return testResult ? (
          <BorderedValue borderType={"rectangle"}>
            {ResultValueToLabel[testResult.result]}
          </BorderedValue>
        ) : null;
      },
    },
    {
      title: "Design Control",
      className: `${styles.topWorkflowColumn} ${commonTableStyles.rightAlignedColumn}`,
      render: (item: BaseQualityItem) => (
        <BorderedValue borderType={"rectangle"}>
          {designControlStatus(item)}
        </BorderedValue>
      ),
    },
  ];

  if (features[FeatureFlags.DESIGN_CONTROLS_CATEGORIES]) {
    requirementColumns.splice(1, 0, {
      title: "Category",
      dataIndex: "category",
      render: (category: QRIAttribute | undefined, item: RequirementTrace) => (
        <span data-cy={`${item.id}-category`}>{category?.label}</span>
      ),
    });
    testCaseColumns.splice(1, 0, {
      title: "Category",
      dataIndex: "category",
      render: (
        category: QRIAttribute | undefined,
        item: TestCase | TestCaseWithResult,
      ) => <span data-cy={`${item.id}-category`}>{category?.label}</span>,
    });
  }

  if (features[FeatureFlags.DESIGN_CONTROLS_REMOVE_STATUS_LABEL]) {
    requirementColumns = requirementColumns.filter(
      (column) => column.title !== "Workflow",
    );
    testCaseColumns = testCaseColumns.filter(
      (column) => column.title !== "Workflow",
    );
  }

  if (
    permissions[UserPermissions.EDIT_DESIGN_ELEMENTS] &&
    permissions[UserPermissions.DELETE_DESIGN_ELEMENTS]
  ) {
    requirementColumns.push(editDeleteActionsRequirementColumn);
    testCaseColumns.push(editDeleteActionsTestCaseColumn);
  }

  const expandable: ExpandableConfig<RequirementTrace | TestCase> = {
    expandedRowRender: (record) => (
      <ExpandedRow
        requirement={record as RequirementTrace}
        onDelete={onDelete}
      />
    ),
    expandIcon: ({ expanded, onExpand, record }) =>
      expanded ? (
        <div data-cy={`${record.id}-collapse`}>
          <DefaultExpandedIcon
            className={styles.childExpandIcon}
            onClick={(e: React.MouseEvent<HTMLElement>) => onExpand(record, e)}
          />
        </div>
      ) : (
        <div data-cy={`${record.id}-expand`}>
          <DefaultCollapsedIcon
            className={styles.childExpandIcon}
            onClick={(e: React.MouseEvent<HTMLElement>) => onExpand(record, e)}
          />
        </div>
      ),
    onExpandedRowsChange: (keys) => setExpandedRowKeys(keys as string[]),
  };

  const rowClassName = useCallback(
    (childrenCount: number) => {
      return (row: any, index: number) =>
        `${styles.withGuidelineMarker} ${
          expandedRowKeys.includes(row.id) ? "table-row-expanded" : ""
        } ${index === childrenCount - 1 ? "table-row-last" : ""}`;
    },
    [expandedRowKeys],
  );

  const hasTests = requirement.testedBy && requirement.testedBy.length > 0;
  const hasItems =
    hasTests || (requirement.children && requirement.children.length > 0);

  return (
    <div
      className={`${expandedRowStyles.expandedContentContainer} ${
        hasItems ? styles.withGuideline : ""
      }`}
    >
      <ExpandedRowItemDetails requirement={requirement} />

      {hasItems && (
        <main className={styles.mainContent}>
          <section
            className={`${!hasTests ? styles.lastGuidelineSection : ""}`}
          >
            {requirement.children && requirement.children.length > 0 ? (
              <Table
                className={`${styles.childTable} ${expandedRowStyles.nestedTable}`}
                childrenColumnName="disabled"
                showHeader={false}
                rowKey="id"
                rowClassName={rowClassName(requirement.children.length)}
                columns={requirementColumns}
                dataSource={requirement.children}
                pagination={false}
                expandable={expandable as ExpandableConfig<RequirementTrace>}
              />
            ) : null}
          </section>

          {requirement.testedBy && requirement.testedBy.length > 0 ? (
            <section className={`${styles.lastGuidelineSection}`}>
              <Typography.Title className={styles.sectionTitle} level={5}>
                {testCaseLabel}
              </Typography.Title>
              <Table
                className={`${styles.childTable} ${expandedRowStyles.nestedTable}`}
                childrenColumnName="disabled"
                showHeader={false}
                rowKey="id"
                rowClassName={rowClassName(requirement.testedBy.length)}
                columns={testCaseColumns}
                dataSource={requirement.testedBy}
                pagination={false}
                expandable={expandable as ExpandableConfig<TestCase>}
              />
            </section>
          ) : null}
        </main>
      )}
    </div>
  );
};
export default ExpandedRow;
