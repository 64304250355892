import React, { useCallback, useMemo, useState } from "react";

import { isTestCasePolicy, TestCaseList } from "@design-controls/types";
import {
  createQColumnHelper,
  DataProvider,
  Filtering,
  QDataTable,
  TableMenuItem,
  QDeleteConfirmationModal,
  QText,
  Pagination,
  QTag,
  Sorting,
} from "@qualio/ui-components";
import { Outlet, useNavigate } from "react-router";

import { useTestCaseList } from "../../../hooks/useTestCaseList";
import { ResultValueToLabel } from "../../../views/TestCaseResult/testResultValueToLabel";
import { ShowOnlyOpen } from "../../filter/ShowOnlyOpen";
import { TestCaseType } from "./filter";
import { useCurrentConfigs } from "../../hooks/useCurrentConfigs";
import { usePolicyGroupResourceQuickSearch } from "../../../hooks/qri/usePolicyGroupResourceQuickSearch";
import { CategoryFilter } from "../../filter/CategoryFilter";
import { ResultOptions } from "../../../views/TestCaseResult/TestCaseResult.enums";
import { TestResult } from "./filter/TestResult";
import { elementStatus, ElementStatusTag } from "../../domain/elementStatus";
import { TestResultVariantColour } from "../../constants";
import { useDeleteTestCase } from "../../hooks/designElement/useDeleteTestCase";
import { GapTableCell } from "../../components/GapTableCell/GapTableCell";

const columnHelper = createQColumnHelper<TestCaseList>();

export const TestList: React.FC = () => {
  const { isLoading, data } = useTestCaseList();
  const { isLoading: isDeleting, mutate: deleteElement } = useDeleteTestCase();
  const [itemToDelete, setItemToDelete] = useState<TestCaseList | null>(null);
  const policies = useCurrentConfigs();
  const navigate = useNavigate();

  const { isLoading: categoryIsLoading, data: categories } =
    usePolicyGroupResourceQuickSearch(isTestCasePolicy);

  const columns = useMemo(() => {
    return [
      columnHelper.code("code", { header: "ID", maxWidth: "100px" }),
      {
        header: " ",
        meta: {
          width: "100px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: TestCaseList };
        }) => <GapTableCell openIssues={row.policyIssues} type={row.type} />,
      },
      columnHelper.textLink("title", (record) => record.id, {
        header: "Test case",
        maxWidth: "400px",
      }),
      columnHelper.text((record) => record.category?.label, {
        header: "Category",
        maxWidth: "200px",
      }),
      columnHelper.date((record) => new Date(record.updated), {
        header: "Last modified",
        id: "updated",
        maxWidth: "150px",
      }),
      {
        header: "Last results",
        meta: {
          maxWidth: "150px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: TestCaseList };
        }) => {
          if (!row.testResult) {
            return "";
          }

          return (
            <QTag
              variantColor={
                TestResultVariantColour[row.testResult.result as never]
              }
            >
              {ResultValueToLabel[row.testResult.result as never]}
            </QTag>
          );
        },
      },
      columnHelper.status(elementStatus, {
        header: "Status",
        statuses: ElementStatusTag,
      }),
      columnHelper.menu({
        items: (
          <>
            <TableMenuItem
              onClick={(item: TestCaseList) =>
                navigate(`add-result/${item.id}`)
              }
              data-cy="add-test-result"
            >
              Add test result
            </TableMenuItem>
            <TableMenuItem
              onClick={(item: TestCaseList) => navigate(`${item.id}/edit`)}
              isDisabled={(item: TestCaseList) =>
                !["product-development", "qualio"].includes(item.source)
              }
              data-cy="edit"
            >
              Edit
            </TableMenuItem>
            <TableMenuItem
              onClick={(item: TestCaseList) => setItemToDelete(item)}
              color="red.500"
              data-cy="delete"
            >
              Delete
            </TableMenuItem>
          </>
        ),
      }),
    ];
  }, []);

  const filterDefinitions = useMemo(() => {
    return {
      type: {
        label: "Test type",
        schema: Filtering.schemas.StringSchema().array().nullish(),
        activeRender: (v: any) => {
          const item = policies?.find((c) => c.type === v);
          if (!item) {
            return;
          }

          return {
            label: "Test type:",
            value: item.label,
          };
        },
      },
      category: {
        label: "Category",
        schema: Filtering.schemas.StringSchema().array().nullish(),
        activeRender: (v: any) => {
          const item = categories?.find((c) => c.identifiers.qri === v);
          if (!item) {
            return;
          }

          return {
            label: "Category:",
            value: item.displayLabel,
          };
        },
      },
      testResult: {
        label: "Latest results",
        schema: Filtering.schemas.StringSchema().nullish(),
        activeRender: (v: any) => ({
          label: "Result:",
          value: v && ResultValueToLabel[v as ResultOptions],
        }),
      },
      show_outdated: {
        label: "Show outdated result",
        schema: Filtering.schemas.BooleanSchema().nullish(),
        activeRender: (v: any) => ({
          label: null,
          value: v && "Outdated result",
        }),
      },
      show_required_parent: {
        label: "Show missing requirement",
        schema: Filtering.schemas.BooleanSchema().nullish(),
        activeRender: (v: any) => ({
          label: null,
          value: v && "Missing requirement",
        }),
      },
      show_failing: {
        label: "Show failing",
        schema: Filtering.schemas.BooleanSchema().nullish(),
        activeRender: (v: any) => ({
          label: null,
          value: v && "Failing test case",
        }),
      },
    };
  }, [policies, categories]);

  const filterOption = useCallback(
    (
      item: Readonly<TestCaseList>,
      searchTerm: string | undefined,
      filters: Filtering.ResolvedFilters<typeof filterDefinitions> | undefined,
    ) => {
      if (
        searchTerm &&
        !item.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
      ) {
        return false;
      }

      if (filters && Object.values(filters).some((field) => field.value)) {
        const {
          show_outdated,
          show_required_parent,
          show_failing,
          type,
          category,
          testResult,
        } = filters;

        return (
          (!show_outdated?.value || item.policyIssues.OUTDATED_TESTRESULT) &&
          (!show_required_parent?.value || item.policyIssues.REQUIRES_PARENT) &&
          (!show_failing?.value || item.policyIssues.FAILING_TEST) &&
          (!type?.value || type.value.includes(item.type)) &&
          (!testResult?.value ||
            testResult.value === item.testResult?.result) &&
          (!category?.value || category.value.includes(item.category?.qri))
        );
      }

      return true;
    },
    [],
  ) as DataProvider.FilterOptionFn<TestCaseList>;

  return (
    <>
      <Pagination.Auto pageSizeKey="size" pageIndexKey="page" clientSide>
        <Sorting.DefaultSortingProvider
          initialSort={{ column: "updated", descending: true }}
        >
          <Filtering.FilterProvider
            definitions={filterDefinitions}
            searchTermKey="search"
          >
            <DataProvider.Fixed
              data={data ?? []}
              isLoading={isLoading}
              filterOption={filterOption}
            >
              <QDataTable
                columns={columns}
                data-cy="test-list"
                getRowId={(row) => row.id}
              >
                <Filtering.FormContent>
                  <TestCaseType policies={policies} />
                  <CategoryFilter
                    categories={categories}
                    isLoading={categoryIsLoading}
                  />
                  <TestResult />
                  <ShowOnlyOpen type="testCase" />
                </Filtering.FormContent>
              </QDataTable>
            </DataProvider.Fixed>
          </Filtering.FilterProvider>
        </Sorting.DefaultSortingProvider>
      </Pagination.Auto>
      <Outlet />
      {itemToDelete && (
        <QDeleteConfirmationModal
          title="Delete test"
          onConfirm={() => {
            itemToDelete &&
              deleteElement(itemToDelete as any, {
                onSettled: () => setItemToDelete(null),
              });
          }}
          inProgress={isDeleting}
          onClose={() => setItemToDelete(null)}
          message={
            <QText fontSize="sm">
              Are you sure you want to delete {itemToDelete.codeTitle}? You
              cannot undo this action.
            </QText>
          }
        />
      )}
    </>
  );
};
