import { Policy } from "@design-controls/types";
import { useCurrentUser, useToastProvider } from "@qualio/ui-components";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { designControlsApi } from "../../api/designControls";
import { useCurrentProduct } from "../useCurrentProduct";

const sortedArray = [
  "req1",
  "req2",
  "req3",
  "req4",
  "testCase1",
  "testCase2",
  "testCase3",
  "risk",
  "testCase",
  "testLog",
] as const;

export const useUpdatePolicy = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();
  const navigate = useNavigate();
  const { showToast } = useToastProvider();
  const client = useQueryClient();

  return useMutation({
    mutationFn: (policies: Policy[]) => {
      const sortedPolicies = policies.sort(
        (a, b) => sortedArray.indexOf(a.type) - sortedArray.indexOf(b.type),
      );

      return Promise.all(
        sortedPolicies.map((policy) =>
          designControlsApi.updatePolicy(companyId, productId, policy),
        ),
      );
    },
    onSuccess: async () => {
      showToast({
        title: "Success",
        description: "Changes saved to product configuration",
        status: "success",
      });
      await client.invalidateQueries(["PRODUCT_CONFIGS", companyId, productId]);
      navigate("..");
    },
    onError: () => {
      showToast({
        title: "Error",
        description: "Failed to save changes. Please try again.",
        status: "error",
      });
    },
  });
};
