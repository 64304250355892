import { useCurrentUser } from "@qualio/ui-components";
import { useCurrentProduct } from "../useCurrentProduct";
import { useQuery } from "react-query";
import { designControlsApi } from "../../api/designControls";

export const useRetrieveElements = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();

  const { isLoading, data } = useQuery({
    queryFn: () => designControlsApi.query(companyId, productId),
    queryKey: ["OPEN_ISSUE_LIST", companyId, productId],
  });

  return {
    isLoading,
    data,
  };
};
