export const sourceDisplayName = (source: string): string | null => {
  switch (source) {
    case "asana":
      return "Asana";
    case "jira":
      return "JIRA";
    case "xrayCloud":
      return "X-Ray";
    case "zephyrScale":
      return "Zephyr Scale";
    case "testrail":
      return "TestRail";
    case "azureDevops":
      return "Azure Devops";
    case "github":
      return "Github";
    case "gitlab":
      return "Gitlab";
    default:
      return null;
  }
};
