import { TestCaseDesignElement, Requirement } from "@design-controls/types";
import { z } from "zod";

export const testCaseToForm = (testCase: TestCaseDesignElement) => {
  const { parent, ...item } = testCase;

  return {
    ...item,
    requirements: [...parent.filter((item) => item.type.startsWith("req"))],
  };
};

export const TestCaseForm = z
  .object({
    type: z.string(),
    requirements: Requirement.shape.parent,
  })
  .merge(
    Requirement.omit({
      children: true,
      parent: true,
      sourcedItems: true,
      mitigates: true,
    }),
  );

export type TestCaseForm = z.infer<typeof TestCaseForm>;
