import {
  QBodyLayout,
  QButton,
  QButtonGroup,
  QFooter,
  QFormControl,
  QHeader,
  QInput,
  QMultiSelect,
  QStack,
  QTitle,
} from "@qualio/ui-components";
import React, { useCallback, useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Editor } from "../../../components";
import { ReviewDesignElement } from "../ReviewDesignElement/ReviewDesignElement";
import { LinkedDocuments } from "../LinkedDocuments/LinkedDocuments";
import { ReviewForm as ReviewFormValidation } from "./validation";
import { useRetrieveUsers } from "../../hooks/useRetrieveUsers";
import { useUnstagedElements } from "../../hooks/review/useUnstagedElements";
import {
  isDesignElementRequirement,
  isDesignElementRisk,
  isDesignElementTestCase,
} from "@design-controls/types";
import { ReviewRefreshAlert } from "../ReviewRefreshAlert/ReviewRefreshAlert";

type Props = {
  isSaving: boolean;
  onSubmit: (form: ReviewFormValidation) => void;
  defaultValue: ReviewFormValidation;
  onClose: () => void;
};

export const ReviewForm: React.FC<Props> = ({
  isSaving,
  onSubmit,
  onClose,
  defaultValue,
  children: title,
}) => {
  const methods = useForm<ReviewFormValidation>({
    defaultValues: defaultValue,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(ReviewFormValidation),
  });

  const { data: approvers, isLoading: isLoadingApprovers } = useRetrieveUsers();
  const { data: unstaged } = useUnstagedElements();

  const {
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const requirements = useMemo(
    () =>
      [...(unstaged ?? []), ...defaultValue.requirements].filter(
        isDesignElementRequirement,
      ),
    [unstaged, defaultValue.requirements],
  );

  const testCases = useMemo(
    () =>
      [...(unstaged ?? []), ...defaultValue.testCases].filter(
        isDesignElementTestCase,
      ),
    [unstaged, defaultValue.testCases],
  );

  const risks = useMemo(
    () =>
      [...(unstaged ?? []), ...defaultValue.risks].filter(isDesignElementRisk),
    [unstaged, defaultValue.risks],
  );

  const approverOptions = useMemo(() => {
    return (approvers ?? []).map((approver) => ({
      label: approver.fullName,
      value: approver.id.toString(),
    }));
  }, [approvers]);

  const [watchedRequirements, watchedTestCases, watchedRisks] = watch([
    "requirements",
    "testCases",
    "risks",
  ]) as [
    ReviewFormValidation["requirements"],
    ReviewFormValidation["testCases"],
    ReviewFormValidation["risks"],
  ];

  const isOutdated = useMemo(() => {
    return [...watchedRequirements, ...watchedRisks, ...watchedTestCases].some(
      (item) => item.latest && item.latest !== item.version,
    );
  }, [watchedRequirements, watchedTestCases, watchedRisks]);

  const refreshAllDesignElements = useCallback(() => {
    setValue(
      "requirements",
      watchedRequirements.map((item) => ({
        ...item,
        version: item.latest ?? item.version,
      })),
    );
    setValue(
      "testCases",
      watchedTestCases.map((item) => ({
        ...item,
        version: item.latest ?? item.version,
      })),
    );
    setValue(
      "risks",
      watchedRisks.map((item) => ({
        ...item,
        version: item.latest ?? item.version,
      })),
    );
  }, [watchedRequirements, watchedTestCases, watchedRisks]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} data-cy="review-form">
        <QBodyLayout.Default size="md">
          <QHeader>
            <QTitle>{title}</QTitle>
            {isOutdated && (
              <ReviewRefreshAlert onRefresh={refreshAllDesignElements} />
            )}
          </QHeader>
          <QStack gap="16px">
            <QFormControl
              label="Title"
              isRequired
              isInvalid={!!errors?.title}
              error={errors?.title?.message}
            >
              <Controller
                name="title"
                render={({ field: { value, onChange } }) => (
                  <QInput value={value} onChange={onChange} data-cy="title" />
                )}
              />
            </QFormControl>
            <QFormControl
              label="Description"
              isInvalid={!!errors?.description}
              error={errors?.description?.message}
            >
              <Controller
                name="description"
                render={({ field: { value, onChange } }) => (
                  <Editor onChange={onChange} value={value} />
                )}
              />
            </QFormControl>
            <QFormControl label="Approvers" data-cy="approvers">
              <Controller
                name="approvers"
                render={({ field: { value, onChange } }) => (
                  <QMultiSelect
                    options={approverOptions}
                    onChange={(selectedItems) =>
                      onChange(
                        selectedItems.map((item) => ({
                          id: item.value,
                          fullName: item.label,
                        })),
                      )
                    }
                    value={approverOptions
                      .filter((approver) =>
                        value.find(
                          (v: ReviewFormValidation["approvers"][0]) =>
                            v.id === approver.value,
                        ),
                      )
                      .map((v) => v.value)}
                    isLoading={isLoadingApprovers}
                  />
                )}
              />
            </QFormControl>
            <QFormControl>
              <Controller
                name="requirements"
                render={({ field: { value, onChange } }) => (
                  <ReviewDesignElement
                    label="Requirements"
                    data={requirements}
                    onChange={onChange}
                    value={value}
                    data-cy="add-requirement"
                  />
                )}
              />
            </QFormControl>
            <QFormControl>
              <Controller
                name="testCases"
                render={({ field: { value, onChange } }) => (
                  <ReviewDesignElement
                    label="Test cases"
                    data={testCases}
                    onChange={onChange}
                    value={value}
                    data-cy="add-test-case"
                  />
                )}
              />
            </QFormControl>
            <QFormControl>
              <Controller
                name="risks"
                render={({ field: { value, onChange } }) => (
                  <ReviewDesignElement
                    label="Risks"
                    data={risks}
                    onChange={onChange}
                    value={value}
                    data-cy="add-risk"
                  />
                )}
              />
            </QFormControl>

            <QFormControl>
              <Controller
                name="linkedDocuments"
                render={({ field: { value, onChange } }) => (
                  <LinkedDocuments
                    onChange={onChange}
                    value={value}
                    data-cy="add-document"
                  />
                )}
              />
            </QFormControl>
          </QStack>
          <QFooter>
            <QButtonGroup>
              <QButton
                variant="outline"
                isDisabled={isSaving}
                onClick={onClose}
                data-cy="cancel-button"
              >
                Cancel
              </QButton>
              <QButton type="submit" isLoading={isSaving} data-cy="save-button">
                {defaultValue.title ? "Save" : "Create review"}
              </QButton>
            </QButtonGroup>
          </QFooter>
        </QBodyLayout.Default>
      </form>
    </FormProvider>
  );
};
