import { useCurrentUser } from "@qualio/ui-components";
import { useQuery } from "react-query";
import { useCurrentProduct } from "../useCurrentProduct";
import { ChangeControlElement } from "../../types/router";
import { useParams } from "react-router-dom";
import { designControlsApi } from "../../api/designControls";

export const useRetrieveReview = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();
  const { changeControlId } =
    useParams<ChangeControlElement>() as ChangeControlElement;

  return useQuery({
    queryKey: ["REVIEW", companyId, productId, changeControlId],
    queryFn: () =>
      designControlsApi.retrieveReview(companyId, productId, changeControlId),
  });
};
