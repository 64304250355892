import { z } from "zod";
import { PolicyIssue } from "./openIssue";
import { QRIAttribute } from "./qri";

const BaseList = z.object({
  id: z.string(),
  title: z.string(),
  created: z.string(),
  updated: z.string(),
  code: z.string(),
  itemStatus: z.string(),
  changeControlStatus: z.string(),
  source: z.string(),
  codeTitle: z.string(),
  policyIssues: z.record(PolicyIssue, z.boolean()),
});

export type BaseList = z.infer<typeof BaseList>;

const Level1RequirementList = BaseList.extend({
  type: z.literal("req1"),
  category: QRIAttribute.optional(),
  component: QRIAttribute.optional(),
});
type Level1RequirementList = z.infer<typeof Level1RequirementList>;

const Level2RequirementList = BaseList.extend({
  type: z.literal("req2"),
  category: QRIAttribute.optional(),
  component: QRIAttribute.optional(),
});
type Level2RequirementList = z.infer<typeof Level2RequirementList>;

const Level3RequirementList = BaseList.extend({
  type: z.literal("req3"),
  category: QRIAttribute.optional(),
  component: QRIAttribute.optional(),
});
type Level3RequirementList = z.infer<typeof Level3RequirementList>;

const Level4RequirementList = BaseList.extend({
  type: z.literal("req4"),
  category: QRIAttribute.optional(),
  component: QRIAttribute.optional(),
});
type Level4RequirementList = z.infer<typeof Level4RequirementList>;

export type RequirementList =
  | Level1RequirementList
  | Level2RequirementList
  | Level3RequirementList
  | Level4RequirementList;

const TestCase = BaseList.merge(
  z.object({
    testResult: z
      .object({
        result: z.string(),
        executedBy: z.string(),
        date: z.string(),
        notes: z.string().nullish(),
        source: z.string(),
      })
      .optional(),
    category: QRIAttribute.optional(),
  }),
);

const Level1TestCaseList = TestCase.extend({
  type: z.literal("testCase1"),
});
type Level1TestCaseList = z.infer<typeof Level1TestCaseList>;

const Level2TestCaseList = TestCase.extend({
  type: z.literal("testCase2"),
});
type Level2TestCaseList = z.infer<typeof Level2TestCaseList>;

const Level3TestCaseList = TestCase.extend({
  type: z.literal("testCase3"),
});
type Level3TestCaseList = z.infer<typeof Level3TestCaseList>;

export type TestCaseList =
  | Level1TestCaseList
  | Level2TestCaseList
  | Level3TestCaseList;

export const RiskList = BaseList.extend({
  category: QRIAttribute.optional(),
  type: z.literal("risk"),
  subType: z.string().optional(),
});
export type RiskList = z.infer<typeof RiskList>;

export type ISORiskList = RiskList;
export type FMEARiskList = RiskList;

export const ElementList = z.discriminatedUnion("type", [
  Level1RequirementList,
  Level2RequirementList,
  Level3RequirementList,
  Level4RequirementList,
  Level1TestCaseList,
  Level2TestCaseList,
  Level3TestCaseList,
  RiskList,
]);
export type ElementList = z.infer<typeof ElementList>;

export const isRequirementList = (
  item: ElementList,
): item is RequirementList => {
  return item.type.startsWith("req");
};

export const isTestCaseList = (item: ElementList): item is TestCaseList => {
  return item.type.startsWith("testCase");
};

export const isRiskList = (item: ElementList): item is RiskList => {
  return item.type === "risk";
};

export const isIsoRiskList = (item: ElementList): item is ISORiskList => {
  return isRiskList(item) && (!item.subType || item.subType === "iso");
};

export const isFmeaRiskList = (item: ElementList): item is FMEARiskList => {
  return isRiskList(item) && item.subType === "fmea";
};
