import { FMEARiskDesignElement, isRiskPolicy } from "@design-controls/types";
import { useCurrentConfigs } from "../../../hooks/useCurrentConfigs";
import { QTag } from "@qualio/ui-components";
import React from "react";

type Props = {
  mitigation: FMEARiskDesignElement["preMitigation"];
  "data-cy": string;
};

export const FMEARiskLevel: React.FC<Props> = ({
  mitigation,
  "data-cy": dataCy,
}) => {
  const riskPolicy = useCurrentConfigs().find(isRiskPolicy);
  if (
    !riskPolicy ||
    !riskPolicy.assessment.fmea ||
    !mitigation ||
    !(mitigation.detectability && mitigation.occurrence && mitigation.severity)
  ) {
    return null;
  }

  const score =
    Number(mitigation.detectability) *
    Number(mitigation.occurrence) *
    Number(mitigation.severity);

  const threshold = Number(riskPolicy.assessment.fmea.mitigationThreshold);

  return (
    <QTag variantColor={score >= threshold ? "red" : "green"} data-cy={dataCy}>
      {score}
    </QTag>
  );
};
