import { useDisclosure } from "@chakra-ui/hooks";
import React, { useCallback, useMemo } from "react";
import {
  createQColumnHelper,
  DataProvider,
  QBox,
  QButton,
  QCard,
  QCenter,
  QDataTable,
  QIconButton,
  QLookup,
  QStack,
  QTag,
  QText,
  QTooltip,
} from "@qualio/ui-components";
import {
  isDesignElementFMEARisk,
  isDesignElementISORisk,
  LinkedElement,
} from "@design-controls/types";
import { useCurrentConfigs } from "../../hooks/useCurrentConfigs";
import { ItemStatusTag } from "../ItemStatusTag/ItemStatusTag";
import { useNavigate } from "react-router";

const columnHelper = createQColumnHelper<LinkedElement>();

type LinkedDesignElement = LinkedElement & {
  latest?: string;
  version?: string;
};

type Props = {
  label: string;
  "data-cy"?: string;
  data: Array<LinkedDesignElement>;
  onChange: (items: LinkedDesignElement[]) => void;
  value: LinkedDesignElement[];
};

export const ReviewDesignElement: React.FC<Props> = ({
  label,
  "data-cy": dataCy,
  data,
  onChange,
  value,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const configs = useCurrentConfigs();
  const navigate = useNavigate();

  const defaultIds = useMemo(() => value.map((item) => item.id), [value]);

  const isPreselected = useCallback(
    (result) => {
      return !!defaultIds.find((id) => id === result.id);
    },
    [defaultIds],
  );

  const onSelect = useCallback(
    (selection: readonly LinkedElement[]) => {
      onChange([...selection]);
      onClose();
      return Promise.resolve();
    },
    [onChange, onClose],
  );

  const onDeleteItem = useCallback(
    (item: LinkedElement) => {
      onChange(value.filter((element) => element.id !== item.id));
    },
    [value, onChange],
  );

  const onRefreshItem = useCallback(
    (item: LinkedDesignElement) => {
      if (item.latest && item.latest !== item.version) {
        onChange(
          value.map((element) => {
            if (element.id !== item.id) {
              return element;
            }

            return { ...element, version: item.latest };
          }),
        );
      }
    },
    [value, onChange],
  );

  const onClickToDiff = useCallback(
    (item: LinkedElement) => {
      return () => navigate(`${item.id}/${item.version}`);
    },
    [navigate],
  );

  const columns = useMemo(() => {
    return [
      columnHelper.text("code", { header: "Code", maxWidth: "100px" }),
      columnHelper.text("title", { header: "Title", maxWidth: "400px" }),
      {
        header: "Type",
        meta: {
          maxWidth: "150px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: LinkedDesignElement };
        }) => {
          let label = (
            configs.find((config) => config.type === row.type) as
              | { label: string }
              | undefined
          )?.label;

          if (isDesignElementISORisk(row)) {
            label = "ISO 14971";
          }

          if (isDesignElementFMEARisk(row)) {
            label = "FMEA";
          }

          return <QTag variantColor="gray">{label}</QTag>;
        },
      },
      {
        header: "Status",
        id: "status",
        meta: {
          maxWidth: "100px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: LinkedDesignElement };
        }) => {
          return (
            row.itemStatus && <ItemStatusTag itemStatus={row.itemStatus} />
          );
        },
      },
      {
        header: " ",
        id: "latest",
        meta: {
          maxWidth: "48px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: LinkedDesignElement };
        }) => {
          return row.latest && row.latest !== row.version ? (
            <QIconButton
              iconName="RefreshCw"
              aria-label="Update design element to latest version"
              data-cy={`refresh-${row.id}`}
              onClick={() => onRefreshItem(row)}
            />
          ) : null;
        },
      },
      {
        header: " ",
        id: "diff",
        meta: {
          maxWidth: "50px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: LinkedElement };
        }) => {
          return (
            row.itemStatus !== "NEW" && (
              <QCenter>
                <QTooltip label="See difference">
                  <QBox>
                    <QIconButton
                      iconName="GitPullRequest"
                      aria-label="See difference between version"
                      onClick={onClickToDiff(row)}
                    />
                  </QBox>
                </QTooltip>
              </QCenter>
            )
          );
        },
      },
      {
        header: " ",
        meta: {
          maxWidth: "48px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: LinkedDesignElement };
        }) => {
          return (
            <QIconButton
              iconName="Trash"
              aria-label="Remove"
              data-cy={`remove-${row.id}`}
              onClick={() => onDeleteItem(row)}
            />
          );
        },
      },
    ];
  }, [configs, onDeleteItem, onRefreshItem, onClickToDiff]);

  const filterOption = useCallback(
    (item: Readonly<LinkedDesignElement>, searchTerm: string | undefined) => {
      if (!searchTerm) {
        return true;
      }

      return item.title
        .toLocaleLowerCase()
        .includes(searchTerm.toLocaleLowerCase());
    },
    [],
  );

  return (
    <>
      <QCard>
        <QStack padding={4} gap={4}>
          <QStack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <QText fontSize="sm" fontWeight={600}>
              {label}
            </QText>
            <QButton variant="link" onClick={onOpen} data-cy={dataCy}>
              Add {label.toLocaleLowerCase()}
            </QButton>
          </QStack>
          {value.length > 0 && (
            <DataProvider.Fixed data={value} isLoading={false}>
              <QDataTable columns={columns} withHeaders={false} hideItemCount />
            </DataProvider.Fixed>
          )}
        </QStack>
      </QCard>
      {isOpen && (
        <DataProvider.Fixed<LinkedElement>
          data={data}
          filterOption={filterOption}
        >
          <QLookup.MultiSelect
            isOpen={isOpen}
            onCancel={onClose}
            onSelect={onSelect}
            accessors={{ id: "id" }}
            view={ELEMENT_VIEW}
            isItemPreSelected={isPreselected}
            data-cy={`${dataCy}-lookup`}
          />
        </DataProvider.Fixed>
      )}
    </>
  );
};

const ELEMENT_VIEW = {
  code: {
    header: "Code",
    width: "",
    render: (code) => <QText>{code}</QText>,
  },
  title: {
    header: "Title",
    width: "75%",
    render: (title) => <QText>{title}</QText>,
  },
  itemStatus: {
    header: "Status",
    width: "100px",
    render: (itemStatus) =>
      itemStatus && <ItemStatusTag itemStatus={itemStatus} />,
  },
} as QLookup.DataView<LinkedElement>;
