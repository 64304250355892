import { useQuery } from "react-query";
import { designControlsApi } from "../../api/designControls";
import { useCurrentUser } from "@qualio/ui-components";
import { useCurrentProduct } from "../useCurrentProduct";

export const useUnstagedElements = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();

  return useQuery({
    queryFn: () => designControlsApi.retrieveUnstaged(companyId, productId),
    queryKey: ["UNSTAGED", companyId, productId],
  });
};
