import React from "react";
import { QButton, QButtonGroup } from "@qualio/ui-components";
import { useIsMutating } from "react-query";

type Props = {
  onClick: () => void;
  onClose: () => void;
};

export const FormButtons: React.FC<Props> = ({ onClick, onClose }) => {
  const isMutating = useIsMutating();

  return (
    <QButtonGroup>
      <QButton
        variant="outline"
        isDisabled={isMutating > 0}
        onClick={onClose}
        data-cy="cancel"
      >
        Cancel
      </QButton>
      <QButton
        onClick={onClick}
        isLoading={isMutating > 0}
        data-cy="save-changes"
      >
        Save changes
      </QButton>
    </QButtonGroup>
  );
};
