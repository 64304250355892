import React, { ReactElement, useCallback, useMemo } from "react";

import {
  QAlertBox,
  QButton,
  QFlex,
  QIconButton,
  QStack,
  QText,
} from "@qualio/ui-components";

import FileIcon from "../../../components/CustomIcons/File";
import { downloadFile } from "../../../lib/downloadFile";
import { resolveFileType } from "../../../lib/resolveExtensionToType";
import { sortByAlphanumericInsensitive } from "../../../lib/sortFunctions";
import { ReleaseDocument, Report } from "../../../types/release";
import { ClickPropagationBarrier } from "../../ClickPropogateBarrier/ClickPropogateBarrier";
import { DisplayElement, DisplayElementHeader } from "../../DisplayElement";
import { ReleaseDocumentsStatus } from "../hooks";
import { ChangeControlStatus } from "../../../types/changeControl";

type ChangeControlDetailsDocumentsProps = {
  isRefetching: boolean;
  status: ReleaseDocumentsStatus;
  documents: Array<Report | ReleaseDocument>;
  reviewModalStatus:
    | ChangeControlStatus.APPROVED
    | ChangeControlStatus.NOT_APPROVED
    | undefined;
  refresh: () => void;
};

export const ChangeControlDetailsDocuments: React.FC<
  ChangeControlDetailsDocumentsProps
> = ({ isRefetching, status, documents, reviewModalStatus, refresh }) => {
  let childComponent: ReactElement;

  const handleDownload = useCallback(
    (e: React.MouseEvent) => {
      if (reviewModalStatus) {
        return;
      }
      const documentName = e.currentTarget.getAttribute("target-id") as string;
      const clickedDoc = documents.find((doc) => doc.name === documentName);
      if (clickedDoc) {
        downloadFile(clickedDoc.url);
      }
    },
    [documents, reviewModalStatus],
  );

  const sortedDocuments = useMemo(() => {
    return documents.sort((first, second) =>
      sortByAlphanumericInsensitive(first.name, second.name),
    );
  }, [documents]);

  switch (status) {
    case ReleaseDocumentsStatus.RELEASE_ERROR:
      childComponent = (
        <QAlertBox status={"error"}>
          <QFlex width={"100%"}>
            <QText fontSize={13}>
              Some documents were not generated or generated incorrectly
            </QText>
          </QFlex>
        </QAlertBox>
      );
      break;
    case ReleaseDocumentsStatus.LOADING:
    case ReleaseDocumentsStatus.RELEASE_IN_PROGRESS:
      childComponent = (
        <QAlertBox status={"info"}>
          <QFlex
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <QText fontSize={13}>Documents are currently being generated</QText>
            <QIconButton
              data-cy={"refresh-release-documents-button"}
              iconName={"RefreshCw"}
              aria-label={"refresh-now-btn"}
              size={"sm"}
              onClick={refresh}
              isLoading={isRefetching}
            />
          </QFlex>
        </QAlertBox>
      );
      break;
    case ReleaseDocumentsStatus.COMPLETED:
      childComponent = (
        <QFlex
          width={"100%"}
          flexDirection={"column"}
          rowGap={1}
          data-cy={"review-documents"}
        >
          {sortedDocuments.map((document) => {
            return (
              <DisplayElement
                key={document.name}
                cursor="pointer"
                target-id={document.name}
                onClick={handleDownload}
                data-cy={`design-control-document-${document.name}`}
              >
                <DisplayElementHeader width={"100%"}>
                  <QStack
                    direction="row"
                    alignItems="center"
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    <QFlex columnGap={3}>
                      <FileIcon
                        fileType={resolveFileType(document.url)}
                        style={{ fontSize: 20 }}
                      />
                      <QText weight={"semibold"}>{document.name}</QText>
                    </QFlex>
                    <ClickPropagationBarrier>
                      <QButton
                        target-id={document.name}
                        data-cy={`design-control-document-download-button-${document.name}`}
                        rightIcon={"Download"}
                        variant="link"
                        onClick={handleDownload}
                      >
                        &nbsp;
                      </QButton>
                    </ClickPropagationBarrier>
                  </QStack>
                </DisplayElementHeader>
              </DisplayElement>
            );
          })}
        </QFlex>
      );
  }

  return (
    <QFlex marginTop={5} marginBottom={5} rowGap={3} flexDirection={"column"}>
      <QText color="gray.700" fontSize={14} weight="semibold">
        Review Documents
      </QText>
      {childComponent}
    </QFlex>
  );
};
