import React, { useCallback, useMemo } from "react";
import { useDeleteElementDrawer } from "../../hooks/useDesignElementDrawer";
import {
  QBackLink,
  QBodyLayout,
  QBox,
  QButton,
  QButtonGroup,
  QDateMetadataItem,
  QHeader,
  QHeading,
  QHorizontalMetadata,
  QNotFound,
  QOpenPropertiesPanelButton,
  QPageLoader,
  QPropertiesPanel,
  QStack,
  QText,
  QTitle,
  QTogglePropertiesPanelButton,
} from "@qualio/ui-components";
import { isDesignElementTestCase } from "@design-controls/types";
import { sanitize } from "dompurify";
import { DesignReviewOverviewTable } from "../../components/DesignReviewOverviewTable/DesignReviewOverviewTable";
import { RequirementTable } from "../../components/OverviewTable/RequirementTable";
import { RisksTable } from "../../components/OverviewTable/RisksTable";
import { ItemStatusTag } from "../../components/ItemStatusTag/ItemStatusTag";
import { ReviewStatusTag } from "../../components/ReviewStatusTag/ReviewStatusTag";
import { AttachmentTable } from "../../components/OverviewTable/AttachmentTable";
import { isSoureEditableAndDeletable } from "../../domain/editableSource";
import { Outlet, useNavigate } from "react-router";
import { useCurrentProduct } from "../../hooks/useCurrentProduct";
import { TestResultTable } from "../../components/OverviewTable/TestResultTable";
import { GapAnalysis } from "../../components/GapAnalysis/GapAnalysis";
import { sourceDisplayName } from "../../constants/sourceDisplayName";

export const TestCaseOverview: React.FC = () => {
  const { isLoading, designElement, designElementHistory, version } =
    useDeleteElementDrawer();
  const navigate = useNavigate();
  const { name } = useCurrentProduct();

  const requirements = useMemo(() => {
    if (!designElement) {
      return [];
    }

    return [
      ...((designElement as any).children ?? []),
      ...((designElement as any).parent ?? []),
    ].filter((element) => element.type.startsWith("req"));
  }, [designElement]);

  const risks = useMemo(() => {
    if (!designElement) {
      return [];
    }

    return [
      ...(designElement as any).sourcedItems,
      ...((designElement as any).mitigates ?? []),
    ];
  }, [designElement]);

  const onEditClick = useCallback(() => {
    navigate("edit");
  }, [navigate]);

  if (isLoading) {
    return <QPageLoader />;
  }

  if (!designElement || !isDesignElementTestCase(designElement)) {
    return <QNotFound />;
  }

  return (
    <QBodyLayout.Default>
      <QHeader>
        <QBackLink href="../test-case" data-cy="back-btn">
          Back to {name}
        </QBackLink>
        <QTitle>
          <QText as="span" color="gray.500">
            {designElement?.code}
          </QText>{" "}
          {designElement?.title}
        </QTitle>
        <QHorizontalMetadata>
          <ItemStatusTag itemStatus={designElement.itemStatus} />
          <QDateMetadataItem
            label="Created"
            value={new Date(designElement?.created ?? 0)}
          />
          <QDateMetadataItem
            label="Last modified"
            value={new Date(designElement?.updated ?? 0)}
          />
        </QHorizontalMetadata>
        <QButtonGroup>
          {isSoureEditableAndDeletable(designElement.source) &&
            version === "v0" && (
              <QButton
                leftIcon="Edit3"
                variant="outline"
                data-cy="edit"
                onClick={onEditClick}
              >
                Edit
              </QButton>
            )}
          {!isSoureEditableAndDeletable(designElement.source) && (
            <a
              href={designElement.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <QButton
                data-cy="integration-button"
                variant="outline"
                rightIcon="ExternalLink"
              >
                {sourceDisplayName(designElement.source)}
              </QButton>
            </a>
          )}
          <QOpenPropertiesPanelButton />
        </QButtonGroup>
        <GapAnalysis
          policyIssues={designElement.policyIssues}
          type={designElement.type}
        />
      </QHeader>
      <QStack gap="24px" data-cy="test-case-overview">
        <QStack gap="8px">
          <QText fontSize="sm" fontWeight={600} color="gray.900">
            Description
          </QText>
          {designElement.description && (
            <QText
              fontSize={14}
              style={{ wordBreak: "break-word" }}
              dangerouslySetInnerHTML={{
                __html: sanitize(designElement.description),
              }}
            />
          )}
        </QStack>

        <TestResultTable result={designElement.testResult} />

        {designElementHistory && designElementHistory?.length > 0 && (
          <DesignReviewOverviewTable changeHistory={designElementHistory} />
        )}

        <QStack gap="8px">
          <QHeading fontSize="md" fontWeight={600}>
            Related design elements
          </QHeading>
          <QText fontSize="sm">
            Connected requirements, and risks that establish traceability for
            this test case.
          </QText>
        </QStack>

        <RequirementTable requirements={requirements} />
        <RisksTable risks={risks} />

        {designElement.attachments.length > 0 && (
          <>
            <QStack gap="8px">
              <QHeading fontSize="md" fontWeight={600}>
                Attachments
              </QHeading>
              <QText fontSize="sm">
                Supporting documents and files associated with this test case.
              </QText>
            </QStack>
            <AttachmentTable attachments={designElement.attachments} />
          </>
        )}

        <QPropertiesPanel openOnMount>
          <QStack spacing="8px" w="300px">
            <QStack direction="row" justifyContent="space-between">
              <QTitle>Properties</QTitle>
              <QTogglePropertiesPanelButton />
            </QStack>
            {designElement.category && (
              <QStack>
                <QText fontSize="sm" fontWeight={600}>
                  Category
                </QText>
                <QText fontSize="sm" data-cy="category">
                  {designElement.category?.label}
                </QText>
              </QStack>
            )}
            <QStack>
              <QText fontSize="sm" fontWeight={600}>
                Review status
              </QText>
              <QBox>
                <ReviewStatusTag status={designElement.changeControlStatus} />
              </QBox>
            </QStack>
          </QStack>
        </QPropertiesPanel>
      </QStack>
      <Outlet />
    </QBodyLayout.Default>
  );
};
