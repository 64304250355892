import { RequirementDesignElement, Requirement } from "@design-controls/types";
import { z } from "zod";

export const requirementToForm = (requirement: RequirementDesignElement) => {
  const { children, parent, ...item } = requirement;

  return {
    ...item,
    testCases: children.filter((item) => item.type.startsWith("testCase")),
    requirements: [
      ...parent.filter((item) => item.type.startsWith("req")),
      ...children.filter((item) => item.type.startsWith("req")),
    ],
  };
};

export const RequirementForm = z
  .object({
    type: z.string(),
    requirements: Requirement.shape.children.optional(),
    testCases: Requirement.shape.children.optional(),
  })
  .merge(
    Requirement.omit({
      children: true,
      parent: true,
      sourcedItems: true,
      mitigates: true,
    }),
  );

export type RequirementForm = z.infer<typeof RequirementForm>;
