import React, { useCallback, useMemo, useState } from "react";

import { Product } from "@design-controls/types";
import {
  createQColumnHelper,
  DataProvider,
  QBodyLayout,
  QButton,
  QButtonGroup,
  QDataTable,
  QDeleteConfirmationModal,
  QHeader,
  QText,
  QTitle,
  TableMenuItem,
  Filtering,
} from "@qualio/ui-components";
import { useNavigate } from "react-router";

import { UserPermissions } from "../../../components";
import { useProducts } from "../../../hooks/useProducts";
import useHasPermission from "../../hooks/useHasPermission";

const columnHelper = createQColumnHelper<Product>();

export const Products: React.FC = () => {
  const navigate = useNavigate();
  const permissions = useHasPermission([UserPermissions.MANAGE_PRODUCT]);
  const createProductClick = useCallback(
    () => navigate("/product/create"),
    [navigate],
  );
  const onEditClick = useCallback(
    (product: Product) => navigate(`/product/${product.id}/config`),
    [navigate],
  );
  const {
    products: data,
    isLoading,
    deleteProduct,
    isDeleting,
  } = useProducts();

  const [productToDelete, setProductToBeDelete] = useState<Product | undefined>(
    undefined,
  );

  const columns = useMemo(() => {
    const columns = [
      columnHelper.textLink(
        "name",
        (product: Product) => `/product/${product.id}`,
        {
          header: "Product",
        },
      ),
    ];

    if (!!permissions[UserPermissions.MANAGE_PRODUCT]) {
      columns.push(
        columnHelper.menu({
          items: (
            <>
              <TableMenuItem onClick={onEditClick} data-cy="edit-product">
                Edit
              </TableMenuItem>
              <TableMenuItem
                onClick={setProductToBeDelete}
                data-cy="delete-product"
                color="red.500"
              >
                Delete
              </TableMenuItem>
            </>
          ),
        }),
      );
    }

    return columns;
  }, [onEditClick, permissions]);

  return (
    <QBodyLayout.Default>
      <QHeader>
        <QTitle>Products</QTitle>
        {!!permissions[UserPermissions.MANAGE_PRODUCT] && (
          <QButtonGroup>
            <QButton onClick={createProductClick} data-cy="create-product">
              Create product
            </QButton>
          </QButtonGroup>
        )}
      </QHeader>
      <Filtering.FilterProvider definitions={{}} searchTermKey="Search">
        <DataProvider.FixedWithAutoPagination
          data={data}
          isLoading={isLoading && !data.length}
          filterOption={(item: Product, searchTerm: string | undefined) => {
            if (searchTerm) {
              return item.name
                .toLocaleLowerCase()
                .includes(searchTerm?.toLocaleLowerCase());
            }

            return true;
          }}
        >
          <QDataTable
            columns={columns}
            data-cy="product-list"
            getRowId={(product: Product) => product.id}
          >
            <Filtering.FormContent />
          </QDataTable>
        </DataProvider.FixedWithAutoPagination>
      </Filtering.FilterProvider>
      <>
        {productToDelete && (
          <QDeleteConfirmationModal
            inProgress={isDeleting}
            title="Delete product"
            message={
              <QText fontSize="sm">
                Are you sure you want to delete {productToDelete?.name}? You
                cannot undo this action.
              </QText>
            }
            onConfirm={() => {
              productToDelete &&
                deleteProduct(productToDelete.id, {
                  onSuccess: () => setProductToBeDelete(undefined),
                });
            }}
            onClose={() => setProductToBeDelete(undefined)}
          />
        )}
      </>
    </QBodyLayout.Default>
  );
};
