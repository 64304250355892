import { useMutation, useQueryClient } from "react-query";
import { useCurrentUser, useToastProvider } from "@qualio/ui-components";
import { useCurrentProduct } from "../useCurrentProduct";
import { useParams } from "react-router-dom";
import { designControlsApi } from "../../api/designControls";
import { ChangeControlElement } from "../../types/router";

export const useRevertToDraft = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();
  const { changeControlId: reviewId } =
    useParams<ChangeControlElement>() as ChangeControlElement;
  const queryClient = useQueryClient();
  const { showToast } = useToastProvider();

  return useMutation({
    mutationFn: () =>
      designControlsApi.revertToDraft(companyId, productId, reviewId),
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        "REVIEW",
        companyId,
        productId,
        reviewId,
      ]);
      showToast({
        title: "Reverted",
        description: "Successfully reverted to draft",
        status: "success",
      });
    },
    onError: () => {
      showToast({
        title: "Not reverted to draft",
        description: "Cannot revert to draft",
        status: "error",
      });
    },
  });
};
