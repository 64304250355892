import { z } from "zod";
import { BaseDesignElement, ResourceIdentifier } from "./designElement";
import { PolicyIssue } from "./openIssue";

export const OrphanItem = z.object({
  id: z.string(),
  type: z.string(),
  code: z.string().optional(),
});
export type OrphanItem = z.infer<typeof OrphanItem>;

export const BaseTraceItem = OrphanItem;
export type BaseTraceItem = z.infer<typeof BaseTraceItem>;

export const TraceabilityMatrix = z
  .object({
    testedBy: z.array(z.any()).optional(),
    children: z.array(z.any()).optional(),
    risks: z.array(z.any()).optional(),
  })
  .merge(BaseTraceItem)
  .merge(
    z.object({
      category: ResourceIdentifier.optional(),
      component: ResourceIdentifier.optional(),
      policyIssues: z.record(PolicyIssue, z.boolean()),
    }),
  )
  .passthrough()
  .array();

export type TraceabilityMatrix = z.infer<typeof TraceabilityMatrix>;

export const isOrphanItem = (item: BaseTraceItem): item is OrphanItem =>
  item.id.includes("ORPHAN");

export const isDesignElement = (
  item: BaseTraceItem,
): item is BaseDesignElement => !item.id.includes("ORPHAN");
