import React, { useCallback, useMemo } from "react";
import { useRetrieveReview } from "../../hooks/review/useRetrieveReview";
import {
  QAlert,
  QBackLink,
  QBodyLayout,
  QBox,
  QButton,
  QButtonGroup,
  QDateMetadataItem,
  QDeleteConfirmationModal,
  QHeader,
  QHorizontalMetadata,
  QIconButton,
  QMenuButton,
  QMenuItem,
  QNotFound,
  QOpenPropertiesPanelButton,
  QPageLoader,
  QPerson,
  QPersonProps,
  QPropertiesPanel,
  QStack,
  QTab,
  QTabList,
  QTabPanel,
  QTabPanels,
  QTabs,
  QText,
  QTitle,
  QTogglePropertiesPanelButton,
  QTooltip,
  useCurrentUser,
} from "@qualio/ui-components";
import { ReviewStatus, ReviewStatusEnum } from "@design-controls/types";
import { useCurrentProduct } from "../../hooks/useCurrentProduct";
import { PermissionCheck } from "../../components/PermissionCheck/PermissionCheck";
import { UserPermissions } from "../../../components";
import { Outlet, useNavigate } from "react-router";
import { useSendForApproval } from "../../hooks/review/useSendForApproval";
import { useRevertToDraft } from "../../hooks/review/useRevertToDraft";
import { useDisclosure } from "@chakra-ui/hooks";
import { useDeleteReview } from "../../hooks/review/useDeleteReview";
import { useRetrieveGeneratedDocuments } from "../../hooks/review/useRetrieveGeneratedDocuments";
import { ReviewStatusTag } from "./components/ReviewStatusTag";
import { ReviewTraceability } from "../ReviewTraceability/ReviewTraceability";
import { ReviewDetail } from "./components/ReviewDetails";
import { useReferenceDrawer } from "../../hooks/useReferenceDrawer";

const statusTable: Partial<Record<ReviewStatus, QPersonProps["status"]>> = {
  [ReviewStatus.enum.IN_PROGRESS]: "default",
  [ReviewStatus.enum.NOT_APPROVED]: "declined",
  [ReviewStatus.enum.APPROVED]: "approved",
};

const TABS = [
  {
    id: "detail",
    title: "Details",
  },
  {
    id: "traceability",
    title: "Traceability",
  },
];

export const ReviewOverview: React.FC = () => {
  const { isLoading, data } = useRetrieveReview();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    data: reportData,
    isComplete,
    downloadExportPacket,
  } = useRetrieveGeneratedDocuments(data?.releaseId);

  const { formatDate, userId } = useCurrentUser();
  const { name } = useCurrentProduct();
  const navigate = useNavigate();

  const { mutate: mutateForApproval, isLoading: isSendForApprovalLoading } =
    useSendForApproval();

  const { mutate: mutateForDraft, isLoading: isRevertToDraftLoading } =
    useRevertToDraft();

  const { isLoading: isDeleting, mutate: deleteReview } = useDeleteReview();

  const onEdit = useCallback(
    () => navigate(`../change-control/${data?.id}/edit`),
    [navigate, data],
  );

  const { handleClick } = useReferenceDrawer(
    "design-controls-changeControl",
    data?.id,
    data?.title,
  );

  const isOwner = useMemo(
    () => userId === Number(data?.createdBy.id),
    [userId, data],
  );

  const canApprove = useMemo(() => {
    if (data?.status !== ReviewStatus.enum.SENT_FOR_APPROVAL) {
      return false;
    }

    return !!data.approvers.find(
      (approver) =>
        Number(approver.id) === userId &&
        approver.status === ReviewStatus.enum.IN_PROGRESS,
    );
  }, [userId, data]);

  const sendForApproval = useCallback(
    () => mutateForApproval(),
    [mutateForApproval],
  );

  const onConfirmDelete = useCallback(() => {
    if (!data) {
      return;
    }

    deleteReview(data.id);
  }, [data, deleteReview]);

  const revertToDraft = useCallback(() => mutateForDraft(), [mutateForDraft]);

  const hasOutdatedDesignElements = useMemo(() => {
    return (
      data?.qualityItems.some(
        (item) => item.latest && item.latest !== item.version,
      ) && data.status !== ReviewStatusEnum.APPROVED
    );
  }, [data]);

  if (isLoading) {
    return <QPageLoader />;
  }

  if (!data) {
    return <QNotFound />;
  }

  return (
    <>
      <QStack overflow="hidden">
        <QBodyLayout.Default>
          <QHeader showDivider={!data.releaseId}>
            <QBackLink href="../change-control" data-cy="back-btn">
              Back to {name}
            </QBackLink>
            <QTitle>
              <QText as="span" color="gray.500">
                {data.code}
              </QText>{" "}
              {data.title}
            </QTitle>
            {hasOutdatedDesignElements && (
              <QAlert
                description={
                  isOwner
                    ? "Some design elements under review are outdated. Please edit review to update"
                    : "Some design elements under review are outdated"
                }
                status="warning"
                data-cy="outdated-design-elements"
              />
            )}
            <QHorizontalMetadata>
              {data.status && <ReviewStatusTag status={data.status} />}
              <QDateMetadataItem
                label="Created"
                value={new Date(data?.created ?? 0)}
              />
              <QDateMetadataItem
                label="Last modified"
                value={new Date(data?.updated ?? 0)}
              />
            </QHorizontalMetadata>
            <QButtonGroup>
              <QTooltip label="View related records" placement="top">
                <QBox>
                  <QIconButton
                    variant="outline"
                    onClick={handleClick}
                    iconName="Related"
                    aria-label="View related records"
                    data-cy="related-records-button"
                  />
                </QBox>
              </QTooltip>
              {isComplete && (
                <QButton
                  data-cy="export-review-packet"
                  onClick={downloadExportPacket}
                  variant="outline"
                >
                  Export review packet
                </QButton>
              )}
              <PermissionCheck
                permissions={[UserPermissions.EDIT_CHANGE_CONTROL]}
              >
                {isOwner && data.status === ReviewStatus.enum.IN_PROGRESS && (
                  <QButton
                    leftIcon="Edit3"
                    variant="outline"
                    data-cy="edit"
                    onClick={onEdit}
                  >
                    Edit
                  </QButton>
                )}
                {isOwner && data.status === ReviewStatus.enum.IN_PROGRESS && (
                  <QButton
                    data-cy="send-for-approval-button"
                    onClick={sendForApproval}
                    isLoading={isSendForApprovalLoading}
                  >
                    Send for approval
                  </QButton>
                )}
                {isOwner &&
                  data.status === ReviewStatus.enum.SENT_FOR_APPROVAL && (
                    <QButton
                      data-cy="send-for-draft-button"
                      variant="outline"
                      onClick={revertToDraft}
                      isLoading={isRevertToDraftLoading}
                    >
                      Revert to draft
                    </QButton>
                  )}
                {isOwner && data.status === ReviewStatus.enum.IN_PROGRESS && (
                  <QMenuButton
                    data-cy="more"
                    iconName="MoreVertical"
                    itemSize="sm"
                    variant="icon"
                  >
                    <QMenuItem
                      isDestructive
                      onClick={onOpen}
                      data-cy="delete-review"
                    >
                      Delete
                    </QMenuItem>
                  </QMenuButton>
                )}
              </PermissionCheck>
              <QOpenPropertiesPanelButton />
            </QButtonGroup>
            {!data.approvers.length && (
              <QAlert
                title="Missing approver(s)"
                description="Cannot send for approval with approver(s)"
                status="warning"
              />
            )}
          </QHeader>
          <QStack data-cy="review-overview">
            {!data.releaseId ? (
              <ReviewDetail
                canApprove={canApprove}
                data={data}
                reportData={reportData}
                isComplete={isComplete}
                hasOutdatedDesignElements={hasOutdatedDesignElements}
              />
            ) : (
              <QTabs>
                <QTabList>
                  {TABS.map((tab) => (
                    <QTab key={tab.title}>{tab.title}</QTab>
                  ))}
                </QTabList>
                <QTabPanels>
                  <QTabPanel>
                    <ReviewDetail
                      canApprove={canApprove}
                      data={data}
                      reportData={reportData}
                      isComplete={isComplete}
                      hasOutdatedDesignElements={hasOutdatedDesignElements}
                    />
                  </QTabPanel>
                  <QTabPanel>
                    <ReviewTraceability releaseId={data.releaseId} />
                  </QTabPanel>
                </QTabPanels>
              </QTabs>
            )}
          </QStack>
          <QPropertiesPanel openOnMount>
            <QStack spacing="8px" w="300px">
              <QStack direction="row" justifyContent="space-between">
                <QTitle>Properties</QTitle>
                <QTogglePropertiesPanelButton />
              </QStack>
              <QStack>
                <QText fontSize="sm" fontWeight={600}>
                  Owner
                </QText>
                <QText fontSize="sm" data-cy="category">
                  {data.createdBy.fullName}
                </QText>
              </QStack>
              <QStack>
                <QText fontSize="sm" fontWeight={600}>
                  Approvers
                </QText>
                <QStack>
                  {data.approvers.map((approver) => (
                    <QStack>
                      <QPerson
                        key={approver.id}
                        status={
                          statusTable[approver.status as ReviewStatus] ??
                          "default"
                        }
                        actionTimestamp={
                          approver.modified
                            ? formatDate(new Date(approver.modified))
                            : undefined
                        }
                        fullName={approver.fullName ?? ""}
                      />
                      {approver.note && (
                        <QText as="i" fontSize="xs" mt={1} color="gray.700">
                          {approver.note}
                        </QText>
                      )}
                    </QStack>
                  ))}
                </QStack>
              </QStack>
            </QStack>
          </QPropertiesPanel>
        </QBodyLayout.Default>
      </QStack>
      <Outlet />
      {isOpen && (
        <QDeleteConfirmationModal
          onConfirm={onConfirmDelete}
          onClose={onClose}
          title="Delete review"
          inProgress={isDeleting}
          message={
            <QText fontSize="sm">
              Are you sure you want to delete {data?.title}? You cannot undo
              this action.
            </QText>
          }
        />
      )}
    </>
  );
};
