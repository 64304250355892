import { useCurrentUser, useToastProvider } from "@qualio/ui-components";
import { useCurrentProduct } from "../useCurrentProduct";
import { useMutation, useQueryClient } from "react-query";
import { designControlsApi } from "../../api/designControls";
import { Policy } from "@design-controls/types";

export const useDeletePolicy = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();
  const { showToast } = useToastProvider();
  const client = useQueryClient();

  return useMutation({
    mutationFn: (type: Policy["type"]) =>
      designControlsApi.deletePolicy(companyId, productId, type),
    onSuccess: async () => {
      await client.invalidateQueries(["PRODUCT_CONFIGS", companyId, productId]);
      showToast({
        title: "Success",
        description: "Changes saved to product configuration",
        status: "success",
      });
    },
    onError: () => {
      showToast({
        title: "Error",
        description: "Failed to save changes. Please try again.",
        status: "error",
      });
    },
  });
};
