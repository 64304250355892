import {
  ChangeControlStatus,
  DesignElement,
  TestResult,
} from "@design-controls/types";

export const TestResultVariantColour: Record<
  TestResult["result"],
  "green" | "red" | "gray"
> = {
  passed: "green",
  failed: "red",
  blocked: "gray",
};

export const ResultValueToLabel: Record<string, string> = {
  passed: "Passed",
  failed: "Failed",
  blocked: "Blocked",
};

export const designElementStatus = (element: DesignElement) => {
  if (element.changeControlStatus === ChangeControlStatus.enum.NOT_IN_REVIEW) {
    return element.itemStatus;
  }

  return element.changeControlStatus;
};
