import { useCurrentUser, useToastProvider } from "@qualio/ui-components";
import { useMutation, useQueryClient } from "react-query";
import { useCurrentProduct } from "../useCurrentProduct";
import { designControlsApi } from "../../api/designControls";

type DesignElement = {
  id: string;
  code: string;
  title: string;
};

export const useDeleteTestCase = () => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();
  const { showToast } = useToastProvider();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (element: DesignElement) =>
      designControlsApi.deleteTestCaseById(companyId, productId, element.id),
    onSuccess: async (_: unknown, element: DesignElement) => {
      showToast({
        title: "Item deleted",
        description: `${element.code} ${element.title} was successfully deleted.`,
        status: "success",
      });
      await queryClient.invalidateQueries([
        "OPEN_ISSUE_LIST",
        companyId,
        productId,
      ]);
    },
    onError: (_: unknown, element: DesignElement) => {
      showToast({
        title: "Item failed to deleted",
        description: `${element.code} ${element.title} could not be deleted, try again.`,
        status: "error",
      });
    },
  });
};
