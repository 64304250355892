import { isRiskPolicy } from "@design-controls/types";
import React from "react";
import { useCurrentConfigs } from "../../../hooks/useCurrentConfigs";
import { QTag, QTagProps, QText } from "@qualio/ui-components";
import { riskScore } from "../../../domain/isoRequiresControl";

type Props = {
  probability?: string;
  severity?: string;
};

export const FormRiskScore: React.FC<Props> = ({ probability, severity }) => {
  const riskPolicy = useCurrentConfigs().find(isRiskPolicy);
  if (!riskPolicy || !probability || !severity) {
    return <QText>-</QText>;
  }

  const level = riskScore(riskPolicy, probability, severity);

  const table: Record<number, QTagProps["variantColor"][]> = {
    2: ["blue", "red"],
    3: ["blue", "yellow", "red"],
    4: ["blue", "green", "yellow", "red"],
    5: ["blue", "green", "yellow", "orange", "red"],
  };

  const total = riskPolicy.assessment.iso.levels.length;
  const position = riskPolicy.assessment.iso.levels.findIndex(
    (l) => l === level,
  );

  return <QTag variantColor={table[total][position]}>{level}</QTag>;
};
