import React, { useMemo } from "react";

import {
  DesignElement,
  RequirementLevel1Policy,
  RequirementLevel2Policy,
  RequirementLevel3Policy,
} from "@design-controls/types";
import { QAlert, QStack } from "@qualio/ui-components";

import { DesignElementType } from "../../../types/baseQualityItem";
import { PolicyIssues } from "../../../types/openIssue";
import { useCurrentConfigs } from "../../hooks/useCurrentConfigs";

type GapAnalysisProps = Pick<DesignElement, "policyIssues" | "type">;

const requirementLevels: Array<DesignElementType> = [
  DesignElementType.REQ1,
  DesignElementType.REQ2,
  DesignElementType.REQ3,
];

type Requirements =
  | RequirementLevel1Policy
  | RequirementLevel2Policy
  | RequirementLevel3Policy;

export const GapAnalysis: React.FC<GapAnalysisProps> = ({
  policyIssues: policyIssueRecords,
  type,
}) => {
  const configs = useCurrentConfigs();
  const policiesDefaultOrder = Object.values(PolicyIssues);
  const policyIssues = policiesDefaultOrder.filter(
    (policy) => policyIssueRecords[policy],
  );

  const parentRequirement: Requirements | undefined = useMemo(() => {
    if (!type.startsWith("req") || requirementLevels[0] === type) {
      return;
    }

    const parentType =
      requirementLevels[
        requirementLevels.findIndex((level) => level === type) - 1
      ];
    const config = configs.find((config) => config.type === parentType);

    return config as Requirements;
  }, [configs, type]);

  const childRequirement: Requirements | undefined = useMemo(() => {
    if (
      !type.startsWith("req") ||
      requirementLevels[requirementLevels.length - 1] === type
    ) {
      return;
    }

    const childType =
      requirementLevels[
        requirementLevels.findIndex((level) => level === type) + 1
      ];
    const config = configs.find((config) => config.type === childType);

    return config as Requirements;
  }, [configs, type]);

  if (!policyIssues.length) {
    return null;
  }

  return (
    <QStack gap="16px">
      {policyIssues.map((policyIssue) => {
        let title;
        let description;

        switch (policyIssue) {
          case PolicyIssues.FAILING_TEST:
            title = "Failing tests";
            description = "Test case has failing test result.";
            break;
          case PolicyIssues.OUTDATED_TESTRESULT:
            title = "Outdated test result";
            description = "Test case needs to be re-run.";
            break;
          case PolicyIssues.REQUIRES_CHILD:
            title = "Missing related requirement";
            description = `You must link at least one ${childRequirement?.label} requirement.`;
            break;
          case PolicyIssues.REQUIRES_PARENT:
            title = "Missing related requirement";
            description = type.startsWith("testCase")
              ? "Tests must be linked to at least one requirement."
              : `You must link at least one ${parentRequirement?.label} requirement.`;
            break;
          case PolicyIssues.UNMITIGATED:
            title = "Unmitigated risks";
            description = "Risk needs mitigation based on policy.";
            break;
          case PolicyIssues.UNTESTED_REQUIREMENT:
            title = "Missing related test case";
            description =
              "Requirement must be linked to at least one test case.";
            break;
        }

        return (
          <QAlert
            description={description}
            status="warning"
            title={title}
            key={policyIssue}
            data-cy={policyIssue}
          />
        );
      })}
    </QStack>
  );
};

// ensure QHeader can show component
GapAnalysis.displayName = "QAlert";
