import { useCurrentUser } from "@qualio/ui-components";
import { useCurrentProduct } from "../useCurrentProduct";
import { useQuery } from "react-query";
import { designControlsApi } from "../../api/designControls";

export const useReleaseTraceability = (releaseId?: string) => {
  const { companyId } = useCurrentUser();
  const { id: productId } = useCurrentProduct();

  return useQuery({
    queryFn: () =>
      designControlsApi.releaseTraceabilityMatrix(
        companyId,
        productId,
        releaseId as string,
      ),
    queryKey: ["TRACEABILITY", "RELEASE", companyId, productId, releaseId],
    enabled: !!releaseId,
  });
};
