import axios from "axios";
import { SearchResponse } from "../types/globalSearch";

export const globalSearchApi = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: `${process.env.REACT_APP_GLOBAL_SEARCH_SERVICE_HOSTNAME}`,
  withCredentials: true,
});

export const GlobalSearchAPI = {
  async quickSearchResourceItem(companyId: number, resourceSubType: string) {
    const response = await globalSearchApi.get(
      `/v2/quicksearch?companyId=${companyId}&term=status:effective&resources=registry-item&resourceSubType=${resourceSubType}&size=100&page=1`,
    );

    return SearchResponse.parse(response.data);
  },
};
