import { z } from "zod";

export const ErrorResponse = z.object({
  errors: z
    .object({
      path: z.string().array(),
      message: z.string(),
    })
    .array(),
});
export type ErrorResponse = z.infer<typeof ErrorResponse>;
