import { useCurrentUser, useToastProvider } from "@qualio/ui-components";
import { useQuery } from "react-query";
import { MedtechAPI } from "../api/medtech";

export const useTags = () => {
  const { showToast } = useToastProvider();
  const { companyId } = useCurrentUser();

  return useQuery({
    queryKey: [companyId, "tags"],
    queryFn: () => MedtechAPI.getDocumentTags(companyId),
    onError: () => {
      showToast({
        title: "Error",
        description: "Unable to fetch document tags",
        status: "error",
      });
    },
  });
};
