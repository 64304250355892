import {
  QAlertBox,
  QAlertDescription,
  QFlex,
  QIconButton,
  QSpacer,
} from "@qualio/ui-components";
import React from "react";

type Props = {
  onRefresh: () => void;
};

export const ReviewRefreshAlert: React.FC<Props> = ({ onRefresh }) => {
  return (
    <QAlertBox status="warning" alignItems="center">
      <QFlex alignItems="center">
        <QAlertDescription>
          Some design elements under review are outdated
        </QAlertDescription>
        <QSpacer />
        <QIconButton
          iconName="RefreshCw"
          onClick={onRefresh}
          data-cy="refreh-all-design-elements"
          aria-label="Update all design elements to latest version"
        />
      </QFlex>
    </QAlertBox>
  );
};

// ensure QHeader can show component
ReviewRefreshAlert.displayName = "QAlert";
