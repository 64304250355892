import React, { useCallback, useMemo } from "react";
import {
  createQColumnHelper,
  DataProvider,
  QButton,
  QCard,
  QDataTable,
  QIconButton,
  QLookup,
  QStack,
  QTag,
  QText,
} from "@qualio/ui-components";
import { DesignElement, LinkedElement } from "@design-controls/types";
import { globalSearchApi } from "../../api/globalSearch";
import { qriApi } from "../../api/qri";
import { useDisclosure } from "@chakra-ui/hooks";
import { SelectableDataAccessors } from "@qualio/ui-components/lib/QOrganisms/Lookup/types";
import { useCurrentProduct } from "../../hooks/useCurrentProduct";
import { useCurrentConfigs } from "../../hooks/useCurrentConfigs";

type SearchResource = "dc-requirement" | "dc-testCase" | "dc-risk";

type LinkedDesignElement = Pick<
  LinkedElement,
  "id" | "code" | "qri" | "title" | "type"
>;

type RelateDesignElementsProps = {
  label: string;
  resource: Array<SearchResource> | SearchResource;
  resourceSubType: readonly DesignElement["type"][];
  onChange: (items: LinkedDesignElement[]) => void;
  value: LinkedDesignElement[];
  "data-cy"?: string;
  buttonLabel?: string;
};

const columnHelper = createQColumnHelper<LinkedElement>();

export const RelateDesignElements: React.FC<RelateDesignElementsProps> = ({
  resource,
  resourceSubType,
  label,
  value,
  onChange,
  "data-cy": dataCy,
  buttonLabel,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id: productId } = useCurrentProduct();
  const configs = useCurrentConfigs();

  const defaultDataQRIs = useMemo(
    () => (value ?? []).map((item: LinkedDesignElement) => item.qri as string),
    [value],
  );

  const onSelect = useCallback(
    (selection: readonly QLookup.DataProvider.V2Result[]) => {
      if (!selection || selection.length === 0) {
        //When selection is cleared, set category value to be undefined instead of an empty array
        onChange([]);
      } else {
        const mappedSelection = selection.map(
          (item): LinkedDesignElement => ({
            id: item.identifiers.id.split("#").pop() as string, // remove productId if it exists
            code: item.identifiers.code as string,
            title: item.displayLabel
              .split(item.identifiers.code as string)[1]
              .trim(),
            type: item.resourceSubType as string,
            qri: item.identifiers.qri,
          }),
        );
        onChange(mappedSelection);
      }
      onClose();
      return Promise.resolve();
    },
    [onChange],
  );

  const isPreselected = useCallback(
    (result: QLookup.DataProvider.V2Result) => {
      return !!defaultDataQRIs.find(
        (qri?: string) => qri === result.identifiers.qri,
      );
    },
    [defaultDataQRIs],
  );

  const onDelete = useCallback(
    (element: LinkedElement) => {
      onChange(value.filter((item) => item.id !== element.id));
    },
    [onChange, value],
  );

  const qriMust = useMemo(() => {
    const must: Array<Record<string, any>> = [{ term: { productId } }];
    if (resourceSubType.length > 1) {
      must.push({ terms: { dcSubType: resourceSubType } });
    }

    return must;
  }, [productId, resourceSubType]);

  const qriResourceSubType = useMemo((): string | undefined => {
    if (resourceSubType.length === 1) {
      return resourceSubType[0];
    }

    return;
  }, [resourceSubType]);

  const columns = useMemo(() => {
    return [
      columnHelper.text("code", { header: "Code", maxWidth: "100px" }),
      columnHelper.text("title", { header: "Title" }),
      {
        header: "Type",
        meta: {
          maxWidth: "150px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: LinkedElement };
        }) => {
          return (
            <QTag variantColor="gray">
              {
                (
                  configs.find((config) => config.type === row.type) as
                    | { label: string }
                    | undefined
                )?.label
              }
            </QTag>
          );
        },
      },
      {
        header: " ",
        id: "remove",
        meta: {
          maxWidth: "50px",
        },
        cell: ({
          row: { original: row },
        }: {
          row: { original: LinkedElement };
        }) => {
          return (
            <QIconButton
              iconName="Trash"
              aria-label="Remove"
              onClick={() => onDelete(row)}
            />
          );
        },
      },
    ];
  }, [configs, onDelete]);

  return (
    <>
      <QCard>
        <QStack padding={4} gap={4}>
          <QStack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <QText fontSize="sm" fontWeight={600}>
              {label}
            </QText>
            <QButton variant="link" onClick={onOpen} data-cy={dataCy}>
              {buttonLabel ? buttonLabel : `Add ${label.toLocaleLowerCase()}`}
            </QButton>
          </QStack>
          {value.length > 0 && (
            <DataProvider.Fixed data={value} isLoading={false}>
              <QDataTable
                columns={columns}
                withHeaders={false}
                hideItemCount
                data-cy={`${dataCy}-table`}
              />
            </DataProvider.Fixed>
          )}
        </QStack>
      </QCard>
      {isOpen && (
        <QLookup.DataProvider.QuickSearchV2
          quickSearchClient={globalSearchApi}
          qriClient={qriApi}
          resource={resource}
          resourceSubType={qriResourceSubType}
          must={qriMust}
          defaultDataQRIs={defaultDataQRIs}
        >
          <QLookup.MultiSelect
            isOpen={isOpen}
            onCancel={onClose}
            onSelect={onSelect}
            accessors={ACCESSORS}
            view={DESIGN_ELEMENT_VIEW}
            isItemPreSelected={isPreselected}
            data-cy={`${dataCy}-lookup`}
          />
        </QLookup.DataProvider.QuickSearchV2>
      )}
    </>
  );
};

const ACCESSORS = {
  id: "id",
} as Pick<SelectableDataAccessors<QLookup.DataProvider.V2Result>, "id">;

const DESIGN_ELEMENT_VIEW = {
  identifiers: {
    header: "ID",
    width: "",
    render: (identifiers) => <QText>{identifiers.code}</QText>,
  },
  displayLabel: {
    header: "Name",
    width: "80%",
    render: (displayLabel) => <QText>{displayLabel}</QText>,
  },
} as QLookup.DataView<QLookup.DataProvider.V2Result>;
