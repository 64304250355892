import React, { useCallback, useEffect, useRef } from "react";

import {
  QBodyLayout,
  QButton,
  QFooter,
  QHeader,
  QStep,
  QStepperProgress,
  QTitle,
} from "@qualio/ui-components";
import { useNavigate } from "react-router";

import { StepBlocker } from "../../components/StepBlocker/StepBlocker";
import { CreateProductProvider } from "../../context/CreateProductContext";
import { useCreateProductStore } from "../../hooks/useCreateProductStore";
import { Product, Requirements, Risk, TestCases } from "./steps";
import { useCreateProduct } from "../../hooks/createProductSteps/useCreateProduct";

export const CreateProductLayout: React.FC = () => {
  const nextRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const { activeStep, isDisabled, isComplete, previousActiveStep } =
    useCreateProductStore((state) => ({
      activeStep: state.activeStep,
      isDisabled: state.isDisabled,
      isComplete: state.isComplete,
      previousActiveStep: state.previousActiveStep,
    }));

  const { isLoading, createProduct } = useCreateProduct();

  const onCancel = useCallback(() => {
    navigate("/product");
  }, [navigate]);

  useEffect(() => {
    isComplete && createProduct();
  }, [isComplete]);

  return (
    <QBodyLayout.SplitPanelLeftWithStepper
      size="md"
      activeStep={activeStep}
      onStepItemClicked={() => undefined}
    >
      <QHeader>
        <QTitle>Create Product</QTitle>
      </QHeader>
      <QStepperProgress />
      <QStep title="Product profile" />
      <QStep title="Requirements" />
      <QStep title="Test cases" />
      <QStep title="Risks" />
      <StepBlocker isActive={activeStep === 0}>
        <Product nextRef={nextRef} />
      </StepBlocker>
      <StepBlocker isActive={activeStep === 1}>
        <Requirements nextRef={nextRef} />
      </StepBlocker>
      <StepBlocker isActive={activeStep === 2}>
        <TestCases nextRef={nextRef} />
      </StepBlocker>
      <StepBlocker isActive={activeStep === 3}>
        <Risk nextRef={nextRef} />
      </StepBlocker>
      <QFooter>
        <QButton variant="ghost" onClick={onCancel} data-cy="cancel">
          Cancel
        </QButton>
        <QButton
          rightIcon="ArrowRight"
          onClick={() => nextRef.current?.click()}
          isDisabled={isDisabled}
          data-cy="submit-button"
        >
          Next
        </QButton>
      </QFooter>
      <QFooter>
        <QButton variant="ghost" onClick={onCancel} data-cy="cancel">
          Cancel
        </QButton>
        <QButton
          leftIcon="ArrowLeft"
          onClick={previousActiveStep}
          variant="outline"
          data-cy="previous-button"
        >
          Back
        </QButton>
        <QButton
          rightIcon="ArrowRight"
          onClick={() => nextRef.current?.click()}
          isDisabled={isDisabled}
          data-cy="submit-button"
        >
          Next
        </QButton>
      </QFooter>
      <QFooter>
        <QButton variant="ghost" onClick={onCancel} data-cy="cancel">
          Cancel
        </QButton>
        <QButton
          leftIcon="ArrowLeft"
          onClick={previousActiveStep}
          variant="outline"
          data-cy="previous-button"
        >
          Back
        </QButton>
        <QButton
          rightIcon="ArrowRight"
          onClick={() => nextRef.current?.click()}
          isDisabled={isDisabled}
          data-cy="submit-button"
        >
          Next
        </QButton>
      </QFooter>
      <QFooter>
        <QButton variant="ghost" onClick={onCancel} data-cy="cancel">
          Cancel
        </QButton>
        <QButton
          leftIcon="ArrowLeft"
          onClick={previousActiveStep}
          variant="outline"
          data-cy="previous-button"
          isDisabled={isLoading}
        >
          Back
        </QButton>
        <QButton
          rightIcon="ArrowRight"
          onClick={() => {
            nextRef.current?.click();
          }}
          isLoading={isLoading}
          data-cy="create-product-button"
        >
          Create product
        </QButton>
      </QFooter>
    </QBodyLayout.SplitPanelLeftWithStepper>
  );
};

export const CreateProduct: React.FC = () => {
  return (
    <CreateProductProvider>
      <CreateProductLayout />
    </CreateProductProvider>
  );
};
