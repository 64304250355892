import { useCurrentUser } from "@qualio/ui-components";
import { useQuery } from "react-query";
import { GlobalSearchAPI } from "../../api/globalSearch";

export const useResourceLibraryQuickSearch = (resourceSubType: string) => {
  const { companyId } = useCurrentUser();

  return useQuery({
    queryKey: ["SEARCH", companyId, resourceSubType],
    queryFn: () =>
      GlobalSearchAPI.quickSearchResourceItem(companyId, resourceSubType),
  });
};
