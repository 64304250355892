import {
  ProductWithoutId,
  RequirementLevel1Policy,
  RequirementLevel2Policy,
  RequirementLevel3Policy,
  RequirementLevel4Policy,
  RiskPolicy,
  TestCaseLevel1Policy,
  TestCaseLevel2Policy,
  TestCaseLevel3Policy,
} from "@design-controls/types";
import { z } from "zod";

export const RequirementPolicies = z.object({
  req1: RequirementLevel1Policy,
  req2: RequirementLevel2Policy.optional(),
  req3: RequirementLevel3Policy.optional(),
  req4: RequirementLevel4Policy.optional(),
});
export type RequirementPolicies = z.infer<typeof RequirementPolicies>;

export const TestCasePolicies = z.object({
  testCase1: TestCaseLevel1Policy.optional(),
  testCase2: TestCaseLevel2Policy.optional(),
  testCase3: TestCaseLevel3Policy.optional(),
});
export type TestCasePolicies = z.infer<typeof TestCasePolicies>;

export type CreateProductStore = {
  product: ProductWithoutId;
  storeProduct: (product: ProductWithoutId) => void;
  requirementPolicies: RequirementPolicies;
  storeRequirementPolicies: (policies: RequirementPolicies) => void;
  testCasePolicies: TestCasePolicies;
  storeTestCasePolicies: (policies: TestCasePolicies) => void;
  riskPolicy: RiskPolicy;
  storeRiskPolicy: (policy: RiskPolicy) => void;

  // step handling
  activeStep: number;
  isDisabled: boolean;
  isComplete: boolean;
  previousActiveStep: () => void;
  setDisabled: (toggle: boolean) => void;
};
