import { useMemo } from "react";

import {
  Policy,
  RequirementPolicies,
  RiskPolicy,
  TestCasePolicies,
} from "@design-controls/types";
import { useCurrentUser } from "@qualio/ui-components";
import { useQueries } from "react-query";

import { GlobalSearchAPI } from "../../api/global-search/search";
import { useCurrentConfigs } from "../../appV2/hooks/useCurrentConfigs";

export const usePolicyGroupResourceQuickSearch = (
  policyFn: (
    policy: Policy,
  ) => policy is RequirementPolicies | TestCasePolicies | RiskPolicy,
) => {
  const { companyId } = useCurrentUser();
  const configs = useCurrentConfigs();

  const queries = useQueries(
    configs.filter(policyFn).map((config) => ({
      queryKey: ["SEARCH", companyId, config.category?.[0].resourceIdentifier],
      queryFn: () =>
        GlobalSearchAPI.quickSearchResourceItem(
          companyId.toString(10),
          config?.category?.[0].resourceIdentifier ?? "",
        ),
    })),
  );

  const data = useMemo(() => {
    const data = queries
      .filter((query) => !!query.data)
      .map((query) => query.data?.results ?? [])
      .filter((results) => !!results.length)
      .flat();

    return data.sort((a, b) =>
      a.displayLabel
        .toLocaleLowerCase()
        .localeCompare(b.displayLabel.toLocaleLowerCase()),
    );
  }, [queries]);

  return {
    isLoading: queries.some((query) => query.isLoading),
    data,
  };
};
