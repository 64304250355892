import { zodResolver } from "@hookform/resolvers/zod";
import React, { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RequirementPolicies } from "../../../types/createProductStore";
import { useCurrentConfigs } from "../../../hooks/useCurrentConfigs";
import { isRequirementPolicy, Policy } from "@design-controls/types";
import { RequirementPolicy } from "../../../components/RequirementPolicy/RequirementPolicy";
import { useUpdatePolicy } from "../../../hooks/policy/useUpdatePolicy";
import { useDisclosure } from "@chakra-ui/hooks";
import { DeletePolicy } from "./DeletePolicy";
import { useArchivePrefixes } from "../../../hooks/policy/useArchivePrefixes";
import { isAxiosError } from "axios";

type Props = {
  nextRef: React.RefObject<any>;
};

export const RequirementConfigure: React.FC<Props> = ({ nextRef }) => {
  const configs = useCurrentConfigs();
  const { mutate } = useUpdatePolicy();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [policyType, setPolicyType] = useState<Policy["type"] | null>(null);
  const { data: prefixes } = useArchivePrefixes();

  const defaultValues = useMemo(() => {
    const requirements = configs.filter(isRequirementPolicy);
    return Object.fromEntries(
      requirements.map((policy) => [policy.type, policy]),
    );
  }, configs);

  const methods = useForm<RequirementPolicies>({
    defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(RequirementPolicies),
  });

  const { setError } = methods;

  const onSubmit = useCallback(
    (form: RequirementPolicies) => {
      const policies = Object.values(form);

      const toBeSaved = Object.fromEntries(
        policies.map((item) => [item.type, item.codePrefix]),
      );

      const usedPrefixes = [
        ...configs
          .filter((item) => toBeSaved[item.type] !== item.codePrefix)
          .map((item) => item.codePrefix),
        ...(prefixes ?? []),
      ];

      let hasErrors = false;
      policies.forEach((policy) => {
        if (usedPrefixes.includes(policy.codePrefix)) {
          methods.setError(`${policy.type}.codePrefix`, {
            message: "Prefix in use",
          });
          hasErrors = true;
        }
      });

      if (!hasErrors) {
        mutate(policies, {
          onError: (error) => {
            if (isAxiosError(error) && error.response?.status === 400) {
              const type = JSON.parse(error?.config?.data).data.type;
              const errors = error?.response.data?.errors ?? [];

              for (const error of errors) {
                setError(
                  `${type}.${error.path[0] === "prefix" ? "codePrefix" : errors.path[0]}` as any,
                  { message: error.message, type: "server" },
                );
              }
            }
          },
        });
      }
    },
    [mutate, setError, prefixes, configs],
  );

  const onDelete = useCallback(
    (type: Policy["type"]) => {
      setPolicyType(type);
      onOpen();
    },
    [setPolicyType, onOpen],
  );

  const onDeleteClose = useCallback(() => {
    setPolicyType(null);
    onClose();
  }, [onClose, setPolicyType]);

  return (
    <FormProvider {...methods}>
      <RequirementPolicy onDelete={onDelete} />
      <button
        style={{ display: "none" }}
        ref={nextRef}
        onClick={methods.handleSubmit(onSubmit)}
        type="submit"
      />
      {isOpen && policyType !== null && (
        <DeletePolicy type={policyType} onClose={onDeleteClose} />
      )}
    </FormProvider>
  );
};
